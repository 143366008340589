export default (function (value) {
  if (validaEntero(value)) {
    var retorno = '';
    value = value.toString().split('').reverse().join('');
    var i = value.length;
    while (i > 0) retorno += (i % 3 === 0 && i !== value.length ? '.' : '') +
    // eslint-disable-next-line
    value.substring(i--, i);
    return retorno;
  }
  return 0;
});
function validaEntero(value) {
  var RegExPattern = /[0-9]+$/;
  return RegExPattern.test(value);
}