import get from 'lodash/get';
import { pushRoute } from '../router/history.actions';
import AuthenticationError from '../auth/AuthenticationError';
import { contentV3Endpoints } from '../api/endpoints';
import { apiRequest } from '../api/api.actions';
import { GET_TIENDA, GET_TIENDA_FAIL, GET_TIENDA_SUCCESS, setCatalogoTienda, getCatalogoTiendaFail, getCatalogoTiendaSuccess, getBannerTienda, getBannerTiendaFail, getBannerTiendaSuccess, GET_BANNER_TIENDA, GET_BANNER_TIENDA_FAIL, GET_BANNER_TIENDA_SUCCESS, setBannerTienda, cleanTienda } from './tienda.actions';
import eventTypes from '@cencosud/puntos-web/src/analytics/eventTypes';
import registerEvent from '@cencosud/puntos-web/src/analytics/registerEvent';
import listProductStoreBinder from '@cencosud/puntos-core/src/features/binder/ListProductStoreBinder';
export var getCatalogoTiendaFlow = function getCatalogoTiendaFlow(_ref) {
  var dispatch = _ref.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_TIENDA) {
        return nextResponse;
      }
      var _action$payload = action.payload,
        tienda = _action$payload.tienda,
        numpag = _action$payload.numpag,
        cantreg = _action$payload.cantreg;
      var storeName = tienda.replace('%20', ' ').replace(/\b\w/g, function (match) {
        return match.toUpperCase();
      }).replace(' ', '%20');
      return Promise.all([dispatch(apiRequest(contentV3Endpoints.productos.tiendas.get(storeName, numpag, cantreg), getCatalogoTiendaSuccess, getCatalogoTiendaFail)), dispatch(getBannerTienda())]);
    };
  };
};
export var processGetCatalogoTienda = function processGetCatalogoTienda(_ref2) {
  var dispatch = _ref2.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      switch (type) {
        case GET_TIENDA_SUCCESS:
          {
            var _tiendas$items;
            var tiendas = payload.payload;
            if ((tiendas === null || tiendas === void 0 ? void 0 : (_tiendas$items = tiendas.items) === null || _tiendas$items === void 0 ? void 0 : _tiendas$items.length) > 0) {
              return dispatch(setCatalogoTienda(listProductStoreBinder(tiendas.items, true)));
            }
            dispatch(cleanTienda());
            return nextResponse;
          }
        case GET_TIENDA_FAIL:
          {
            var event = {
              event: eventTypes.event.error,
              category: eventTypes.categories.paginaError,
              name: 'Error al obtener catalogo de tienda (Loyalty -> /catalogo/tienda)'
            };
            registerEvent(event);
            return Promise.all([nextResponse, payload instanceof AuthenticationError ? undefined : dispatch(pushRoute('/error'))]);
          }
        default:
          return nextResponse;
      }
    };
  };
};
export var getBannerTiendaFlow = function getBannerTiendaFlow(_ref3) {
  var dispatch = _ref3.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_BANNER_TIENDA) {
        return nextResponse;
      }
      return dispatch(apiRequest(contentV3Endpoints.productos.tiendas.getBanner(), getBannerTiendaSuccess, getBannerTiendaFail));
    };
  };
};
export var processGetBannerTienda = function processGetBannerTienda(_ref4) {
  var dispatch = _ref4.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      switch (type) {
        case GET_BANNER_TIENDA_SUCCESS:
          {
            var banner = {};
            return dispatch(setBannerTienda({
              source: get(banner, 'urlImagen'),
              accessibilityLabel: get(banner, 'altText'),
              screen: get(banner, 'link'),
              responsive: get(banner, 'urlImagenMobile')
            }));
          }
        case GET_BANNER_TIENDA_FAIL:
          if (payload instanceof AuthenticationError) {
            return nextResponse;
          }
          return nextResponse;
        default:
          return nextResponse;
      }
    };
  };
};
export default [getCatalogoTiendaFlow, processGetCatalogoTienda, getBannerTiendaFlow, processGetBannerTienda];