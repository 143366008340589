import isObject from 'lodash/isObject';
export default (function (popup) {
  var _popup$popupImage, _popup$popupMobileIma;
  return isObject(popup) ? {
    contenido: popup.content,
    imagen: (_popup$popupImage = popup.popupImage) === null || _popup$popupImage === void 0 ? void 0 : _popup$popupImage.url,
    imagenMobile: (_popup$popupMobileIma = popup.popupMobileImage) === null || _popup$popupMobileIma === void 0 ? void 0 : _popup$popupMobileIma.url,
    link: popup.link,
    nombre: popup.name
  } : {};
});