import { apiRequest } from '@cencosud/puntos-core/src/features/api/api.actions';
import { GET_REGIONES, GET_REGIONES_SUCCESS, getRegionesSuccess, getRegionesFail, setRegiones } from './regiones.actions';
import normalizeRegiones from './normalizeRegiones';
import { loyaltyEndpoints } from '../../api/endpoints';
export var getRegionesFlujo = function getRegionesFlujo(_ref) {
  var dispatch = _ref.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type === GET_REGIONES) {
        return dispatch(apiRequest(loyaltyEndpoints.regiones.get(), getRegionesSuccess, getRegionesFail));
      }
      return nextResponse;
    };
  };
};
export var getRegionesProcesar = function getRegionesProcesar(_ref2) {
  var dispatch = _ref2.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      if (type !== GET_REGIONES_SUCCESS) {
        return nextResponse;
      }
      return dispatch(setRegiones(normalizeRegiones(payload.payload)));
    };
  };
};
export default [getRegionesFlujo, getRegionesProcesar];