import setTituloVista from '@cencosud/puntos-core/src/features/common/setTituloVista';
export default function setTitle() {
  var pathname = window.location.pathname;
  switch (pathname) {
    case '/puntos/resultados' || '/resultados':
      setTituloVista('Resultados de búsqueda');
      break;
    case '/puntos/puntos-por-vencer' || '/puntos-por-vencer':
      setTituloVista('Puntos por Vencer');
      break;
    case '/puntos/cuenta' || '/cuenta':
      setTituloVista('Mi cuenta');
      break;
    case '/puntos/mis-canjes' || '/mis-canjes':
      setTituloVista('Mis canjes');
      break;
    case '/puntos/perfil' || '/perfil':
      setTituloVista('Actualizar mis datos');
      break;
    case '/puntos/adicionales' || '/adicionales':
      setTituloVista('Administrar adicionales');
      break;
    case '/puntos/bases-legales' || '/bases-legales':
      setTituloVista('Bases legales y ganadores');
      break;
    case '/puntos/faq' || '/faq':
      setTituloVista('¿Qué es Puntos Cencosud?');
      break;
    case '/puntos/politicas%20de%20privacidad' || '/politicas%20de%20privacidad':
      setTituloVista('Políticas de privacidad');
      break;
    case '/puntos/terminos%20y%20condiciones' || '/terminos%20y%20condiciones':
      setTituloVista('Términos y condiciones');
      break;
    default:
      break;
  }
}