import methods from './httpMethods';
import apiEndpointCreators from './apiEndpointCreators';
var createEndpoint = apiEndpointCreators.AuthEndpoint;
export default {
  login: function login() {
    return createEndpoint({
      method: methods.post,
      uri: ''
    });
  }
};