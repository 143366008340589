var namespace = '[registro v2]';
export var INTENTO_REGISTRO = "".concat(namespace, " intento de registro");
export var REGISTRO_EXITOSO = "".concat(namespace, " registro exitoso");
export var REGISTRO_FALLIDO = "".concat(namespace, " registro fallido");
export var INTENTO_EQUIFAX = "".concat(namespace, " intento de equifax");
export var EQUIFAX_EXITOSO = "".concat(namespace, " equifax exitoso");
export var EQUIFAX_FALLIDO = "".concat(namespace, " equifax fallido");
export var intentoRegistro = function intentoRegistro(_ref, showSuccess, showErrors) {
  var rut = _ref.rut,
    nombres = _ref.nombres,
    password = _ref.password,
    aceptaCondiciones = _ref.aceptaCondiciones,
    token = _ref.token,
    apellidoPaterno = _ref.apellidoPaterno,
    apellidoMaterno = _ref.apellidoMaterno,
    fechaNacimiento = _ref.fechaNacimiento,
    sexo = _ref.sexo,
    estadoCivil = _ref.estadoCivil,
    referido = _ref.referido;
  return {
    type: INTENTO_REGISTRO,
    payload: {
      registro: {
        rut: rut,
        nombres: nombres,
        password: password,
        aceptaCondiciones: aceptaCondiciones,
        token: token,
        apellidoPaterno: apellidoPaterno,
        apellidoMaterno: apellidoMaterno,
        fechaNacimiento: fechaNacimiento,
        sexo: sexo,
        estadoCivil: estadoCivil,
        referido: referido
      },
      showSuccess: showSuccess,
      showErrors: showErrors
    }
  };
};
export var intentoEquifax = function intentoEquifax(_ref2, nextStep, fillForm) {
  var rut = _ref2.rut,
    numDoc = _ref2.numDoc,
    email = _ref2.email,
    phone = _ref2.phone,
    captcha = _ref2.captcha,
    campaign = _ref2.campaign;
  return {
    type: INTENTO_EQUIFAX,
    payload: {
      equifax: {
        rut: rut,
        numDoc: numDoc,
        email: email,
        phone: phone,
        captcha: captcha,
        campaign: campaign
      },
      nextStep: nextStep,
      fillForm: fillForm
    }
  };
};
export var registroExitoso = function registroExitoso(response, showSuccess, showErrors) {
  return {
    type: REGISTRO_EXITOSO,
    payload: {
      response: response,
      showSuccess: showSuccess,
      showErrors: showErrors
    }
  };
};
export var registroFallido = function registroFallido(cause) {
  return {
    type: REGISTRO_FALLIDO,
    payload: cause
  };
};
export var equifaxExitoso = function equifaxExitoso(response, nextStep, fillForm) {
  return {
    type: EQUIFAX_EXITOSO,
    payload: {
      response: response,
      nextStep: nextStep,
      fillForm: fillForm
    }
  };
};
export var equifaxFallido = function equifaxFallido(cause) {
  return {
    type: EQUIFAX_FALLIDO,
    payload: cause
  };
};