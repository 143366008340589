import _defineProperty from "/home/jenkins/agent/workspace/UCTION_FRONT_deploy_front_master/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { GET_COMMERCES_LOADING, SET_COMMERCES } from './ecommerce.actions';
var initialState = {
  ecommercesList: [],
  ecommercesLoading: false,
  ecommercesError: false
};
export default (function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var _ref = arguments.length > 1 ? arguments[1] : undefined,
    type = _ref.type,
    payload = _ref.payload;
  switch (type) {
    case SET_COMMERCES:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          ecommercesList: payload,
          ecommercesLoading: false,
          ecommercesError: false
        });
      }
    case GET_COMMERCES_LOADING:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          ecommercesLoading: true
        });
      }
    default:
      {
        return state;
      }
  }
});