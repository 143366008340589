var namespace = '[tienda]';
export var GET_TIENDA = "".concat(namespace, " get tiendas");
export var GET_TIENDA_SEARCH = "".concat(namespace, " get tiendas search");
export var GET_TIENDA_SUCCESS = "".concat(GET_TIENDA, ": success");
export var GET_TIENDA_FAIL = "".concat(GET_TIENDA, ": fail");
export var SET_TIENDA = "".concat(namespace, " set tiendas");
export var CLEAN_TIENDA = "".concat(namespace, " clean tiendas");
export var GET_BANNER_TIENDA = "".concat(namespace, " get banner");
export var GET_BANNER_TIENDA_SUCCESS = "".concat(GET_BANNER_TIENDA, ": success");
export var GET_BANNER_TIENDA_FAIL = "".concat(GET_BANNER_TIENDA, ": fail");
export var SET_BANNER_TIENDA = "".concat(namespace, " set banner");

/**
 * @typedef {Object} GetCatalogoTiendaAction
 */

/**
 * @return {GetCatalogoTiendaAction}
 */

export var cleanTienda = function cleanTienda() {
  return {
    type: CLEAN_TIENDA
  };
};
export var getCatalogoTienda = function getCatalogoTienda(payload) {
  return {
    type: GET_TIENDA,
    payload: payload
  };
};
export var getCatalogoTiendaSuccess = function getCatalogoTiendaSuccess(tiendas) {
  return {
    type: GET_TIENDA_SUCCESS,
    payload: tiendas
  };
};
export var getCatalogoTiendaFail = function getCatalogoTiendaFail(cause) {
  return {
    type: GET_TIENDA_FAIL,
    payload: cause
  };
};
export var setCatalogoTienda = function setCatalogoTienda(productos) {
  return {
    type: SET_TIENDA,
    payload: productos
  };
};
export var getBannerTienda = function getBannerTienda() {
  return {
    type: GET_BANNER_TIENDA
  };
};
export var getBannerTiendaSuccess = function getBannerTiendaSuccess(banner) {
  return {
    type: GET_BANNER_TIENDA_SUCCESS,
    payload: banner
  };
};
export var getBannerTiendaFail = function getBannerTiendaFail(cause) {
  return {
    type: GET_BANNER_TIENDA_FAIL,
    payload: cause
  };
};
export var setBannerTienda = function setBannerTienda(banner) {
  return {
    type: SET_BANNER_TIENDA,
    payload: banner
  };
};