var namespace = '[home]';
export var GET_HOME = "".concat(namespace, " get home");
export var GET_HOME_SUCCESS = "".concat(GET_HOME, ": success");
export var GET_HOME_FAIL = "".concat(GET_HOME, ": fail");
export var SET_HOME = "".concat(namespace, " set home");
export var getHome = function getHome() {
  return {
    type: GET_HOME
  };
};
export var getHomeSuccess = function getHomeSuccess(success) {
  return {
    type: GET_HOME_SUCCESS,
    payload: success
  };
};
export var getHomeFail = function getHomeFail(cause) {
  return {
    type: GET_HOME_FAIL,
    payload: cause
  };
};
export var setHome = function setHome(home) {
  return {
    type: SET_HOME,
    payload: home
  };
};