import { apiRequest } from '@cencosud/puntos-core/src/features/api/api.actions';
import { loyaltyEndpoints } from '@cencosud/puntos-core/src/features/api/endpoints';
import { GET_CAPTCHA,
// eslint-disable-next-line import/named
GET_CAPTCHA_SUCCESS, setCaptchaResponse,
// eslint-disable-next-line import/named
getCaptchaSuccess,
// eslint-disable-next-line import/named
getCaptchaFail } from './captcha.action';
export var getCaptcha = function getCaptcha(_ref) {
  var dispatch = _ref.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type === GET_CAPTCHA) {
        return dispatch(apiRequest(loyaltyEndpoints.captcha.getStatusCaptcha(), getCaptchaSuccess, getCaptchaFail));
      }
      return nextResponse;
    };
  };
};
export var getCaptchaProcesar = function getCaptchaProcesar(_ref2) {
  var dispatch = _ref2.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      if (type !== GET_CAPTCHA_SUCCESS) {
        return nextResponse;
      }
      return dispatch(setCaptchaResponse(payload.payload));
    };
  };
};
export default [getCaptcha, getCaptchaProcesar];