var namespace = '[auth]';
export var LOGIN_ATTEMPT = "".concat(namespace, " login attempt");
export var LOGIN_SUCCESS = "".concat(namespace, " login success");
export var LOGIN_FAIL = "".concat(namespace, " login fail");
export var SET_SESSION = "".concat(namespace, " set session");
export var LOGOUT = "".concat(namespace, " logout session");
export var LOGOUT_SUCCESS = "".concat(LOGOUT, ": success");
export var LOGOUT_FAIL = "".concat(LOGOUT, ": fail");
export var LOGIN_REFRESH = "".concat(namespace, " login refresh");
export var LOGIN_REFRESH_SUCCESS = "".concat(namespace, " login refresh success");
export var LOGIN_REFRESH_FAIL = "".concat(namespace, " login refresh fail");
export var loginAttempt = function loginAttempt(username, password, omitirContrasegnaTemporal, token) {
  return {
    type: LOGIN_ATTEMPT,
    payload: {
      username: username,
      password: password,
      omitirContrasegnaTemporal: omitirContrasegnaTemporal,
      token: token
    }
  };
};
export var loginSuccess = function loginSuccess(response, rut, omitirContrasegnaTemporal) {
  return {
    type: LOGIN_SUCCESS,
    payload: response,
    meta: {
      rut: rut,
      omitirContrasegnaTemporal: omitirContrasegnaTemporal
    }
  };
};
export var setSession = function setSession(session, rut) {
  return {
    type: SET_SESSION,
    payload: {
      session: session,
      rut: rut
    }
  };
};
export var loginFail = function loginFail(cause) {
  return {
    type: LOGIN_FAIL,
    payload: cause
  };
};
export var logout = function logout() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
      navigate: true
    },
    _ref$navigate = _ref.navigate,
    navigate = _ref$navigate === void 0 ? true : _ref$navigate;
  return {
    type: LOGOUT,
    payload: {
      navigate: navigate
    }
  };
};
export var logoutSuccess = function logoutSuccess() {
  return {
    type: LOGOUT_SUCCESS
  };
};
export var logoutFail = function logoutFail() {
  return {
    type: LOGOUT_FAIL
  };
};
export var refreshApiCredentials = function refreshApiCredentials(apiList) {
  return {
    type: LOGIN_REFRESH,
    payload: {
      apiList: apiList
    }
  };
};
export var refreshSuccess = function refreshSuccess(succes) {
  return {
    type: LOGIN_REFRESH_SUCCESS,
    payload: succes
  };
};
export var refreshFail = function refreshFail(cause) {
  return {
    type: LOGIN_REFRESH_FAIL,
    payload: cause
  };
};
export var logoutWithoutNavigation = function logoutWithoutNavigation() {
  return logout({
    navigate: false
  });
};