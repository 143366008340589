import PropTypes from 'prop-types';
export var categoriaPropType = PropTypes.shape({
  banner: PropTypes.shape({
    accessibilityLabel: PropTypes.string,
    source: PropTypes.string,
    screen: PropTypes.string
  }),
  id: PropTypes.string,
  nombre: PropTypes.string,
  partnerId: PropTypes.string,
  subcategorias: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    nombre: PropTypes.string
  }))
});
export var categoriasPropType = PropTypes.arrayOf(categoriaPropType);
export var subcategoriaPropType = PropTypes.shape({
  banner: PropTypes.shape({
    accessibilityLabel: PropTypes.string,
    source: PropTypes.string,
    screen: PropTypes.string
  }),
  id: PropTypes.string,
  nombre: PropTypes.string,
  nombreCategoria: PropTypes.string,
  partnerId: PropTypes.string
});