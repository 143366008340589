import { SET_FOOTER } from './footer.actions';
// prettier-ignore
export default (function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var _ref = arguments.length > 1 ? arguments[1] : undefined,
    type = _ref.type,
    payload = _ref.payload;
  // NOSONAR
  if (type !== SET_FOOTER) {
    return state;
  }
  return payload;
});