import { SET_PARAMETERIZE } from './parameterize.actions';

// prettier-ignore
export default (function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _ref = arguments.length > 1 ? arguments[1] : undefined,
    type = _ref.type,
    payload = _ref.payload;
  // NOSONAR
  if (type !== SET_PARAMETERIZE) {
    return state;
  }
  return {
    login: payload === null || payload === void 0 ? void 0 : payload.loginModuleMsg,
    inscripcion: payload === null || payload === void 0 ? void 0 : payload.inscriptionMsg,
    perfil: payload === null || payload === void 0 ? void 0 : payload.dataUpdateMsg,
    page404: payload === null || payload === void 0 ? void 0 : payload.contingencyMsg,
    canje: payload === null || payload === void 0 ? void 0 : payload.exchangeMsg,
    cuenta: payload === null || payload === void 0 ? void 0 : payload.accountStatusMsg,
    puntos: payload === null || payload === void 0 ? void 0 : payload.pointsQueryMsg,
    adicional: payload === null || payload === void 0 ? void 0 : payload.additionalManagementMsg,
    home: payload === null || payload === void 0 ? void 0 : payload.homeMsg
  };
});