import history from '@cencosud/puntos-core/src/features/router/history';
import normalizeUri from '@cencosud/puntos-core/src/features/common/url/normalizeUri';
import { registerProductMenuEvent } from '@cencosud/ui/src/v2/compound/navbar/NavBarAnalyticsEvents';
export var handleRedirect = function handleRedirect(event, category, subcategory, url) {
  if (category.toLowerCase() === 'canjes en tiendas') history.push("/tiendas/".concat(subcategory ? normalizeUri(subcategory) : ''));else if (url) window.open(url, '_blank');else if (subcategory) history.push("/categorias/".concat(normalizeUri(category), "/").concat(normalizeUri(subcategory)));else history.push("/categorias/".concat(normalizeUri(category)));
};
export var handleRedirectCategory = function handleRedirectCategory(event, category, subcategory, url) {
  event === null || event === void 0 ? void 0 : event.preventDefault();
  handleRedirect(event, category, subcategory, url);
  registerProductMenuEvent(subcategory ? "".concat(category, ":").concat(subcategory) : category);
};