/* istanbul ignore file */
var namespace = '[puntos]';
export var GET_PUNTOS = "".concat(namespace, " get puntos");
export var GET_PUNTOS_SUCCESS = "".concat(GET_PUNTOS, ": success");
export var GET_PUNTOS_FAIL = "".concat(GET_PUNTOS, ": fail");
export var SET_PUNTOS = "".concat(namespace, " set puntos");
export var getPuntosPorRut = function getPuntosPorRut(rut, captcha) {
  return {
    type: GET_PUNTOS,
    payload: {
      rut: rut,
      captcha: captcha
    }
  };
};
export var getPuntosSuccess = function getPuntosSuccess(estado) {
  return {
    type: GET_PUNTOS_SUCCESS,
    payload: estado
  };
};
export var getPuntosFail = function getPuntosFail(cause) {
  return {
    type: GET_PUNTOS_FAIL,
    payload: cause
  };
};
export var setPuntos = function setPuntos(puntos) {
  return {
    type: SET_PUNTOS,
    payload: puntos
  };
};