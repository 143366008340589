/* istanbul ignore file */
import { HIDE_CHAT, SHOW_CHAT } from './clickToChat.actions';
// prettier-ignore
export default (function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _ref = arguments.length > 1 ? arguments[1] : undefined,
    type = _ref.type;
  // NOSONAR
  switch (type) {
    case SHOW_CHAT:
      return true;
    case HIDE_CHAT:
      return false;
    default:
      return state;
  }
});