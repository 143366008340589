import isEmpty from 'lodash/isEmpty';
import { tiposProducto } from '../productos/tiposProducto';

/**
 * Valida si el usuario cumple con requisitos de negocio para iniciar un proceso
 * de canje.
 *
 * @param {number} cantidad - cantidad de productos a canjear
 * @param {number} puntosUsuario
 * @param {number} puntosProducto
 * @param {string} stock - estado de stock según api
 * @param {boolean} tienePermisoCanje
 * @param {string} correo
 * @param {Object} productoTipo
 * @param {number} telefono
 * @return {boolean} Verdadero si los requisitos son satisfactorios
 */
export default (function (_ref) {
  var cantidad = _ref.cantidad,
    puntosUsuario = _ref.puntosUsuario,
    puntosProducto = _ref.puntosProducto,
    stock = _ref.stock,
    tienePermisoCanje = _ref.tienePermisoCanje,
    correo = _ref.correo,
    productoTipo = _ref.productoTipo,
    telefono = _ref.telefono,
    tienePrestamo = _ref.tienePrestamo;
  return cantidad > 0 && puntosUsuario >= puntosProducto * cantidad && (productoTipo.id === tiposProducto.SERVICIO.id ? !isEmpty(correo) : true) && !isEmpty(telefono) && tienePermisoCanje && stock > 0 && tienePrestamo === 'N';
});