var namespace = '[on/off]';
export var GET_FEATURE_TOGGLES = "".concat(namespace, " get feature toggles");
export var GET_FEATURE_TOGGLES_SUCCESS = "".concat(GET_FEATURE_TOGGLES, ": success");
export var GET_FEATURE_TOGGLES_FAIL = "".concat(GET_FEATURE_TOGGLES, ": fail");
export var SET_FEATURE_TOGGLES = "".concat(namespace, " set feature toggles");
export var getFeatureToggles = function getFeatureToggles() {
  return {
    type: GET_FEATURE_TOGGLES
  };
};
export var getFeatureTogglesSuccess = function getFeatureTogglesSuccess(featureToggle) {
  return {
    type: GET_FEATURE_TOGGLES_SUCCESS,
    payload: {
      featureToggle: featureToggle
    }
  };
};
export var getFeatureTogglesFail = function getFeatureTogglesFail(cause) {
  return {
    type: GET_FEATURE_TOGGLES_FAIL,
    payload: cause
  };
};
export var setFeatureToggles = function setFeatureToggles(featureToggle) {
  return {
    type: SET_FEATURE_TOGGLES,
    payload: featureToggle
  };
};