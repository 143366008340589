import { apiRequest } from '../../api/api.actions';
import { GET_PRODUCTO_TIENDA, GET_PRODUCTO_TIENDA_FAIL, GET_PRODUCTO_TIENDA_SUCCESS, setProductoTienda, getProductoTiendaFail, getProductoTiendaSuccess } from './productoTienda.actions';
import history from '../../router/history';
import AuthenticationError from '../../auth/AuthenticationError';
import { contentV3Endpoints } from '../../api/endpoints';
import eventTypes from '@cencosud/puntos-web/src/analytics/eventTypes';
import registerEvent from '@cencosud/puntos-web/src/analytics/registerEvent';
import productStoreBinder from '@cencosud/puntos-core/src/features/binder/ProductStoreBinder';
export var getProductoTiendaFlow = function getProductoTiendaFlow(_ref) {
  var dispatch = _ref.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_PRODUCTO_TIENDA) {
        return nextResponse;
      }
      return dispatch(apiRequest(contentV3Endpoints.productos.getDetalleTienda(action.payload.id), getProductoTiendaSuccess, getProductoTiendaFail));
    };
  };
};
var normalizeFeaturedProductsStore = function normalizeFeaturedProductsStore(product) {
  var _product$productImage, _product$productImage2, _product$productImage3, _product$productImage4;
  var normalizedProduct = {
    altText: product.name,
    prefijo: null,
    copago: product.copayPrice,
    descripcionLarga: product.description,
    condicionesDeCanje: product.exchangeConditions,
    puntosPlata1: null,
    puntosPlata2: null,
    puntosPlata3: null,
    marca: product.brand,
    variante: product.variant,
    id: product.id,
    urlImagen: (_product$productImage = product.productImage) === null || _product$productImage === void 0 ? void 0 : (_product$productImage2 = _product$productImage.imageDetail1) === null || _product$productImage2 === void 0 ? void 0 : _product$productImage2.url,
    imagenes: [(_product$productImage3 = product.productImage) === null || _product$productImage3 === void 0 ? void 0 : (_product$productImage4 = _product$productImage3.imageDetail1) === null || _product$productImage4 === void 0 ? void 0 : _product$productImage4.url],
    nombre: product.name,
    puntos: product.points,
    tagNuevo: false,
    tagOferta: false,
    stock: 'null',
    icono: null,
    tienda: product.store,
    cantidadProductos: null,
    sku: product.productStock
  };
  return normalizedProduct;
};
export var processGetProductoTienda = function processGetProductoTienda(_ref2) {
  var dispatch = _ref2.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      switch (type) {
        case GET_PRODUCTO_TIENDA_SUCCESS:
          {
            var productoTienda = payload.payload;
            return dispatch(setProductoTienda(productStoreBinder(productoTienda)));
          }
        case GET_PRODUCTO_TIENDA_FAIL:
          {
            if (payload instanceof AuthenticationError) {
              return nextResponse;
            }
            var event = {
              event: eventTypes.event.error,
              category: eventTypes.categories.paginaError,
              name: 'Error al obtener detalle de producto en tienda (Content -> /cartalogo/tienda/producto)'
            };
            registerEvent(event);
            return dispatch(history.push('/error'));
          }
        default:
          return nextResponse;
      }
    };
  };
};
export default [getProductoTiendaFlow, processGetProductoTienda];