import isArray from 'lodash/isArray';
import { sumBy, sortBy } from 'lodash';
import moment from 'moment';
import { SET_DASHBOARD_CUENTA } from './dashboard.actions';
import persistantStore from '../../state/persistentStore';
import { minDate } from '../../common/fecha';
var nombreTransaccion = {
  tienda: 'tienda',
  tarjeta: 'tarjeta',
  promocion: 'promocion',
  alianza: 'alianza',
  canje: 'canje',
  esAcumulacion: 'esAcumulacion',
  // FIXME: ofertas EECC
  ofertas: 'ofertas'
};
var totalDoceMeses = function totalDoceMeses(transacciones, transaccion) {
  var oneYear = isArray(transacciones) ? transacciones.filter(function (x) {
    return x.active[transaccion] === true;
  }).filter(function (x) {
    return moment(x.fecha).utcOffset(0).isAfter(minDate);
  }) : [];
  return sumBy(oneYear, 'puntos');
};
// prettier-ignore
export default (function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _ref = arguments.length > 1 ? arguments[1] : undefined,
    type = _ref.type,
    payload = _ref.payload;
  // NOSONAR
  if (type !== SET_DASHBOARD_CUENTA) {
    return state;
  }
  if (payload !== null && payload !== void 0 && payload.lista) persistantStore.setTransacciones(payload);
  if (!payload) {
    return {
      lista: [],
      total: {}
    };
  }
  var transacciones = sortBy(payload.lista, function (transaction) {
    return transaction.fecha;
  }).reverse();
  return {
    lista: transacciones,
    total: {
      acumulado: totalDoceMeses(transacciones, nombreTransaccion.esAcumulacion),
      canjeado: totalDoceMeses(transacciones, nombreTransaccion.canje),
      tienda: totalDoceMeses(transacciones, nombreTransaccion.tienda),
      tarjeta: totalDoceMeses(transacciones, nombreTransaccion.tarjeta),
      promocion: totalDoceMeses(transacciones, nombreTransaccion.promocion),
      alianza: totalDoceMeses(transacciones, nombreTransaccion.alianza),
      // FIXME: ofertas EECC
      ofertas: totalDoceMeses(transacciones, nombreTransaccion.ofertas),
      estimado: payload.estimado
    }
  };
});