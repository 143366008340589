import namespace from './AutogestionVouchersNamespace';
export var GET_VOUCHERS = "".concat(namespace, " get vouchers");
export var GET_VOUCHERS_SUCCESS = "".concat(GET_VOUCHERS, ": success");
export var GET_VOUCHERS_FAIL = "".concat(GET_VOUCHERS, ": fail");
export var SET_VOUCHERS = "".concat(namespace, " set vouchers");
export var RESEND_VOUCHER = "".concat(namespace, " resend voucher");
export var RESEND_VOUCHER_SUCCESS = "".concat(RESEND_VOUCHER, " success");
export var RESEND_VOUCHER_FAIL = "".concat(RESEND_VOUCHER, " fail");
export var RESEND_VOUCHER_SIGNAL = "".concat(RESEND_VOUCHER, " set signal");
export var getVouchers = function getVouchers(pageSize, startRowNum) {
  return {
    type: GET_VOUCHERS,
    payload: {
      pageSize: pageSize,
      startRowNum: startRowNum
    }
  };
};
export var getVouchersSuccess = function getVouchersSuccess(vouchers) {
  return {
    type: GET_VOUCHERS_SUCCESS,
    payload: {
      vouchers: vouchers
    }
  };
};
export var getVouchersFail = function getVouchersFail(cause) {
  return {
    type: GET_VOUCHERS_FAIL,
    payload: cause
  };
};
export var setVouchers = function setVouchers(vouchers) {
  return {
    type: SET_VOUCHERS,
    payload: vouchers
  };
};
export var resendMail = function resendMail(idVoucher) {
  return {
    type: RESEND_VOUCHER,
    payload: {
      idVoucher: idVoucher
    }
  };
};
export var resendMailSuccess = function resendMailSuccess(response) {
  return {
    type: RESEND_VOUCHER_SUCCESS,
    payload: response
  };
};
export var resendMailFail = function resendMailFail(response) {
  return {
    type: RESEND_VOUCHER_FAIL,
    payload: response
  };
};
export var signalResend = function signalResend(response) {
  return {
    type: RESEND_VOUCHER_SIGNAL,
    payload: response
  };
};