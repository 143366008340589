import methods from './httpMethods';
import { eliminarCaracteresEspeciales } from '../../common/rut';
import apiEndpointCreators from './apiEndpointCreators';
var createPrivateEndpoint = apiEndpointCreators.loyalty.PrivateLoyaltyEndpoint;
var createPublicEndpoint = apiEndpointCreators.loyalty.PublicLoyaltyEndpoint;
export default {
  user: {
    statusAccout: {
      getStatusAccount: function getStatusAccount(rut) {
        return createPrivateEndpoint({
          method: methods.get,
          uri: '/socios/puntos/{rut}',
          uriParams: {
            rut: eliminarCaracteresEspeciales(rut)
          }
        });
      },
      getTransactions: function getTransactions() {
        return createPrivateEndpoint({
          method: methods.post,
          uri: '/socios/account/statement/detail'
        });
      },
      getTransactionsAndTotals: function getTransactionsAndTotals() {
        return createPrivateEndpoint({
          method: methods.post,
          uri: '/socios/account/statement'
        });
      },
      getDashboards: function getDashboards() {
        return createPrivateEndpoint({
          method: methods.get,
          uri: '/socios/account/statement/boards'
        });
      }
    }
  },
  canje: {
    /**
     * @param {string} rut
     * @return {PrivateLoyaltyEndpoint}
     */
    canjear: function canjear(rut) {
      return createPrivateEndpoint({
        method: methods.post,
        uri: '/socios/canje/{rut}',
        uriParams: {
          rut: eliminarCaracteresEspeciales(rut)
        }
      });
    },
    checkRedeem: function checkRedeem() {
      return createPrivateEndpoint({
        method: methods.post,
        uri: '/socios/redeem-limit'
      });
    },
    codigo: {
      generar: function generar() {
        return createPrivateEndpoint({
          method: methods.post,
          uri: '/canje/generar/numero/'
        });
      },
      validar: function validar() {
        return createPrivateEndpoint({
          method: methods.post,
          uri: '/socios/validar/numero'
        });
      }
    },
    codigoEcommerce: {
      generar: function generar() {
        return createPublicEndpoint({
          method: methods.post,
          uri: '/copec/identify'
        });
      },
      validar: function validar() {
        return createPublicEndpoint({
          method: methods.post,
          uri: '/copec/confirm'
        });
      }
    },
    checkCopecAuth: function checkCopecAuth(rut) {
      return createPublicEndpoint({
        method: methods.get,
        uri: "/copec/".concat(rut)
      });
    },
    downloadVoucherResource: function downloadVoucherResource(id) {
      return createPrivateEndpoint({
        method: methods.get,
        uri: "/socios/voucher/".concat(id),
        uriParams: {
          id: id
        }
      });
    }
  },
  contacto: {
    enviarFormulario: function enviarFormulario() {
      return createPublicEndpoint({
        method: methods.post,
        uri: '/socios/contacto'
      });
    },
    getMotivos: function getMotivos() {
      return createPublicEndpoint({
        method: methods.get,
        uri: '/socios/contacto/motivos'
      });
    }
  },
  usuario: {
    /**
     * @param {string} rut
     * @return {PrivateLoyaltyEndpoint}
     */
    codigo: {
      generar: function generar() {
        return createPrivateEndpoint({
          method: methods.post,
          uri: '/generar/otp/emailsms/'
        });
      }
    },
    actualizar: function actualizar(rut) {
      return createPrivateEndpoint({
        method: methods.put,
        uri: '/socios/{rut}',
        uriParams: {
          rut: eliminarCaracteresEspeciales(rut)
        }
      });
    },
    actualizarPhone: function actualizarPhone(rut) {
      return createPrivateEndpoint({
        method: methods.put,
        uri: '/socios/phone/{rut}',
        uriParams: {
          rut: eliminarCaracteresEspeciales(rut)
        }
      });
    },
    actualizarEmail: function actualizarEmail(rut) {
      return createPrivateEndpoint({
        method: methods.put,
        uri: '/socios/email/{rut}',
        uriParams: {
          rut: eliminarCaracteresEspeciales(rut)
        }
      });
    },
    confirmarDatos: function confirmarDatos(rut) {
      return createPrivateEndpoint({
        method: methods.put,
        uri: '/socios/confirmation/{rut}',
        uriParams: {
          rut: eliminarCaracteresEspeciales(rut)
        }
      });
    },
    validarIdentidad: function validarIdentidad(rut) {
      return createPrivateEndpoint({
        method: methods.post,
        uri: '/socios/identidad/{rut}',
        uriParams: {
          rut: eliminarCaracteresEspeciales(rut)
        }
      });
    },
    adicionales: {
      /**
       * @param {string} rutTitular
       * @return {PrivateLoyaltyEndpoint}
       */
      get: function get(rutTitular) {
        return createPrivateEndpoint({
          method: methods.get,
          uri: '/socios/contacto/adicionales/listar/{rut}',
          uriParams: {
            rut: eliminarCaracteresEspeciales(rutTitular)
          }
        });
      },
      /**
       * @param {string} rutTitular
       * @return {PrivateLoyaltyEndpoint}
       */
      getPendientes: function getPendientes(rutTitular) {
        return createPrivateEndpoint({
          method: methods.get,
          uri: '/socios/contacto/adicionales/listar/prospecto/{rut}',
          uriParams: {
            rut: eliminarCaracteresEspeciales(rutTitular)
          }
        });
      },
      /**
       * @param {string} rutTitular
       * @return {PrivateLoyaltyEndpoint}
       */
      otorgarPermisoCanje: function otorgarPermisoCanje(rutTitular) {
        return createPrivateEndpoint({
          method: methods.put,
          uri: '/socios/contacto/adicionales/permiso/{rut}',
          uriParams: {
            rut: eliminarCaracteresEspeciales(rutTitular)
          }
        });
      },
      /**
       * @param {string} rutTitular
       * @return {PrivateLoyaltyEndpoint}
       */
      eliminarSocio: function eliminarSocio(rutTitular) {
        return createPrivateEndpoint({
          method: methods.put,
          uri: '/socios/contacto/adicionales/eliminar/{rut}',
          uriParams: {
            rut: eliminarCaracteresEspeciales(rutTitular)
          }
        });
      }
    },
    /**
     * @param {string} rutTitular
     * @return {PrivateLoyaltyEndpoint}
     */
    agregarAdicional: function agregarAdicional(rutTitular) {
      return createPrivateEndpoint({
        method: methods.post,
        uri: '/socios/inscripcion/adicional/{rut}',
        uriParams: {
          rut: eliminarCaracteresEspeciales(rutTitular)
        }
      });
    },
    /**
     * @param {string} rut
     * @return {PrivateLoyaltyEndpoint}
     */
    get: function get(rut) {
      return createPrivateEndpoint({
        method: methods.get,
        uri: '/socios/{rut}',
        uriParams: {
          rut: eliminarCaracteresEspeciales(rut)
        }
      });
    },
    /**
     * @param {string} rut
     * @return {PrivateLoyaltyEndpoint}
     */
    getEstadoCuenta: function getEstadoCuenta(rut) {
      return createPrivateEndpoint({
        method: methods.get,
        uri: '/socios/puntos/{rut}',
        uriParams: {
          rut: eliminarCaracteresEspeciales(rut)
        }
      });
    },
    getCanjesPendientes: function getCanjesPendientes(rut) {
      return createPrivateEndpoint({
        method: methods.get,
        uri: '/socios/quotes/pending/{rut}',
        uriParams: {
          rut: eliminarCaracteresEspeciales(rut)
        }
      });
    },
    getTransacciones: function getTransacciones(rut) {
      return createPrivateEndpoint({
        method: methods.get,
        uri: '/socios/transactions'
      });
    },
    getDashboardCuenta: function getDashboardCuenta(rut) {
      return createPrivateEndpoint({
        method: methods.get,
        uri: '/socios/acumulatedPoints/{rut}',
        uriParams: {
          rut: eliminarCaracteresEspeciales(rut)
        }
      });
    },
    getVouchers: function getVouchers(rut) {
      return createPrivateEndpoint({
        method: methods.post,
        uri: '/socios/vouchers/{rut}',
        uriParams: {
          rut: eliminarCaracteresEspeciales(rut)
        }
      });
    },
    getPuntos: function getPuntos() {
      return createPublicEndpoint({
        method: methods.post,
        uri: '/socios/nolog'
      });
    },
    getInscriptionType: function getInscriptionType() {
      return createPublicEndpoint({
        method: methods.get,
        uri: '/socios/inscribir'
      });
    },
    checkEquifax: function checkEquifax() {
      return createPublicEndpoint({
        method: methods.post,
        uri: '/socios/inscribir/paso1'
      });
    },
    inscribir2Pasos: function inscribir2Pasos() {
      return createPublicEndpoint({
        method: methods.post,
        uri: '/socios/inscribir/paso2'
      });
    },
    inscribir: function inscribir() {
      return createPublicEndpoint({
        method: methods.post,
        uri: '/socios/inscripcion'
      });
    },
    resendVoucher: function resendVoucher(rut) {
      return createPrivateEndpoint({
        method: methods.post,
        uri: '/socios/mail/voucher/{rut}',
        uriParams: {
          rut: eliminarCaracteresEspeciales(rut)
        }
      });
    },
    setPreferenciasContacto: function setPreferenciasContacto(rut) {
      return createPrivateEndpoint({
        method: methods.put,
        uri: '/socios/contacto/permiso/{rut}',
        uriParams: {
          rut: eliminarCaracteresEspeciales(rut)
        }
      });
    }
  },
  tyc: {
    getByHash: function getByHash(hash) {
      return createPublicEndpoint({
        method: methods.get,
        uri: '/socios/tyc/{hash}',
        uriParams: {
          hash: hash
        }
      });
    },
    signByHash: function signByHash(hash) {
      return createPublicEndpoint({
        method: methods.put,
        uri: '/socios/tyc/{hash}/acepta',
        uriParams: {
          hash: hash
        }
      });
    }
  },
  captcha: {
    getStatusCaptcha: function getStatusCaptcha() {
      return createPublicEndpoint({
        method: methods.get,
        uri: '/captcha',
        uriParams: {}
      });
    }
  },
  regiones: {
    get: function get() {
      return createPublicEndpoint({
        method: methods.get,
        uri: '/listas/regiones?incluirComunas=true'
      });
    }
  }
};