import { apiRequest } from '../api/api.actions';
import { contentV3Endpoints } from '../api/endpoints';
import { GET_BASIC_PAGE, getBasicPageSuccess, getBasicPageFail, GET_BASIC_PAGE_SUCCESS, setBasicPage } from './basicPage.actions';
export var getBasicPageFlow = function getBasicPageFlow(_ref) {
  var dispatch = _ref.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_BASIC_PAGE) {
        return nextResponse;
      }
      return dispatch(apiRequest(contentV3Endpoints.basicPages.get(action.payload.id), getBasicPageSuccess, getBasicPageFail));
    };
  };
};
export var processGetBasicPage = function processGetBasicPage(_ref2) {
  var dispatch = _ref2.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      if (type !== GET_BASIC_PAGE_SUCCESS) {
        return nextResponse;
      }
      return dispatch(setBasicPage(payload.payload));
    };
  };
};
export default [getBasicPageFlow, processGetBasicPage];