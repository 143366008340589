var obfuscateHead = function obfuscateHead() {
  var text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var numberOfVisibleCharacters = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  return text.slice(0, text.length - numberOfVisibleCharacters).replace(/./g, '*') + text.slice(text.length - numberOfVisibleCharacters);
};
export default obfuscateHead;
export var obfuscatedEmail = function obfuscatedEmail() {
  var text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var str = text.split('');
  var finalArr = [];
  var len = str.indexOf('@');
  str.forEach(function (item, pos) {
    return pos >= 1 && pos <= len - 2 ? finalArr.push('*') : finalArr.push(str[pos]);
  });
  return finalArr.join('');
};