import { apiRequest } from '@cencosud/puntos-core/src/features/api/api.actions';
import { pushRoute } from '@cencosud/puntos-core/src/features/router/history.actions';
import AuthenticationError from '@cencosud/puntos-core/src/features/auth/AuthenticationError';
import { contentV3Endpoints } from '@cencosud/puntos-core/src/features/api/endpoints';
import { GET_HOME, GET_HOME_FAIL, GET_HOME_SUCCESS, setHome, getHomeFail, getHomeSuccess } from './home.actions';
import eventTypes from '../analytics/eventTypes';
import registerEvent from '../analytics/registerEvent';
import { setFooterLinks } from '@cencosud/puntos-core/src/features/ui/footer/footer.actions';
import { setDuplaList } from '@cencosud/puntos-core/src/features/registro/dupla/dupla.action';
import { setParameterize } from '@cencosud/ui/src/Parameterize/parameterize.actions';
import homeBinder from '@cencosud/puntos-core/src/features/binder/HomeBinder';
import duplesBinder from '@cencosud/puntos-core/src/features/binder/DuplesBinder';
import { setRegiones } from '@cencosud/puntos-core/src/features/common/regiones/regiones.actions';
import normalizeRegiones from '@cencosud/puntos-core/src/features/common/regiones/normalizeRegiones';
export var getHomeFlow = function getHomeFlow(_ref) {
  var dispatch = _ref.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_HOME) {
        return nextResponse;
      }
      return dispatch(apiRequest(contentV3Endpoints.home.getWeb(), getHomeSuccess, getHomeFail));
    };
  };
};
var registerEventError = function registerEventError() {
  var event = {
    event: eventTypes.event.error,
    category: eventTypes.categories.paginaError,
    name: 'Error al obtener home (Content -> /seccione/v3/home-body)'
  };
  registerEvent(event);
};
export var processGetHome = function processGetHome(_ref2) {
  var dispatch = _ref2.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      if (type !== GET_HOME_SUCCESS && type !== GET_HOME_FAIL) {
        return nextResponse;
      }
      switch (type) {
        case GET_HOME_SUCCESS:
          {
            var data = payload.payload;
            if (data) dispatch(setFooterLinks(data.footer));
            if (data) dispatch(setDuplaList(duplesBinder(data.inscriptionDuples)));
            if (data) dispatch(setParameterize(data));
            if (data) dispatch(setRegiones(normalizeRegiones(data.regions)));
            if (data) return dispatch(setHome(homeBinder(data)));
            registerEventError();
            return dispatch(pushRoute('/error'));
          }
        case GET_HOME_FAIL:
          {
            if (payload instanceof AuthenticationError) {
              return nextResponse;
            }
            registerEventError();
            return dispatch(pushRoute('/error'));
          }
        default:
          return nextResponse;
      }
    };
  };
};
export default [getHomeFlow, processGetHome];