import { namespace } from '../usuario/usuario.actions';
export var GET_ESTADO_CUENTA = "".concat(namespace, " get estado de cuenta");
export var GET_ESTADO_CUENTA_SUCCESS = "".concat(GET_ESTADO_CUENTA, ": success");
export var GET_ESTADO_CUENTA_FAIL = "".concat(GET_ESTADO_CUENTA, ": fail");
export var GET_CANJES_PENDIENTES = "".concat(namespace, " get canjes pendientes");
export var GET_CANJES_PENDIENTES_SUCCESS = "".concat(GET_CANJES_PENDIENTES, ": success");
export var GET_CANJES_PENDIENTES_FAIL = "".concat(GET_CANJES_PENDIENTES, ": fail");
export var GET_TRANSACCIONES = "".concat(namespace, " get transacciones");
export var GET_TRANSACCIONES_SUCCESS = "".concat(GET_TRANSACCIONES, ": success");
export var GET_TRANSACCIONES_FAIL = "".concat(GET_TRANSACCIONES, ": fail");
export var SET_ESTADO_CUENTA = "".concat(namespace, " set estado de cuenta ");
export var SET_CANJES_PENDIENTES = "".concat(namespace, " set canjes pendientes ");
export var SET_TRANSACCIONES = "".concat(namespace, " set transacciones");
export var GET_VOUCHER_RESOURCE = "".concat(namespace, " get voucher resource");
export var GET_VOUCHER_RESOURCE_SUCCESS = "".concat(GET_VOUCHER_RESOURCE, " success");
export var GET_VOUCHER_RESOURCE_FAIL = "".concat(GET_VOUCHER_RESOURCE, " fail");
export var getEstadoDeCuenta = function getEstadoDeCuenta() {
  return {
    type: GET_ESTADO_CUENTA
  };
};
export var getEstadoDeCuentaSuccess = function getEstadoDeCuentaSuccess(estado) {
  return {
    type: GET_ESTADO_CUENTA_SUCCESS,
    payload: estado
  };
};
export var getEstadoDeCuentaFail = function getEstadoDeCuentaFail(cause) {
  return {
    type: GET_ESTADO_CUENTA_FAIL,
    payload: cause
  };
};
export var getCanjesPendientes = function getCanjesPendientes() {
  return {
    type: GET_CANJES_PENDIENTES
  };
};
export var getCanjesPendientesSuccess = function getCanjesPendientesSuccess(estado) {
  return {
    type: GET_CANJES_PENDIENTES_SUCCESS,
    payload: estado
  };
};
export var getCanjesPendientesFail = function getCanjesPendientesFail(cause) {
  return {
    type: GET_CANJES_PENDIENTES_FAIL,
    payload: cause
  };
};
export var getTransacciones = function getTransacciones() {
  return {
    type: GET_TRANSACCIONES
  };
};
export var getTransaccionesSuccess = function getTransaccionesSuccess(estado) {
  return {
    type: GET_TRANSACCIONES_SUCCESS,
    payload: estado
  };
};
export var getTransaccionesFail = function getTransaccionesFail(cause) {
  return {
    type: GET_TRANSACCIONES_FAIL,
    payload: cause
  };
};
export var setEstadoDeCuenta = function setEstadoDeCuenta(cuenta) {
  return {
    type: SET_ESTADO_CUENTA,
    payload: cuenta
  };
};
export var setCanjesPendientes = function setCanjesPendientes(cuenta) {
  return {
    type: SET_CANJES_PENDIENTES,
    payload: cuenta
  };
};
export var setTransacciones = function setTransacciones(cuenta) {
  return {
    type: SET_TRANSACCIONES,
    payload: cuenta
  };
};
export var getVoucherResource = function getVoucherResource(cuenta) {
  return {
    type: GET_VOUCHER_RESOURCE,
    payload: cuenta
  };
};
export var getVoucherResourceSuccess = function getVoucherResourceSuccess(cuenta) {
  return {
    type: GET_VOUCHER_RESOURCE_SUCCESS,
    payload: cuenta
  };
};
export var getVoucherResourceFail = function getVoucherResourceFail(cause) {
  return {
    type: GET_VOUCHER_RESOURCE_FAIL,
    payload: cause
  };
};