var namespace = '[partner]';
export var GET_PARTNERS = "".concat(namespace, " get certified partners list");
export var GET_PARTNERS_SUCCESS = "".concat(GET_PARTNERS, ": success");
export var GET_PARTNERS_FAIL = "".concat(GET_PARTNERS, ": fail");
export var SET_PARTNERS = "".concat(namespace, " set certified partners list");
export var LOGIN_PARTNER = "".concat(namespace, " login as partner");
export var LOGIN_PARTNER_SUCCESS = "".concat(LOGIN_PARTNER, ": success");
export var LOGIN_PARTNER_FAIL = "".concat(LOGIN_PARTNER, ": fail");
export var getPartners = function getPartners() {
  return {
    type: GET_PARTNERS
  };
};
export var getPartnersSuccess = function getPartnersSuccess(partnerList) {
  return {
    type: GET_PARTNERS_SUCCESS,
    payload: partnerList
  };
};
export var getPartnersFail = function getPartnersFail(cause) {
  return {
    type: GET_PARTNERS_FAIL,
    payload: cause
  };
};
export var setPartners = function setPartners(partnerList) {
  return {
    type: SET_PARTNERS,
    payload: partnerList
  };
};
export var loginPartner = function loginPartner(_ref) {
  var username = _ref.username,
    password = _ref.password,
    id = _ref.id,
    redirect = _ref.redirect;
  return {
    type: LOGIN_PARTNER,
    payload: {
      username: username,
      password: password,
      id: id,
      redirect: redirect
    }
  };
};
export var loginPartnerSuccess = function loginPartnerSuccess(auth) {
  return {
    type: LOGIN_PARTNER_SUCCESS,
    payload: auth
  };
};
export var loginPartnerFail = function loginPartnerFail(cause) {
  return {
    type: LOGIN_PARTNER_FAIL,
    payload: cause
  };
};