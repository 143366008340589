import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
var WhileProgress = function WhileProgress(_ref) {
  var primary = _ref.primary,
    secondary = _ref.secondary,
    render = _ref.render,
    primaryActive = _ref.primaryActive,
    secondaryActive = _ref.secondaryActive,
    children = _ref.children;
  if (primary && primaryActive || secondary && secondaryActive) {
    return isFunction(render) ? render() : null;
  }
  return isFunction(children) ? children() : children;
};
WhileProgress.propTypes = {
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  render: PropTypes.func,
  children: PropTypes.oneOf([PropTypes.func, PropTypes.node]).isRequired
};
WhileProgress.defaultProps = {
  primary: false,
  secondary: false
};
var mapStateToProps = function mapStateToProps(state) {
  return {
    primaryActive: state.ui.progress.primary,
    secondaryActive: state.ui.progress.secondary
  };
};
export default connect(mapStateToProps, null)(WhileProgress);