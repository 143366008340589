import isObject from 'lodash/isObject';
export default (function (featureToggles) {
  var _featureToggles$paylo;
  return featureToggles === null || featureToggles === void 0 ? void 0 : (_featureToggles$paylo = featureToggles.payload) === null || _featureToggles$paylo === void 0 ? void 0 : _featureToggles$paylo.map(function (feature) {
    return isObject(feature) ? {
      page: feature.modulo,
      isActive: feature.estado === 'On',
      message: feature.mensaje
    } : [];
  });
});