/**
 * @param {number} id
 * @param {string} nombre
 * @return {{id: number, nombre: string}}
 */
export var TipoProducto = function TipoProducto(id, nombre) {
  return {
    id: id,
    nombre: nombre
  };
};
export var tiposProducto = {
  REGULAR: TipoProducto(0, 'regular'),
  SERVICIO: TipoProducto(1, 'servicio')
};