import Uri from 'urijs/src/URI';
import config from '@cencosud/puntos-core/src/config';
var createMapsUri = function createMapsUri(direccion) {
  var append = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  return new Uri("https://www.google.com/maps/".concat(append)).search({
    q: direccion,
    key: config.apisExternas.googleMaps.key
  });
};
export var getEmbeddedGoogleMapsUrl = function getEmbeddedGoogleMapsUrl(direccion) {
  return createMapsUri(direccion, 'embed/v1/place').toString();
};
export var getGoogleMapsUrl = function getGoogleMapsUrl(direccion) {
  return createMapsUri(direccion).toString();
};