import { AGREGAR_ADICIONAL, AGREGAR_ADICIONAL_SUCCESS, AGREGAR_ADICIONAL_FAIL, agregarAdicionalSuccess, agregarAdicionalFail } from './agregarAdicional.actions';
import { showSecondaryProgress, hideSecondaryProgressBar, showNotifier } from '@cencosud/puntos-core/src/features/ui/ui.actions';
import { apiRequest } from '@cencosud/puntos-core/src/features/api/api.actions';
import processErrorMessage from '@cencosud/puntos-core/src/features/api/processErrorMessage';
import { pushRoute } from '@cencosud/puntos-core/src/features/router/history.actions';
import { showAlert } from '@cencosud/puntos-core/src/features/ui/alert/alert.actions';
import { getCmsText } from '@cencosud/ui/src/Parameterize/getText';
import { loyaltyEndpoints } from '@cencosud/puntos-core/src/features/api/endpoints';
export var agregarAdicionalFlow = function agregarAdicionalFlow(_ref) {
  var dispatch = _ref.dispatch,
    getState = _ref.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== AGREGAR_ADICIONAL) {
        return nextResponse;
      }
      var _action$payload = action.payload,
        rut = _action$payload.rut,
        nombre = _action$payload.nombre,
        apellidoPaterno = _action$payload.apellidoPaterno,
        apellidoMaterno = _action$payload.apellidoMaterno,
        email = _action$payload.email,
        telefono = _action$payload.telefono,
        fechaNacimiento = _action$payload.fechaNacimiento;
      return Promise.all([dispatch(showSecondaryProgress()), dispatch(apiRequest(loyaltyEndpoints.usuario.agregarAdicional(getState().auth.rut), agregarAdicionalSuccess, agregarAdicionalFail, {
        body: {
          apellidoPaterno: apellidoPaterno,
          apellidoMaterno: apellidoMaterno,
          email: email,
          fechaNacimiento: fechaNacimiento,
          nombre: nombre,
          telefono: "9".concat(telefono),
          run: rut
        },
        auth: true
      }))]);
    };
  };
};
export var processAgregarAdicional = function processAgregarAdicional(_ref2) {
  var dispatch = _ref2.dispatch,
    getState = _ref2.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== AGREGAR_ADICIONAL_SUCCESS && action.type !== AGREGAR_ADICIONAL_FAIL) {
        return nextResponse;
      }
      var progressResponse = dispatch(hideSecondaryProgressBar());
      // eslint-disable-next-line default-case
      switch (action.type) {
        case AGREGAR_ADICIONAL_SUCCESS:
          return Promise.all([progressResponse, dispatch(showAlert({
            icon: 'success',
            title: 'Creación de socio exitosa',
            text: getCmsText('ADI-005', getState),
            onSuccess: function onSuccess() {
              dispatch(pushRoute('/adicionales'));
            }
          }))]);
        case AGREGAR_ADICIONAL_FAIL:
          {
            return Promise.all([progressResponse, dispatch(showNotifier(processErrorMessage(action.payload)))]);
          }
        default:
          return Promise.resolve();
      }
    };
  };
};
export default [agregarAdicionalFlow, processAgregarAdicional];