var namespace = '[landing]';
export var GET_SEGURIDAD_DIGITAL = "".concat(namespace, " get seguridad digital ");
export var GET_SEGURIDAD_DIGITAL_SUCCESS = "".concat(GET_SEGURIDAD_DIGITAL, ": success");
export var GET_SEGURIDAD_DIGITAL_FAIL = "".concat(GET_SEGURIDAD_DIGITAL, ": fail");
export var SET_SEGURIDAD_DIGITAL = "".concat(namespace, " set seguridad digital");
export var getSeguridadDigital = function getSeguridadDigital() {
  return {
    type: GET_SEGURIDAD_DIGITAL
  };
};
export var getSeguridadDigitalSuccess = function getSeguridadDigitalSuccess(success) {
  return {
    type: GET_SEGURIDAD_DIGITAL_SUCCESS,
    payload: success
  };
};
export var getSeguridadDigitalFail = function getSeguridadDigitalFail(cause) {
  return {
    type: GET_SEGURIDAD_DIGITAL_FAIL,
    payload: cause
  };
};
export var setSeguridadDigital = function setSeguridadDigital(seguridadDigital) {
  return {
    type: SET_SEGURIDAD_DIGITAL,
    payload: seguridadDigital
  };
};