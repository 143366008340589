import { apiRequest } from '@cencosud/puntos-core/src/features/api/api.actions';
import { contentV3Endpoints } from '@cencosud/puntos-core/src/features/api/endpoints';
import { GET_PARAMETERIZE, GET_PARAMETERIZE_FAIL, GET_PARAMETERIZE_SUCCESS, getParameterizerSuccess, getParameterizerFail, setParameterize } from './parameterize.actions';
import data from './text.json';
import { setDuplaList } from '@cencosud/puntos-core/src/features/registro/dupla/dupla.action';
import normalizeDuplas from '@cencosud/puntos-core/src/features/registro/dupla/normalizeDuplas';
import { setFooterLinks } from '@cencosud/puntos-core/src/features/ui/footer/footer.actions';
import { setHome } from '@cencosud/puntos-web/src/home/home.actions';
import homeBinder from "@cencosud/puntos-core/src/features/binder/HomeBinder";
export var getParameterizeFlow = function getParameterizeFlow(_ref) {
  var dispatch = _ref.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_PARAMETERIZE) {
        return nextResponse;
      }
      return dispatch(apiRequest(contentV3Endpoints.mensajes.get(), getParameterizerSuccess, getParameterizerFail));
    };
  };
};
export var processGetParameterize = function processGetParameterize(_ref2) {
  var dispatch = _ref2.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      switch (type) {
        case GET_PARAMETERIZE_SUCCESS:
          {
            var _data = payload.payload;
            dispatch(setHome(homeBinder(_data)));
            dispatch(setFooterLinks(_data.footer));
            dispatch(setDuplaList(normalizeDuplas(_data.inscriptionDuples)));
            return dispatch(setParameterize(_data));
          }
        case GET_PARAMETERIZE_FAIL:
          {
            var parameterize = data.payload;
            return dispatch(setParameterize(parameterize));
          }
        default:
          return nextResponse;
      }
    };
  };
};
export default [getParameterizeFlow, processGetParameterize];