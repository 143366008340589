/* istanbul ignore file */
import { SET_PUNTOS } from '@cencosud/puntos-core/src/features/ui/header/Header/TopLevelHeader/CuantosPuntosTengo/CuantosPuntosTengo.actions';

// prettier-ignore
export default (function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _ref = arguments.length > 1 ? arguments[1] : undefined,
    type = _ref.type,
    payload = _ref.payload;
  // NOSONAR
  if (type !== SET_PUNTOS) {
    return state;
  }
  return payload;
});