import methods from './httpMethods';
import apiEndpointCreators from './apiEndpointCreators';
var createEndpoint = apiEndpointCreators.EcommerceEndPoint;
export default {
  commerces: {
    getAll: function getAll(id) {
      return createEndpoint({
        method: methods.get,
        uri: "/socio/partners/".concat(id)
      });
    },
    delete: function _delete() {
      return createEndpoint({
        method: methods.post,
        uri: '/socio/borrar-partner'
      });
    }
  }
};