import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
var getPathname = function getPathname(screen) {
  if (isString(screen)) {
    return screen;
  }
  return isObject(screen) ? screen.pathname : null;
};
export var isPuntosAppScreen = function isPuntosAppScreen(screen) {
  var pathname = getPathname(screen);
  return isString(pathname) && pathname.startsWith('/');
};