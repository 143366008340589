import normalizeUri from '@cencosud/puntos-core/src/features/common/url/normalizeUri';

/**
 * getCategoria
 * @param {array} categorias - array de objetos con las categorias totales
 * @param {string} categoria - nombre de la categoria
 * @returns Devuelve un objeto de categoria
 */
export default (function (categorias, categoria) {
  return categorias.find(function (_categoria) {
    return normalizeUri(_categoria.nombre) === normalizeUri(categoria);
  });
});