import _toConsumableArray from "/home/jenkins/agent/workspace/UCTION_FRONT_deploy_front_master/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import { getPromocionesFail, getPromocionesSuccess, getPromocionesSuccessByHash, getPromocionesFailByHash, setPromociones, GET_PROMOCIONES, GET_PROMOCIONES_FAIL, GET_PROMOCIONES_SUCCESS, GET_PROMOCIONES_BY_HASH, GET_PROMOCIONES_SUCCESS_BY_HASH, GET_CUPONES, getCupones, getCuponesFail, getCuponesFailByHash, getCuponesSuccess, getCuponesSuccessByHash, setCupones, GET_CUPONES_BY_HASH, GET_CUPONES_SUCCESS, GET_CUPONES_SUCCESS_BY_HASH, GET_CUPONES_FAIL, GET_CUPONES_FAIL_BY_HASH, SET_CUPONES, getDesafiosSuccess, getDesafiosFail, GET_DESAFIOS_BY_HASH, GET_DESAFIOS, GET_DESAFIOS_SUCCESS, GET_DESAFIOS_FAIL, setDesafios, GET_GANADOS, GET_GANADOS_BY_HASH, getGanadosSuccess, getImageSuccess, getImageFail, getGanadosFail, GET_GANADOS_SUCCESS, GET_GANADOS_FAIL, setGanados, setGanadosCargados, ACTIVAR_CHALLENGE, ACTIVAR_CHALLENGE_SUCCESS, ACTIVAR_CHALLENGE_FAIL, activarChallengeSuccess, activarChallengeFail, ACTIVAR_CUPON, activarCuponSuccess, activarCuponDescuentoSuccess, activarCuponFail, ACTIVAR_CUPON_SUCCESS, ACTIVAR_CUPON_FAIL, setCuponActivo, setCuponDescuentoActivo, DESCARTAR_CUPON, descartarCuponSuccess, descartarCuponFail, DESCARTAR_CUPON_SUCCESS, DESCARTAR_CUPON_FAIL, GET_IMAGE_COUPON_BY_TOKEN, GET_IMAGE_COUPON_BY_HASH, GET_IMAGE_SUCCESS, GET_IMAGE_FAIL, GET_OFERTAS_HOME, GET_OFERTAS_HOME_SUCCESS, GET_OFERTAS_HOME_FAIL, getOfertasHomeSuccess, getOfertasHomeFail, setHome, ACTIVAR_CUPON_DESCUENTO, DESCARTAR_CUPON_DESCUENTO, descartarCuponDescuentoSuccess, descartarCuponDescuentoFail, ACTIVAR_CUPON_DESCUENTO_SUCCESS, ACTIVAR_CUPON_DESCUENTO_FAIL, DESCARTAR_CUPON_DESCUENTO_SUCCESS, DESCARTAR_CUPON_DESCUENTO_FAIL, activarCuponDescuentoFail, GET_MULTI_IMAGE_COUPON_BY_HASH, getMultiImageByHash, getMultiImageSuccess, getMultiImageFail, SET_TIME_DESCARGAR_CUPONES, GET_MULTI_IMAGE_SUCCESS, GET_MULTI_IMAGE_FAIL, GET_MULTI_IMAGE_COUPON_BY_TOKEN, ACTIVAR_ALL_CUPON, activarAllCuponSuccess, activarAllCuponFail } from './promociones.actions';
import { activarAllChallengeFlow, activarAllCuponDescuentoFlow, activarAllCuponFlow, processActivarAllChallenge, processActivarAllCupon, processActivarAllCuponDescuento } from './middlewares/activateAll';
import { apiRequest } from '@cencosud/puntos-core/src/features/api/api.actions';
import ofertasEndpoints from '../api/endpoints/ofertasEndpoints';
import { formatPromociones, formatDesafios, formatGanados, formatHomeOfertas } from './promocionesFormatter';
import contentV3Endpoints from '../api/endpoints/contentV3Endpoints';
import { pushRoute } from '../router/history.actions';
import AuthenticationError from '../auth/AuthenticationError';
import offerHomeBinder from '@cencosud/puntos-core/src/features/binder/OfferHomeBinder';
var X_PLATFORM_ID = 1;
export var getPromocionesFlowByHash = function getPromocionesFlowByHash(_ref) {
  var dispatch = _ref.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_PROMOCIONES_BY_HASH) {
        return nextResponse;
      }
      var hash = action.payload.hash;
      var request = apiRequest(ofertasEndpoints.getByHash(hash), getPromocionesSuccessByHash, getPromocionesFailByHash, {
        body: {
          hash: hash
        }
      });
      return dispatch(request);
    };
  };
};
export var getDescuentosFlowByHash = function getDescuentosFlowByHash(_ref2) {
  var dispatch = _ref2.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_CUPONES_BY_HASH) {
        return nextResponse;
      }
      var hash = action.payload.hash;
      var request = apiRequest(ofertasEndpoints.getDiscountByHash(hash), getCuponesSuccessByHash, getCuponesFailByHash, {
        body: {
          hash: hash
        }
      });
      return dispatch(request);
    };
  };
};
export var getPromocionesFlow = function getPromocionesFlow(_ref3) {
  var dispatch = _ref3.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_PROMOCIONES) {
        return nextResponse;
      }
      var request = apiRequest(ofertasEndpoints.getByToken(), getPromocionesSuccess, getPromocionesFail, {
        auth: true
      });
      return dispatch(request);
    };
  };
};
export var getDescuentosFlow = function getDescuentosFlow(_ref4) {
  var dispatch = _ref4.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_CUPONES) {
        return nextResponse;
      }
      var request = apiRequest(ofertasEndpoints.getDiscountByToken(), getCuponesSuccess, getCuponesFail, {
        auth: true
      });
      return dispatch(request);
    };
  };
};
export var getDesafiosFlowByHash = function getDesafiosFlowByHash(_ref5) {
  var dispatch = _ref5.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_DESAFIOS_BY_HASH) {
        return nextResponse;
      }
      var hash = action.payload.hash;
      var request = apiRequest(ofertasEndpoints.getChallengeByHash(hash), getDesafiosSuccess, getDesafiosFail, {
        body: {
          hash: hash
        }
      });
      return dispatch(request);
    };
  };
};
export var getDesafiosFlow = function getDesafiosFlow(_ref6) {
  var dispatch = _ref6.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_DESAFIOS) {
        return nextResponse;
      }
      var request = apiRequest(ofertasEndpoints.getChallengeByToken(), getDesafiosSuccess, getDesafiosFail, {
        auth: true
      });
      return dispatch(request);
    };
  };
};
export var processDesafios = function processDesafios(_ref7) {
  var dispatch = _ref7.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_DESAFIOS_SUCCESS && action.type !== GET_DESAFIOS_FAIL) {
        return nextResponse;
      }
      var type = action.type,
        payload = action.payload;
      switch (type) {
        case GET_DESAFIOS_SUCCESS:
          {
            var _payload$monthly_offe, _payload$monthly_offe2;
            var desafiosDisponibles = (payload === null || payload === void 0 ? void 0 : (_payload$monthly_offe = payload.monthly_offers) === null || _payload$monthly_offe === void 0 ? void 0 : _payload$monthly_offe.elements) || (payload === null || payload === void 0 ? void 0 : (_payload$monthly_offe2 = payload['monthly_offers ']) === null || _payload$monthly_offe2 === void 0 ? void 0 : _payload$monthly_offe2.elements) || [];
            var desafios = formatDesafios(desafiosDisponibles);
            return Promise.all([dispatch(setDesafios(desafios))]);
          }
        case GET_PROMOCIONES_FAIL:
          {
            return Promise.all([payload]);
          }
        default:
          return Promise.all([]);
      }
    };
  };
};
export var processPromociones = function processPromociones(_ref8) {
  var dispatch = _ref8.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_PROMOCIONES_SUCCESS && action.type !== GET_PROMOCIONES_FAIL) {
        return nextResponse;
      }
      var type = action.type,
        payload = action.payload;
      switch (type) {
        case GET_PROMOCIONES_SUCCESS:
          {
            var _payload$available_of, _payload$actives_offe;
            var promocionesDisponibles = (payload === null || payload === void 0 ? void 0 : (_payload$available_of = payload.available_offers) === null || _payload$available_of === void 0 ? void 0 : _payload$available_of.elements) || [];
            var promocionesActivas = (payload === null || payload === void 0 ? void 0 : (_payload$actives_offe = payload.actives_offers) === null || _payload$actives_offe === void 0 ? void 0 : _payload$actives_offe.elements) || [];
            var promociones = formatPromociones([].concat(_toConsumableArray(promocionesDisponibles), _toConsumableArray(promocionesActivas)));
            return Promise.all([dispatch(setPromociones(promociones))]);
          }
        case GET_PROMOCIONES_FAIL:
          {
            return Promise.all([payload]);
          }
        default:
          return Promise.all([]);
      }
    };
  };
};
export var processDescuentos = function processDescuentos(_ref9) {
  var dispatch = _ref9.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_CUPONES_SUCCESS && action.type !== GET_CUPONES_FAIL) {
        return nextResponse;
      }
      var type = action.type,
        payload = action.payload;
      switch (type) {
        case GET_CUPONES_SUCCESS:
          {
            var _payload$available_of2, _payload$actives_offe2;
            var cuponesDisponibles = (payload === null || payload === void 0 ? void 0 : (_payload$available_of2 = payload.available_offers) === null || _payload$available_of2 === void 0 ? void 0 : _payload$available_of2.elements) || [];
            var cuponesActivas = (payload === null || payload === void 0 ? void 0 : (_payload$actives_offe2 = payload.actives_offers) === null || _payload$actives_offe2 === void 0 ? void 0 : _payload$actives_offe2.elements) || [];
            var cupones = formatPromociones([].concat(_toConsumableArray(cuponesDisponibles), _toConsumableArray(cuponesActivas)));
            return Promise.all([dispatch(setCupones(cupones))]);
          }
        case GET_CUPONES_FAIL:
          {
            return Promise.all([payload]);
          }
        default:
          return Promise.all([]);
      }
    };
  };
};
export var getImagenByHash = function getImagenByHash(_ref10) {
  var dispatch = _ref10.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_IMAGE_COUPON_BY_HASH) {
        return nextResponse;
      }
      var _action$payload = action.payload,
        hash = _action$payload.hash,
        id = _action$payload.id;
      var request = apiRequest(ofertasEndpoints.downloadCouponImageByHash(hash, id), getImageSuccess, getImageFail, {
        body: {
          hash: hash
        }
      });
      return dispatch(request);
    };
  };
};
export var getImagenByToken = function getImagenByToken(_ref11) {
  var dispatch = _ref11.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_IMAGE_COUPON_BY_TOKEN) {
        return nextResponse;
      }
      var id = action.payload.id;
      var request = apiRequest(ofertasEndpoints.downloadCouponImageByToken(id), getImageSuccess, getImageFail);
      return dispatch(request);
    };
  };
};
export var getMultiImagenTokenFlow = function getMultiImagenTokenFlow(_ref12) {
  var dispatch = _ref12.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_MULTI_IMAGE_COUPON_BY_TOKEN) {
        return nextResponse;
      }
      var request = apiRequest(ofertasEndpoints.downloadMultiCouponImageByToken(), getMultiImageSuccess, getMultiImageFail);
      return dispatch(request);
    };
  };
};
export var processDownloadImage = function processDownloadImage(_ref13) {
  var dispatch = _ref13.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_IMAGE_SUCCESS && action.type !== GET_IMAGE_FAIL) {
        return nextResponse;
      }
      var type = action.type,
        payload = action.payload;
      switch (type) {
        case GET_IMAGE_SUCCESS:
          {
            return payload;
          }
        case GET_IMAGE_FAIL:
          {
            return false;
          }
        default:
          return payload;
      }
    };
  };
};
export var processDownloadMultiImage = function processDownloadMultiImage(_ref14) {
  var dispatch = _ref14.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_MULTI_IMAGE_SUCCESS && action.type !== GET_MULTI_IMAGE_FAIL) {
        return nextResponse;
      }
      var type = action.type,
        payload = action.payload;
      switch (type) {
        case GET_MULTI_IMAGE_SUCCESS:
          {
            return payload;
          }
        case GET_MULTI_IMAGE_FAIL:
          {
            return false;
          }
        default:
          return payload;
      }
    };
  };
};
export var getGanadosFlow = function getGanadosFlow(_ref15) {
  var dispatch = _ref15.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_GANADOS) {
        return nextResponse;
      }
      var date = action.payload.date;
      var request = apiRequest(ofertasEndpoints.getWonByToken(), getGanadosSuccess, getGanadosFail, {
        body: {
          criteria: date || '*'
        },
        auth: true
      });
      return dispatch(request);
    };
  };
};
export var getGanadosFlowByHash = function getGanadosFlowByHash(_ref16) {
  var dispatch = _ref16.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_GANADOS_BY_HASH) {
        return nextResponse;
      }
      var _action$payload2 = action.payload,
        hash = _action$payload2.hash,
        date = _action$payload2.date;
      var request = apiRequest(ofertasEndpoints.getWonByHash(hash), getGanadosSuccess, getGanadosFail, {
        body: {
          criteria: date || '*',
          hash: hash
        }
      });
      return dispatch(request);
    };
  };
};
export var processGanados = function processGanados(_ref17) {
  var dispatch = _ref17.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_GANADOS_SUCCESS && action.type !== GET_GANADOS_FAIL) {
        return nextResponse;
      }
      var type = action.type,
        payload = action.payload;
      switch (type) {
        case GET_GANADOS_SUCCESS:
          {
            var ganadosDisponibles = (payload === null || payload === void 0 ? void 0 : payload.coupon_wins) || [];
            var ganados = formatGanados(ganadosDisponibles);
            return Promise.all([dispatch(setGanadosCargados(true)), dispatch(setGanados(ganados))]);
          }
        case GET_GANADOS_FAIL:
          {
            return Promise.all([dispatch(setGanadosCargados(true)), dispatch(setGanados([]))]);
          }
        default:
          return Promise.all([]);
      }
    };
  };
};
export var activarChallengeFlow = function activarChallengeFlow(_ref18) {
  var dispatch = _ref18.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== ACTIVAR_CHALLENGE) {
        return nextResponse;
      }
      var _action$payload3 = action.payload,
        mode = _action$payload3.mode,
        hash = _action$payload3.hash,
        challenge = _action$payload3.challenge,
        tipo = _action$payload3.tipo;
      var request = mode === 'token' ? apiRequest(ofertasEndpoints.activateChallengeByToken(challenge), function (response) {
        return activarChallengeSuccess(response, tipo);
      }, activarChallengeFail, {
        headers: {
          'x-platform-id': X_PLATFORM_ID
        },
        auth: true
      }) : apiRequest(ofertasEndpoints.activateChallengeByHash(challenge, hash), function (response) {
        return activarChallengeSuccess(response, tipo);
      }, activarChallengeFail, {
        headers: {
          'x-platform-id': X_PLATFORM_ID
        },
        body: {
          hash: hash
        }
      });
      return dispatch(request);
    };
  };
};
export var activarCuponFlow = function activarCuponFlow(_ref19) {
  var dispatch = _ref19.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== ACTIVAR_CUPON) {
        return nextResponse;
      }
      var _action$payload4 = action.payload,
        mode = _action$payload4.mode,
        hash = _action$payload4.hash,
        cupon = _action$payload4.cupon,
        tipo = _action$payload4.tipo;
      var request = mode === 'token' ? apiRequest(ofertasEndpoints.activateCouponByToken(cupon), function (response) {
        return activarCuponSuccess(response, tipo);
      }, activarCuponFail, {
        headers: {
          'x-platform-id': X_PLATFORM_ID
        },
        auth: true
      }) : apiRequest(ofertasEndpoints.activateCouponByHash(cupon, hash), function (response) {
        return activarCuponSuccess(response, tipo);
      }, activarCuponFail, {
        headers: {
          'x-platform-id': X_PLATFORM_ID
        },
        body: {
          hash: hash
        }
      });
      return dispatch(request);
    };
  };
};
export var activarDiscountFlow = function activarDiscountFlow(_ref20) {
  var dispatch = _ref20.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== ACTIVAR_CUPON_DESCUENTO) {
        return nextResponse;
      }
      var _action$payload5 = action.payload,
        mode = _action$payload5.mode,
        hash = _action$payload5.hash,
        cupon = _action$payload5.cupon,
        tipo = _action$payload5.tipo;
      var request = mode === 'token' ? apiRequest(ofertasEndpoints.activateDiscountByToken(cupon), function (response) {
        return activarCuponDescuentoSuccess(response, tipo);
      }, activarCuponDescuentoFail, {
        headers: {
          'x-platform-id': X_PLATFORM_ID
        },
        auth: true
      }) : apiRequest(ofertasEndpoints.activateDiscountByHash(cupon, hash), function (response) {
        return activarCuponDescuentoSuccess(response, tipo);
      }, activarCuponDescuentoFail, {
        headers: {
          'x-platform-id': X_PLATFORM_ID
        },
        body: {
          hash: hash
        }
      });
      return dispatch(request);
    };
  };
};
export var processActivarChallenge = function processActivarChallenge(_ref21) {
  var dispatch = _ref21.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== ACTIVAR_CHALLENGE_SUCCESS && action.type !== ACTIVAR_CHALLENGE_FAIL) {
        return nextResponse;
      }
      var type = action.type,
        payload = action.payload;
      switch (type) {
        case ACTIVAR_CHALLENGE_SUCCESS:
          {
            var formatter = payload.tipo === 'promocion' ? formatPromociones : formatDesafios;
            var cupon = formatter([payload.promo]);
            return dispatch(setCuponActivo(cupon[0], payload.tipo));
          }
        case ACTIVAR_CHALLENGE_FAIL:
          {
            return payload;
          }
        default:
          return Promise.all([]);
      }
    };
  };
};
export var processActivarCupon = function processActivarCupon(_ref22) {
  var dispatch = _ref22.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== ACTIVAR_CUPON_SUCCESS && action.type !== ACTIVAR_CUPON_FAIL) {
        return nextResponse;
      }
      var type = action.type,
        payload = action.payload;
      switch (type) {
        case ACTIVAR_CUPON_SUCCESS:
          {
            var formatter = payload.tipo === 'promocion' ? formatPromociones : formatDesafios;
            var cupon = formatter([payload.promo]);
            return dispatch(setCuponActivo(cupon[0], payload.tipo));
          }
        case ACTIVAR_CUPON_FAIL:
          {
            return payload;
          }
        default:
          return Promise.all([]);
      }
    };
  };
};
export var processActivarCuponDescuento = function processActivarCuponDescuento(_ref23) {
  var dispatch = _ref23.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== ACTIVAR_CUPON_DESCUENTO_SUCCESS && action.type !== ACTIVAR_CUPON_DESCUENTO_FAIL) {
        return nextResponse;
      }
      var type = action.type,
        payload = action.payload;
      switch (type) {
        case ACTIVAR_CUPON_DESCUENTO_SUCCESS:
          {
            var formatter = payload.tipo === 'descuento' ? formatPromociones : formatDesafios;
            var cupon = formatter([payload.promo]);
            return dispatch(setCuponDescuentoActivo(cupon[0], payload.tipo));
          }
        case ACTIVAR_CUPON_DESCUENTO_FAIL:
          {
            return payload;
          }
        default:
          return Promise.all([]);
      }
    };
  };
};
export var descartarCuponFlow = function descartarCuponFlow(_ref24) {
  var dispatch = _ref24.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== DESCARTAR_CUPON) {
        return nextResponse;
      }
      var _action$payload6 = action.payload,
        mode = _action$payload6.mode,
        hash = _action$payload6.hash,
        cupon = _action$payload6.cupon;
      var request = mode === 'token' ? apiRequest(ofertasEndpoints.discardCouponByToken(cupon), descartarCuponSuccess, descartarCuponFail, {
        headers: {
          'x-platform-id': X_PLATFORM_ID
        },
        auth: true
      }) : apiRequest(ofertasEndpoints.discardCouponByHash(cupon, hash), descartarCuponSuccess, descartarCuponFail, {
        headers: {
          'x-platform-id': X_PLATFORM_ID
        },
        body: {
          hash: hash
        }
      });
      return dispatch(request);
    };
  };
};
export var descartarCuponDescuentoFlow = function descartarCuponDescuentoFlow(_ref25) {
  var dispatch = _ref25.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== DESCARTAR_CUPON_DESCUENTO) {
        return nextResponse;
      }
      var _action$payload7 = action.payload,
        mode = _action$payload7.mode,
        hash = _action$payload7.hash,
        cupon = _action$payload7.cupon;
      var request = mode === 'token' ? apiRequest(ofertasEndpoints.discardDiscountByToken(cupon), descartarCuponDescuentoSuccess, descartarCuponDescuentoFail, {
        headers: {
          'x-platform-id': X_PLATFORM_ID
        },
        auth: true
      }) : apiRequest(ofertasEndpoints.discardDiscountByHash(cupon, hash), descartarCuponDescuentoSuccess, descartarCuponDescuentoFail, {
        headers: {
          'x-platform-id': X_PLATFORM_ID
        },
        body: {
          hash: hash
        }
      });
      return dispatch(request);
    };
  };
};
export var processDescartarCupon = function processDescartarCupon() {
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== DESCARTAR_CUPON_SUCCESS && action.type !== DESCARTAR_CUPON_FAIL) {
        return nextResponse;
      }
      var type = action.type,
        payload = action.payload;
      switch (type) {
        case DESCARTAR_CUPON_SUCCESS:
          {
            return payload;
          }
        case DESCARTAR_CUPON_FAIL:
          {
            return payload;
          }
        default:
          return Promise.all([]);
      }
    };
  };
};
export var processDescartarCuponDescuento = function processDescartarCuponDescuento() {
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== DESCARTAR_CUPON_DESCUENTO_SUCCESS && action.type !== DESCARTAR_CUPON_DESCUENTO_FAIL) {
        return nextResponse;
      }
      var type = action.type,
        payload = action.payload;
      switch (type) {
        case DESCARTAR_CUPON_DESCUENTO_SUCCESS:
          {
            return payload;
          }
        case DESCARTAR_CUPON_DESCUENTO_FAIL:
          {
            return payload;
          }
        default:
          return Promise.all([]);
      }
    };
  };
};
export var getOfertasHomeFlow = function getOfertasHomeFlow(_ref26) {
  var dispatch = _ref26.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_OFERTAS_HOME) {
        return nextResponse;
      }
      return dispatch(apiRequest(contentV3Endpoints.ofertasHome.get(), getOfertasHomeSuccess, getOfertasHomeFail));
    };
  };
};
export var processGetOfertasHome = function processGetOfertasHome(_ref27) {
  var dispatch = _ref27.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_OFERTAS_HOME_SUCCESS && action.type !== GET_OFERTAS_HOME_FAIL) {
        return nextResponse;
      }
      var type = action.type,
        payload = action.payload;
      switch (type) {
        case GET_OFERTAS_HOME_SUCCESS:
          {
            var home = payload.payload;
            return dispatch(setHome(offerHomeBinder(home)));
          }
        case GET_OFERTAS_HOME_FAIL:
          {
            if (payload instanceof AuthenticationError) {
              return nextResponse;
            }
            return dispatch(pushRoute('/error'));
          }
        default:
          return nextResponse;
      }
    };
  };
};
export var getMultiImagenHashFlow = function getMultiImagenHashFlow(_ref28) {
  var dispatch = _ref28.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_MULTI_IMAGE_COUPON_BY_HASH) {
        return nextResponse;
      }
      var hash = action.payload.hash;
      var request = apiRequest(ofertasEndpoints.downloadMultiCouponImageByHash(hash), getMultiImageSuccess, getMultiImageFail, {
        body: {
          hash: hash
        }
      });
      return dispatch(request);
    };
  };
};
export var setTimer = function setTimer(_ref29) {
  var dispatch = _ref29.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== SET_TIME_DESCARGAR_CUPONES) {
        return nextResponse;
      }
      return true;
    };
  };
};
export default [getPromocionesFlow, getDescuentosFlow, getDesafiosFlow, getImagenByHash, getImagenByToken, activarChallengeFlow, getPromocionesFlowByHash, getDescuentosFlowByHash, getDesafiosFlowByHash, getGanadosFlowByHash, processPromociones, processDescuentos, processDesafios, processDownloadImage, processDownloadMultiImage, getGanadosFlow, processGanados, processActivarChallenge, activarCuponFlow, activarDiscountFlow, processActivarCupon, processActivarCuponDescuento, descartarCuponFlow, descartarCuponDescuentoFlow, processDescartarCupon, processDescartarCuponDescuento, getOfertasHomeFlow, processGetOfertasHome, getMultiImagenTokenFlow, getMultiImagenHashFlow, setTimer, activarAllChallengeFlow, activarAllCuponDescuentoFlow, activarAllCuponFlow, processActivarAllChallenge, processActivarAllCupon, processActivarAllCuponDescuento];