import isArray from 'lodash/isArray';
import { SET_PARTNERS } from './partners.actions';
// prettier-ignore
export default (function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var _ref = arguments.length > 1 ? arguments[1] : undefined,
    type = _ref.type,
    payload = _ref.payload;
  // NOSONAR
  if (type !== SET_PARTNERS) {
    return state;
  }
  if (!isArray(payload)) {
    return state;
  }
  return payload.map(function (partner) {
    return {
      id: partner.nombre,
      host: partner.redirect
    };
  });
});