var namespace = '[registro]';
export var INTENTO_REGISTRO = "".concat(namespace, " intento de registro");
export var REGISTRO_EXITOSO = "".concat(namespace, " registro exitoso");
export var REGISTRO_FALLIDO = "".concat(namespace, " registro fallido");
export var intentoRegistro = function intentoRegistro(_ref, showSuccess) {
  var nombre = _ref.nombre,
    apellido = _ref.apellido,
    email = _ref.email,
    rut = _ref.rut,
    telefono = _ref.telefono,
    fechaNacimiento = _ref.fechaNacimiento,
    contrasegna = _ref.contrasegna,
    aceptaCondiciones = _ref.aceptaCondiciones,
    captcha = _ref.captcha,
    campaign = _ref.campaign,
    referido = _ref.referido;
  return {
    type: INTENTO_REGISTRO,
    payload: {
      registro: {
        nombre: nombre,
        apellido: apellido,
        email: email,
        rut: rut,
        telefono: telefono,
        fechaNacimiento: fechaNacimiento,
        contrasegna: contrasegna,
        aceptaCondiciones: aceptaCondiciones,
        captcha: captcha,
        campaign: campaign,
        referido: referido
      },
      showSuccess: showSuccess
    }
  };
};
export var registroExitoso = function registroExitoso(response, showSuccess) {
  return {
    type: REGISTRO_EXITOSO,
    payload: {
      response: response,
      showSuccess: showSuccess
    }
  };
};
export var registroFallido = function registroFallido(cause) {
  return {
    type: REGISTRO_FALLIDO,
    payload: cause
  };
};