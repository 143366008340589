/**
 *
 * @param nombre
 * @param apellidoPaterno
 * @param apellidoMaterno
 * @param email
 * @param rut
 * @param telefono
 * @param fechaNacimiento
 * @param contrasegna
 * @param aceptaCondiciones
 * @param captcha
 * @return {{rut: *, nombres: *, password: *, aceptaCondiciones: *, token: *}}
 */
export default (function (rut, nombres, password, aceptaCondiciones, token, apellidoPaterno, apellidoMaterno, fechaNacimiento, sexo, estadoCivil, referido) {
  return {
    rut: rut,
    nombres: nombres,
    password: password,
    aceptaCondiciones: aceptaCondiciones,
    token: token,
    apellidoPaterno: apellidoPaterno,
    apellidoMaterno: apellidoMaterno,
    fechaNacimiento: fechaNacimiento,
    sexo: sexo,
    estadoCivil: estadoCivil,
    referido: referido
  };
});