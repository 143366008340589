var namespace = '[ui]';
export var SHOW_NOTIFIER = "".concat(namespace, " show notifier");
export var HIDE_NOTIFIER = "".concat(namespace, " hide notifier");
export var SHOW_PROGRESS_BAR = "".concat(namespace, " show progress bar");
export var HIDE_PROGRESS_BAR = "".concat(namespace, " hide progress bar");
export var SHOW_PRIMARY_PROGRESS = "".concat(namespace, " show primary progress");
export var HIDE_PRIMARY_PROGRESS = "".concat(namespace, " hide primary progress");
export var SHOW_SECONDARY_PROGRESS = "".concat(namespace, " show secondary progress");
export var HIDE_SECONDARY_PROGRESS = "".concat(namespace, " hide secondary progress");
export var SHOW_FULLSCREEN_PROGRESS = "".concat(namespace, " show fullscreen progress");
export var HIDE_FULLSCREEN_PROGRESS = "".concat(namespace, " hide fullscreen progress");
export var SHOW_TOAST_NOTIFIER = "".concat(namespace, " show toast notifier");
export function showNotifier(message) {
  return {
    type: SHOW_NOTIFIER,
    payload: {
      message: message
    }
  };
}
export function hideNotifier() {
  return {
    type: HIDE_NOTIFIER
  };
}
export var showProgressBar = function showProgressBar() {
  return {
    type: SHOW_PROGRESS_BAR
  };
};
export var hideProgressBar = function hideProgressBar() {
  return {
    type: HIDE_PROGRESS_BAR
  };
};
export var showPrimaryProgress = function showPrimaryProgress() {
  return {
    type: SHOW_PRIMARY_PROGRESS
  };
};
export var hidePrimaryProgressBar = function hidePrimaryProgressBar() {
  return {
    type: HIDE_PRIMARY_PROGRESS
  };
};
export var showSecondaryProgress = function showSecondaryProgress() {
  return {
    type: SHOW_SECONDARY_PROGRESS
  };
};
export var hideSecondaryProgressBar = function hideSecondaryProgressBar() {
  return {
    type: HIDE_SECONDARY_PROGRESS
  };
};
export var showFullscreenProgress = function showFullscreenProgress() {
  return {
    type: SHOW_FULLSCREEN_PROGRESS
  };
};
export var hideFullscreenProgress = function hideFullscreenProgress() {
  return {
    type: HIDE_FULLSCREEN_PROGRESS
  };
};
export var showToastNotifier = function showToastNotifier(payload) {
  return {
    type: SHOW_TOAST_NOTIFIER,
    payload: payload
  };
};