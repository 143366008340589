var friendlyUrl = function friendlyUrl(title) {
  var maxlen = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 200;
  if (!title) return '';
  var len = title.length;
  var prevdash = false;
  var sb = [];
  var c;

  // eslint-disable-next-line
  for (var i = 0; i < len; ++i) {
    c = title[i];
    if (c >= 'a' && c <= 'z' || c >= '0' && c <= '9') {
      sb.push(c);
      prevdash = false;
    } else if (c >= 'A' && c <= 'Z') {
      sb.push(c.toLowerCase());
      prevdash = false;
    } else if (c === ' ' || c === ',' || c === '.' || c === '/' || c === '\\' || c === '-' || c === '_' || c === '=') {
      if (!prevdash && sb.length > 0) {
        sb.push('-');
        prevdash = true;
      }
    } else if (c.charCodeAt(0) >= 128) {
      var remapped = remapInternationalCharToAscii(c);
      if (remapped) {
        sb.push(remapped);
        prevdash = false;
      }
    }
    if (sb.length === maxlen) break;
  }
  if (prevdash) return sb.join('').substring(0, sb.length - 1);
  return sb.join('');
};
function remapInternationalCharToAscii(c) {
  if ('àåáâãåa'.indexOf(c) !== -1) return 'a';
  if ('èéêe'.indexOf(c) !== -1) return 'e';
  if ('ìíîïi'.indexOf(c) !== -1) return 'i';
  if ('òóôõøoð'.indexOf(c) !== -1) return 'o';
  if ('ùúûuu'.indexOf(c) !== -1) return 'u';
  if ('çccc'.indexOf(c) !== -1) return 'c';
  if ('zzž'.indexOf(c) !== -1) return 'z';
  if ('ssšs'.indexOf(c) !== -1) return 's';
  if ('ñn'.indexOf(c) !== -1) return 'n';
  if ('ýÿ'.indexOf(c) !== -1) return 'y';
  if ('gg'.indexOf(c) !== -1) return 'g';
  if (c === 'r') return 'r';
  if (c === 'l') return 'l';
  if (c === 'd') return 'd';
  if (c === 'ä') return 'ae';
  if (c === 'ö') return 'oe';
  if (c === 'ü') return 'ue';
  if (c === 'ß') return 'ss';
  if (c === 'Þ') return 'th';
  if (c === 'h') return 'h';
  if (c === 'j') return 'j';
  if (c === 'ñ') return 'ñ';
  return '';
}
export default friendlyUrl;