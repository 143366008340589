import pipe from 'lodash/fp/pipe';
import config from '@cencosud/puntos-core/src/config';
import withConstructor from './withConstructor';
import apiEndpointWithUrlAccessor from './apiEndpointWithUrlAccessor';
import withApi from './withApi';
import createApi from './createApi';
import withEndpoint from './withEndpoint';
var getEndpointCreator = function getEndpointCreator(_ref) {
  var api = _ref.api,
    getConstructor = _ref.getConstructor;
  return function (_ref2) {
    var method = _ref2.method,
      uri = _ref2.uri,
      uriParams = _ref2.uriParams;
    return pipe(withEndpoint({
      method: method,
      uri: uri,
      uriParams: uriParams
    }), withApi(api), apiEndpointWithUrlAccessor(), withConstructor(getConstructor()))({});
  };
};
var createLoyaltyApi = function createLoyaltyApi() {
  var appendToUrl = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return createApi({
    name: 'loyalty',
    url: config.api.list.loyalty.url + appendToUrl
  });
};
var createSecurityApi = function createSecurityApi() {
  var appendToUrl = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return createApi({
    name: 'security',
    url: config.api.list.security.url + appendToUrl
  });
};
var apiEndpointCreators = {
  /**
   * Crea un endpoint asociado a la api de autenticación de Kong
   * @param {Object.} params
   * @param {string} params.method
   * @param {string} params.uri
   * @param {Object} params.uriParams
   */
  AuthEndpoint: getEndpointCreator({
    api: createApi({
      kongName: createSecurityApi().kongName,
      name: 'auth',
      url: config.api.list.auth.url
    }),
    getConstructor: function getConstructor() {
      return apiEndpointCreators.AuthEndpoint;
    }
  }),
  /**
   * Crea un endpoint asociado a la api de contenido
   * @param {Object.} params
   * @param {string} params.method
   * @param {string} params.uri
   * @param {Object} params.uriParams
   */
  ContentEndpoint: getEndpointCreator({
    api: createApi({
      name: 'content',
      url: config.api.list.content.url
    }),
    getConstructor: function getConstructor() {
      return apiEndpointCreators.ContentEndpoint;
    }
  }),
  ContentV3Endpoint: getEndpointCreator({
    api: createApi({
      name: 'contentv3',
      url: config.api.list.contentV3.url
    }),
    getConstructor: function getConstructor() {
      return apiEndpointCreators.ContentEndpoint;
    }
  }),
  loyalty: {
    /**
     * Crea un endpoint asociado a la api de fidelidad con autenticación
     * @param {Object.} params
     * @param {string} params.method
     * @param {string} params.uri
     * @param {Object} params.uriParams
     */
    PrivateLoyaltyEndpoint: getEndpointCreator({
      api: createLoyaltyApi(config.api.privateEndpoint || '/private'),
      getConstructor: function getConstructor() {
        return apiEndpointCreators.loyalty.PrivateLoyaltyEndpoint;
      }
    }),
    /**
     * Crea un endpoint asociado a la api de fidelidad
     * @param {Object.} params
     * @param {string} params.method
     * @param {string} params.uri
     * @param {Object} params.uriParams
     */
    PublicLoyaltyEndpoint: getEndpointCreator({
      api: createLoyaltyApi(config.api.publicEndpoint || '/public'),
      getConstructor: function getConstructor() {
        return apiEndpointCreators.loyalty.PublicLoyaltyEndpoint;
      }
    })
  },
  security: {
    /**
     * Crea un endpoint asociado a la api de seguridad con autenticación
     * @param {Object.} params
     * @param {string} params.method
     * @param {string} params.uri
     * @param {Object} params.uriParams
     */
    PrivateSecurityEndpoint: getEndpointCreator({
      api: createSecurityApi(config.api.privateEndpoint || '/private'),
      getConstructor: function getConstructor() {
        return apiEndpointCreators.SecurityEndpoint;
      }
    }),
    /**
     * Crea un endpoint asociado a la api de seguridad
     * @param {Object.} params
     * @param {string} params.method
     * @param {string} params.uri
     * @param {Object} params.uriParams
     */
    PublicSecurityEndpoint: getEndpointCreator({
      api: createSecurityApi(config.api.publicEndpoint || '/public'),
      getConstructor: function getConstructor() {
        return apiEndpointCreators.SecurityEndpoint;
      }
    })
  },
  /**
   * Crea un endpoint asociado a la api de registro
   * @param {Object.} params
   * @param {string} params.method
   * @param {string} params.uri
   * @param {Object} params.uriParams
   */
  LogEndpoint: getEndpointCreator({
    api: createApi({
      name: 'log',
      url: "".concat(config.api.list.log.url).concat(config.api.publicEndpoint || '/public')
    }),
    getConstructor: function getConstructor() {
      return apiEndpointCreators.LogEndpoint;
    }
  }),
  /**
   * Crea un endpoint asociado a la api de ecommerce
   */
  EcommerceEndPoint: getEndpointCreator({
    api: createApi({
      name: 'ecommerce',
      url: config.api.list.ecommerce.url
    }),
    getConstructor: function getConstructor() {
      return apiEndpointCreators.EcommerceEndPoint;
    }
  }),
  /**
   * Crea un endpoint asociado a la api de ofertas personalizadas
   * @param {Object} params
   * @param {string} params.method
   * @param {string} params.uri
   * @param {Object} params.uriParams
   */
  OfertasEndpoint: getEndpointCreator({
    api: createApi({
      name: 'ofertas',
      url: config.api.list.ofertas.url
    }),
    getConstructor: function getConstructor() {
      return apiEndpointCreators.OfertasEndpoint;
    }
  })
};
export default apiEndpointCreators;