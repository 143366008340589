import eventTypes from '@cencosud/puntos-web/src/analytics/eventTypes';
import registerEvent from '@cencosud/puntos-web/src/analytics/registerEvent';
export var registerSignUpModalEventClick = function registerSignUpModalEventClick() {
  var event = {
    event: eventTypes.event.user,
    category: eventTypes.categories.usuarioNuevo,
    action: eventTypes.actions.click_link_login,
    name: 'inscríbete'
  };
  registerEvent(event);
};