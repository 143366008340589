import _authEndpoints from './authEndpoints';
import _contentEndpoints from './contentEndpoints';
import _contentV3Endpoints from './contentV3Endpoints';
import _loyaltyEndpoints from './loyaltyEndpoints';
import _securityEndpoints from './securityEndpoints';
import _ecommerceEndpoints from './ecommerceEndpoints';
export var authEndpoints = _authEndpoints;
export var contentEndpoints = _contentEndpoints;
export var contentV3Endpoints = _contentV3Endpoints;
export var loyaltyEndpoints = _loyaltyEndpoints;
export var securityEndpoints = _securityEndpoints;
export var ecommerceEndpoints = _ecommerceEndpoints;