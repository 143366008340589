var namespace = '[log]';
export var GET_LOG_LEVEL = "".concat(namespace, " obtener nivel activo de registro");
export var GET_LOG_LEVEL_SUCCESS = "".concat(GET_LOG_LEVEL, ": success");
export var GET_LOG_LEVEL_FAIL = "".concat(GET_LOG_LEVEL, ": fail");
export var SET_LOG_LEVEL = "".concat(namespace, " establecer nivel de registro");
export var LOG_ERROR = "".concat(namespace, " error");
export var LOG_INFO = "".concat(namespace, " info");
export var getLogLevel = function getLogLevel() {
  return {
    type: GET_LOG_LEVEL
  };
};
export var getLogLevelSuccess = function getLogLevelSuccess(canje) {
  return {
    type: GET_LOG_LEVEL_SUCCESS,
    payload: canje
  };
};
export var getLogLevelError = function getLogLevelError(cause) {
  return {
    type: GET_LOG_LEVEL_FAIL,
    payload: cause
  };
};
export var setLogLevel = function setLogLevel(activeLevel) {
  return {
    type: SET_LOG_LEVEL,
    payload: {
      activeLevel: activeLevel
    }
  };
};
export var logError = function logError(_ref) {
  var codigoMensaje = _ref.codigoMensaje,
    mensaje = _ref.mensaje,
    request = _ref.request,
    response = _ref.response;
  return {
    type: LOG_ERROR,
    payload: {
      codigoMensaje: codigoMensaje,
      mensaje: mensaje,
      request: request,
      response: response
    }
  };
};
export var logInfo = function logInfo(_ref2) {
  var codigoMensaje = _ref2.codigoMensaje,
    mensaje = _ref2.mensaje,
    request = _ref2.request,
    response = _ref2.response;
  return {
    type: LOG_INFO,
    payload: {
      codigoMensaje: codigoMensaje,
      mensaje: mensaje,
      request: request,
      response: response
    }
  };
};