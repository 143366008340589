var namespace = '[inscripcion]';
export var GET_INSCRIPTION_TYPE = "".concat(namespace, " get inscription type");
export var SET_INSCRIPTION_TYPE = "".concat(namespace, " set inscription type");
export var getInscriptionType = function getInscriptionType() {
  return {
    type: GET_INSCRIPTION_TYPE
  };
};
export var setInscriptionType = function setInscriptionType(response) {
  return {
    type: SET_INSCRIPTION_TYPE,
    payload: response
  };
};