import { apiRequest } from '@cencosud/puntos-core/src/features/api/api.actions';
import { GET_FEATURED_PRODUCT, GET_FEATURED_PRODUCT_SUCCESS, GET_FEATURED_PRODUCT_FAIL, getFeaturedProductSuccess, getFeaturedProductFail, setFeaturedProduct } from './productoDestacado.actions';
import { pushRoute } from '@cencosud/puntos-core/src/features/router/history.actions';
import { showPrimaryProgress, hidePrimaryProgressBar } from '@cencosud/puntos-core/src/features/ui/ui.actions';
import AuthenticationError from '@cencosud/puntos-core/src/features/auth/AuthenticationError';
import { contentV3Endpoints } from '@cencosud/puntos-core/src/features/api/endpoints';
import eventTypes from '@cencosud/puntos-web/src/analytics/eventTypes';
import registerEvent from '@cencosud/puntos-web/src/analytics/registerEvent';
import listProductStoreBinder from '@cencosud/puntos-core/src/features/binder/ListProductStoreBinder';
export var getFlowFeaturedProduct = function getFlowFeaturedProduct(_ref) {
  var dispatch = _ref.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_FEATURED_PRODUCT) {
        return nextResponse;
      }
      return Promise.all([dispatch(showPrimaryProgress()), dispatch(apiRequest(contentV3Endpoints.productos.getDestacados(), getFeaturedProductSuccess, getFeaturedProductFail))]);
    };
  };
};
export var processFeaturedProduct = function processFeaturedProduct(_ref2) {
  var dispatch = _ref2.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      if (type !== GET_FEATURED_PRODUCT_SUCCESS && type !== GET_FEATURED_PRODUCT_FAIL) {
        return nextResponse;
      }
      var progressResponse = dispatch(hidePrimaryProgressBar());
      // eslint-disable-next-line default-case
      switch (type) {
        case GET_FEATURED_PRODUCT_SUCCESS:
          {
            var items = payload.payload.items;
            return Promise.all([progressResponse, dispatch(setFeaturedProduct(listProductStoreBinder(items.filter(function (producto) {
              return producto.tagNuevo || producto.tagOferta;
            })) // TODO: revisar productos
            ))]);
          }
        case GET_FEATURED_PRODUCT_FAIL:
          {
            if (payload instanceof AuthenticationError) {
              return progressResponse;
            }
            var event = {
              event: eventTypes.event.error,
              category: eventTypes.categories.paginaError,
              name: 'Error al obtener productos destacados (Content -> /catalogo/productos)'
            };
            registerEvent(event);
            return Promise.all([progressResponse, dispatch(pushRoute('/error'))]);
          }
        default:
          return Promise.resolve();
      }
    };
  };
};
export default [getFlowFeaturedProduct, processFeaturedProduct];