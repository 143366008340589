import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import { Region, Provincia, Comuna } from './regiones';
export default (function (regiones) {
  return isArray(regiones) && (regiones === null || regiones === void 0 ? void 0 : regiones.map(function (region) {
    return isObject(region) ? Region({
      id: region.codigo,
      nombre: region.nombre,
      comunas: isArray(region.comunas) && region.comunas.map(function (comuna) {
        return Comuna({
          id: comuna.codigo,
          nombre: comuna.nombre,
          provincia: isObject(comuna.provincia) && Provincia({
            id: comuna.provincia.codigo,
            nombre: comuna.provincia.nombre
          })
        });
      })
    }) : {};
  }));
});