import _defineProperty from "/home/jenkins/agent/workspace/UCTION_FRONT_deploy_front_master/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import isLoggedIn from '@cencosud/puntos-core/src/features/auth/isLoggedIn';
import store from '../state/store';
var withUser = function withUser(event, _ref) {
  var rut = _ref.rut,
    puntos = _ref.puntos;
  return _objectSpread({
    userid: btoa(rut),
    puntos: puntos
  }, event);
};

/**
 * Registra un evento en Google Analytics
 * @param {{name, action: string, event: string, category: string}} event
 */
export default (function (event) {
  var state = store.getState();
  return window.dataLayer.push(isLoggedIn(state) ? withUser(event, state.user) : event);
});