import PropTypes from 'prop-types';
var bool = PropTypes.bool.isRequired;
var object = PropTypes.object.isRequired;
var func = PropTypes.func.isRequired;
export var formikPropTypes = {
  values: object,
  setFieldValue: func,
  errors: object,
  touched: object,
  handleBlur: func,
  handleSubmit: func,
  isSubmitting: bool,
  setFieldTouched: func,
  dirty: bool,
  isValid: bool
};
export var getFormikProps = function getFormikProps(_ref) {
  var errors = _ref.errors,
    handleBlur = _ref.handleBlur,
    handleSubmit = _ref.handleSubmit,
    setFieldTouched = _ref.setFieldTouched,
    setFieldValue = _ref.setFieldValue,
    touched = _ref.touched,
    values = _ref.values,
    isSubmitting = _ref.isSubmitting,
    dirty = _ref.dirty,
    isValid = _ref.isValid;
  return {
    errors: errors,
    handleBlur: handleBlur,
    handleSubmit: handleSubmit,
    setFieldTouched: setFieldTouched,
    setFieldValue: setFieldValue,
    touched: touched,
    values: values,
    isSubmitting: isSubmitting,
    dirty: dirty,
    isValid: isValid
  };
};