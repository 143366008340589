import { apiRequest } from '../api/api.actions';
import { contentV3Endpoints } from '../api/endpoints';
import { GET_INFORMACION, getInformacionSuccess, getInformacionFail, GET_INFORMACION_SUCCESS, setInformacion } from './informaciones.actions';
export var getInformacionFlow = function getInformacionFlow(_ref) {
  var dispatch = _ref.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_INFORMACION) {
        return nextResponse;
      }
      return dispatch(apiRequest(contentV3Endpoints.landingInfo.get(), getInformacionSuccess, getInformacionFail));
    };
  };
};
export var processGetInformacion = function processGetInformacion(_ref2) {
  var dispatch = _ref2.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      if (type !== GET_INFORMACION_SUCCESS) {
        return nextResponse;
      }
      return dispatch(setInformacion(payload.payload));
    };
  };
};
export default [getInformacionFlow, processGetInformacion];