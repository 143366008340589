var namespace = '[contrasena]';
export var CREAR_TEMPORAL = "".concat(namespace, " solicitar nueva temporal");
export var CREAR_TEMPORAL_SUCCESS = "".concat(CREAR_TEMPORAL, ": success");
export var CREAR_TEMPORAL_FAIL = "".concat(CREAR_TEMPORAL, ": fail");
export var CREAR_NUEVA = "".concat(namespace, " intentar crear nueva");
export var CREAR_NUEVA_SUCCESS = "".concat(CREAR_NUEVA, ": success");
export var CREAR_NUEVA_FAIL = "".concat(CREAR_NUEVA, ": fail");
export var SET_CORREO_TEMPORAL = "".concat(namespace, ": set correo temporal");
export var SET_TELEFONO_TEMPORAL = "".concat(namespace, ": set telefono temporal");
export function crearTemporal(rut, recoverMethod, captcha) {
  return {
    type: CREAR_TEMPORAL,
    payload: {
      rut: rut,
      recoverMethod: recoverMethod,
      captcha: captcha
    }
  };
}
export function crearTemporalSuccess(response) {
  return {
    type: CREAR_TEMPORAL_SUCCESS,
    payload: response
  };
}
export function crearTemporalFail(cause) {
  return {
    type: CREAR_TEMPORAL_FAIL,
    payload: cause
  };
}
export function crearNueva(contrasenaActual, contrasena) {
  return {
    type: CREAR_NUEVA,
    payload: {
      contrasenaActual: contrasenaActual,
      contrasena: contrasena
    }
  };
}
export function crearNuevaSuccess(contrasena) {
  return {
    type: CREAR_NUEVA_SUCCESS,
    payload: {
      contrasena: contrasena
    }
  };
}
export function crearNuevaFail(cause) {
  return {
    type: CREAR_NUEVA_FAIL,
    payload: cause
  };
}
export var setCorreoTemporal = function setCorreoTemporal(correo) {
  return {
    type: SET_CORREO_TEMPORAL,
    payload: correo
  };
};
export var setTelefonoTemporal = function setTelefonoTemporal(telefono) {
  return {
    type: SET_TELEFONO_TEMPORAL,
    payload: telefono
  };
};