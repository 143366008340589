/**
 * Region
 * @param id
 * @param nombre
 * @param {Comuna[]} comunas
 * @return {{id: *, nombre: *, comunas: Comuna[]}}
 * @constructor
 */
export var Region = function Region(_ref) {
  var id = _ref.id,
    nombre = _ref.nombre,
    comunas = _ref.comunas;
  return {
    id: id,
    nombre: nombre,
    comunas: comunas || []
  };
};

/**
 * Comuna
 * @param id
 * @param nombre
 * @param {Provincia} provincia
 * @return {{id: *, nombre: *, provincia: Provincia}}
 * @constructor
 */
export var Comuna = function Comuna(_ref2) {
  var id = _ref2.id,
    nombre = _ref2.nombre,
    provincia = _ref2.provincia;
  return {
    id: id,
    nombre: nombre,
    provincia: provincia || {}
  };
};

/**
 * Provincia
 * @param id
 * @param nombre
 * @return {{id: *, nombre: *}}
 * @constructor
 */
export var Provincia = function Provincia(_ref3) {
  var id = _ref3.id,
    nombre = _ref3.nombre;
  return {
    id: id,
    nombre: nombre
  };
};