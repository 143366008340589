import { namespace } from '@cencosud/puntos-core/src/features/usuario/usuario.actions';
export var OBTENER_ADICIONALES = "".concat(namespace, " obtener adicionales");
export var OBTENER_ADICIONALES_SUCCESS = "".concat(OBTENER_ADICIONALES, ": success");
export var OBTENER_ADICIONALES_FAIL = "".concat(OBTENER_ADICIONALES, ": fail");
export var SET_ADICIONALES = "".concat(namespace, " set adicionales");
export var OTORGAR_PERMISO = "".concat(namespace, " otorgar permiso");
export var OTORGAR_PERMISO_SUCCESS = "".concat(OTORGAR_PERMISO, ": success");
export var OTORGAR_PERMISO_FAIL = "".concat(OTORGAR_PERMISO, ": fail");
export var APPEND_TRANSACCION_ASYNC_ADICIONAL = "".concat(namespace, " append transaccion async adicional");
export var ELIMINAR_ADICIONAL = "".concat(namespace, " eliminar socio adicional");
export var ELIMINAR_ADICIONAL_SUCCESS = "".concat(ELIMINAR_ADICIONAL, ": success");
export var ELIMINAR_ADICIONAL_FAIL = "".concat(ELIMINAR_ADICIONAL, ": fail");
export var obtenerAdicionales = function obtenerAdicionales() {
  return {
    type: OBTENER_ADICIONALES
  };
};
export var obtenerAdicionalesSuccess = function obtenerAdicionalesSuccess(adicionales) {
  return {
    type: OBTENER_ADICIONALES_SUCCESS,
    payload: adicionales
  };
};
export var obtenerAdicionalesFail = function obtenerAdicionalesFail(cause) {
  return {
    type: OBTENER_ADICIONALES_FAIL,
    payload: cause
  };
};
export var setAdicionales = function setAdicionales(adicionales) {
  return {
    type: SET_ADICIONALES,
    payload: adicionales
  };
};
export var otorgarPermiso = function otorgarPermiso(run, permisoCanje) {
  return {
    type: OTORGAR_PERMISO,
    payload: {
      run: run,
      permisoCanje: permisoCanje
    }
  };
};
export var otorgarPermisoSuccess = function otorgarPermisoSuccess(run, permisoCanje) {
  return {
    type: OTORGAR_PERMISO_SUCCESS,
    payload: permisoCanje,
    meta: {
      run: run
    }
  };
};
export var otorgarPermisoFail = function otorgarPermisoFail(run, cause) {
  return {
    type: OTORGAR_PERMISO_FAIL,
    payload: cause,
    meta: {
      run: run
    }
  };
};
export var appendTransaccionAsycAdicional = function appendTransaccionAsycAdicional(rut) {
  return {
    type: APPEND_TRANSACCION_ASYNC_ADICIONAL,
    payload: {
      rut: rut
    }
  };
};
export var eliminarAdicional = function eliminarAdicional(rut) {
  return {
    type: ELIMINAR_ADICIONAL,
    payload: {
      rut: rut
    }
  };
};
export var eliminarAdicionalSuccess = function eliminarAdicionalSuccess(eliminar) {
  return {
    type: ELIMINAR_ADICIONAL_SUCCESS,
    payload: eliminar
  };
};
export var eliminarAdicionalFail = function eliminarAdicionalFail(cause) {
  return {
    type: ELIMINAR_ADICIONAL_FAIL,
    payload: cause
  };
};