import * as Yup from 'yup';
import { password, passwordConfirmation, regExpPassword } from '../../../common/form/validaciones';
export var errorContrasegnaIgualActual = 'La contraseña nueva no debe ser igual a la contraseña actual';
export default (function (values) {
  return Yup.object().shape({
    passwordOld: password,
    passwordNew: Yup.string().when('passwordOld', {
      is: function is(passwordOld) {
        return passwordOld.length > 0;
      },
      then: password.matches(new RegExp("^(?!".concat(values.passwordOld, "$)")), errorContrasegnaIgualActual)
    }).matches(/^\d{6}$/, ' ').test('Check Consecutive and Equals Numbers', ' ', function (value) {
      return !regExpPassword.test(value);
    }),
    passwordConfirmation: passwordConfirmation(values.passwordNew)
  });
});