import { ACTIVAR_ALL_CUPON, ACTIVAR_ALL_CUPON_BY_HASH, ACTIVAR_ALL_CUPON_DESCUENTO, ACTIVAR_ALL_CUPON_DESCUENTO_BY_HASH, ACTIVAR_ALL_CUPON_SUCCESS, ACTIVAR_ALL_CUPON_BY_HASH_SUCCESS, ACTIVAR_ALL_CUPON_DESCUENTO_SUCCESS, ACTIVAR_ALL_CUPON_DESCUENTO_BY_HASH_SUCCESS, ACTIVAR_ALL_CUPON_FAIL, ACTIVAR_ALL_CUPON_BY_HASH_FAIL, ACTIVAR_ALL_CUPON_DESCUENTO_FAIL, ACTIVAR_ALL_CUPON_DESCUENTO_BY_HASH_FAIL, SET_ALL_CUPON_ACTIVO, SET_ALL_CUPON_DESCUENTO_ACTIVO, ACTIVAR_ALL_CHALLENGE, ACTIVAR_ALL_CHALLENGE_BY_HASH, ACTIVAR_ALL_CHALLENGE_SUCCESS, ACTIVAR_ALL_CHALLENGE_BY_HASH_SUCCESS, ACTIVAR_ALL_CHALLENGE_FAIL, ACTIVAR_ALL_CHALLENGE_BY_HASH_FAIL, SET_ALL_CHALLENGE_ACTIVO, activarAllCupon, activarAllCuponByHash, activarAllCuponDescuento, activarAllCuponDescuentoByHash, activarAllCuponSuccess, activarAllCuponByHashSuccess, activarAllCuponDescuentoSuccess, activarAllCuponDescuentoByHashSuccess, activarAllCuponFail, activarAllCuponByHashFail, activarAllCuponDescuentoFail, activarAllCuponDescuentoByHashFail, setAllCuponActivo, setAllCuponDescuentoActivo, activarAllChallenge, activarAllChallengeByHash, activarAllChallengeSuccess, activarAllChallengeByHashSuccess, activarAllChallengeFail, activarAllChallengeByHashFail, setAllChallengeActivo } from '../promociones.actions';
import { apiRequest } from '@cencosud/puntos-core/src/features/api/api.actions';
import ofertasEndpoints from '../../api/endpoints/ofertasEndpoints';
import contentEndpoints from '../../api/endpoints/contentEndpoints';
import { pushRoute } from '../../router/history.actions';
import AuthenticationError from '../../auth/AuthenticationError';
var X_PLATFORM_ID = 1;

// Activar puntos extra

export var activarAllCuponFlow = function activarAllCuponFlow(_ref) {
  var dispatch = _ref.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== ACTIVAR_ALL_CUPON && action.type !== ACTIVAR_ALL_CUPON_BY_HASH) {
        return nextResponse;
      }
      var _action$payload = action.payload,
        mode = _action$payload.mode,
        hash = _action$payload.hash,
        tipo = _action$payload.tipo;
      var request = mode === 'token' ? apiRequest(ofertasEndpoints.activateAllCouponByToken(), function (response) {
        return activarAllCuponSuccess(response, tipo);
      }, activarAllCuponFail, {
        headers: {
          'x-platform-id': X_PLATFORM_ID
        },
        auth: true
      }) : apiRequest(ofertasEndpoints.activateAllCouponByHash(), function (response) {
        return activarAllCuponSuccess(response, tipo);
      }, activarAllCuponFail, {
        headers: {
          'x-platform-id': X_PLATFORM_ID
        },
        body: {
          hash: hash
        }
      });
      return dispatch(request);
    };
  };
};
export var processActivarAllCupon = function processActivarAllCupon(_ref2) {
  var dispatch = _ref2.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== ACTIVAR_ALL_CUPON_SUCCESS && action.type !== ACTIVAR_ALL_CUPON_FAIL) {
        return nextResponse;
      }
      var type = action.type,
        payload = action.payload;
      switch (type) {
        case ACTIVAR_ALL_CUPON_SUCCESS:
          {
            return dispatch(setAllCuponActivo());
          }
        case ACTIVAR_ALL_CUPON_FAIL:
          {
            return payload;
          }
        default:
          return Promise.all([]);
      }
    };
  };
};

// Activar todos descuentos

export var activarAllCuponDescuentoFlow = function activarAllCuponDescuentoFlow(_ref3) {
  var dispatch = _ref3.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== ACTIVAR_ALL_CUPON_DESCUENTO && action.type !== ACTIVAR_ALL_CUPON_DESCUENTO_BY_HASH) {
        return nextResponse;
      }
      var _action$payload2 = action.payload,
        mode = _action$payload2.mode,
        hash = _action$payload2.hash,
        tipo = _action$payload2.tipo;
      var request = mode === 'token' ? apiRequest(ofertasEndpoints.activateAllDiscountByToken(), function (response) {
        return activarAllCuponDescuentoSuccess(response, tipo);
      }, activarAllCuponDescuentoFail, {
        headers: {
          'x-platform-id': X_PLATFORM_ID
        },
        auth: true
      }) : apiRequest(ofertasEndpoints.activateAllDiscountByHash(), function (response) {
        return activarAllCuponDescuentoSuccess(response, tipo);
      }, activarAllCuponDescuentoFail, {
        headers: {
          'x-platform-id': X_PLATFORM_ID
        },
        body: {
          hash: hash
        }
      });
      return dispatch(request);
    };
  };
};
export var processActivarAllCuponDescuento = function processActivarAllCuponDescuento(_ref4) {
  var dispatch = _ref4.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== ACTIVAR_ALL_CUPON_DESCUENTO_SUCCESS && action.type !== ACTIVAR_ALL_CUPON_DESCUENTO_FAIL) {
        return nextResponse;
      }
      var type = action.type,
        payload = action.payload;
      switch (type) {
        case ACTIVAR_ALL_CUPON_DESCUENTO_SUCCESS:
          {
            return dispatch(setAllCuponDescuentoActivo());
          }
        case ACTIVAR_ALL_CUPON_DESCUENTO_FAIL:
          {
            return payload;
          }
        default:
          return Promise.all([]);
      }
    };
  };
};

//   Activar all challenge

export var activarAllChallengeFlow = function activarAllChallengeFlow(_ref5) {
  var dispatch = _ref5.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== ACTIVAR_ALL_CHALLENGE && action.type !== ACTIVAR_ALL_CHALLENGE_BY_HASH) {
        return nextResponse;
      }
      var _action$payload3 = action.payload,
        mode = _action$payload3.mode,
        hash = _action$payload3.hash,
        tipo = _action$payload3.tipo;
      var request = mode === 'token' ? apiRequest(ofertasEndpoints.activateAllChallengeByToken(), function (response) {
        return activarAllChallengeSuccess(response, tipo);
      }, activarAllChallengeFail, {
        headers: {
          'x-platform-id': X_PLATFORM_ID
        },
        auth: true
      }) : apiRequest(ofertasEndpoints.activateAllChallengeByHash(hash), function (response) {
        return activarAllChallengeSuccess(response, tipo);
      }, activarAllChallengeFail, {
        headers: {
          'x-platform-id': X_PLATFORM_ID
        },
        body: {
          hash: hash
        }
      });
      return dispatch(request);
    };
  };
};
export var processActivarAllChallenge = function processActivarAllChallenge(_ref6) {
  var dispatch = _ref6.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== ACTIVAR_ALL_CHALLENGE_SUCCESS && action.type !== ACTIVAR_ALL_CHALLENGE_FAIL) {
        return nextResponse;
      }
      var type = action.type,
        payload = action.payload;
      switch (type) {
        case ACTIVAR_ALL_CHALLENGE_SUCCESS:
          {
            return dispatch(setAllChallengeActivo());
          }
        case ACTIVAR_ALL_CHALLENGE_FAIL:
          {
            return payload;
          }
        default:
          return Promise.all([]);
      }
    };
  };
};