var canUseDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);

/**
 * Touch devices emulate mouse events and hover states.
 * This function makes it possible to know if the current
 * modality supports hover (including for multi-modality
 * devices).
 */
var createHoverMonitor = function createHoverMonitor() {
  var isHoverEnabled = false;
  var lastTouchTime = 0;
  function enableHover() {
    if (isHoverEnabled || Date.now() - lastTouchTime < 500) {
      return;
    }
    isHoverEnabled = true;
  }
  function disableHover() {
    lastTouchTime = new Date();
    if (isHoverEnabled) {
      isHoverEnabled = false;
    }
  }
  if (canUseDOM) {
    document.addEventListener('touchstart', disableHover, true);
    document.addEventListener('mousemove', enableHover, true);
  }
  return {
    get isEnabled() {
      return isHoverEnabled;
    }
  };
};
export default createHoverMonitor;