import eventTypes from '@cencosud/puntos-web/src/analytics/eventTypes';
import registerEvent from '@cencosud/puntos-web/src/analytics/registerEvent';
import { getCmsText } from '@cencosud/ui/src/Parameterize/getText';
export var registerEventLogoClick = function registerEventLogoClick() {
  var event = {
    event: eventTypes.event.user,
    category: eventTypes.categories.site,
    action: 'click_logo header',
    name: 'Logotipo - Inicio'
  };
  registerEvent(event);
};
export var registerLoginEventClick = function registerLoginEventClick() {
  var event = {
    event: eventTypes.event.user,
    category: eventTypes.categories.login,
    action: eventTypes.actions.click_link_header,
    name: 'Iniciar sesión'
  };
  registerEvent(event);
};
export var registerEventClick = function registerEventClick() {
  var event = {
    event: eventTypes.event.user,
    category: eventTypes.categories.menuProductos,
    action: eventTypes.actions.click_link_header,
    name: 'Canje de puntos'
  };
  registerEvent(event);
};
export var registerEventFAQClick = function registerEventFAQClick() {
  var event = {
    event: eventTypes.event.user,
    category: eventTypes.categories.header,
    action: eventTypes.actions.click_link_header,
    name: getCmsText('HV3-001') || 'Conoce Puntos Cencosud'
  };
  registerEvent(event);
};
export var registerSignUpEventClick = function registerSignUpEventClick() {
  var event = {
    event: eventTypes.event.user,
    category: eventTypes.categories.usuarioNuevo,
    action: eventTypes.actions.click_link_header,
    name: 'inscríbete'
  };
  registerEvent(event);
};
export var registerProductMenuEvent = function registerProductMenuEvent(name) {
  var event = {
    event: eventTypes.event.user,
    category: eventTypes.categories.menuProductos,
    action: eventTypes.actions.click_link_header,
    name: name
  };
  registerEvent(event);
};
export var registerHeaderUserAction = function registerHeaderUserAction(name) {
  var event = {
    event: eventTypes.event.user,
    category: eventTypes.categories.header,
    action: eventTypes.actions.click_link_header,
    name: name
  };
  registerEvent(event);
};