import _defineProperty from "/home/jenkins/agent/workspace/UCTION_FRONT_deploy_front_master/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var sinBordes = {
  borderTopWidth: 0,
  borderLeftWidth: 0,
  borderRightWidth: 0,
  borderBottomWidth: 0
};
export default (function () {
  return {
    textBloqueadoInput: {
      fontSize: 14,
      fontWeight: 'bold',
      width: '100%'
    },
    inputBloqueadoRut: _objectSpread(_objectSpread({
      width: 290,
      marginRight: 5
    }, sinBordes), {}, {
      fontWeight: 'bold',
      height: 40,
      position: 'relative'
    }),
    inputBloqueadoEmail: _objectSpread(_objectSpread({
      width: 290,
      marginRight: 5,
      flexWrap: 'wrap',
      flex: 1
    }, sinBordes), {}, {
      fontSize: 14,
      lineHeight: 40
    }),
    inputBloqueadoConfirm: _objectSpread(_objectSpread({
      width: 290,
      marginRight: 5,
      flexWrap: 'wrap',
      flex: 1
    }, sinBordes), {}, {
      fontSize: 14,
      lineHeight: 40
    }),
    inputBloqueadoTelefono: _objectSpread(_objectSpread({
      width: 290,
      marginRight: 5,
      flexWrap: 'wrap',
      position: 'relative',
      paddingLeft: 40
    }, sinBordes), {}, {
      fontSize: 14,
      lineHeight: 40
    }),
    inputBloqueado: _objectSpread(_objectSpread({
      width: 290
    }, sinBordes), {}, {
      fontWeight: 'bold',
      height: 40,
      fontSize: 14
    }),
    title: {
      marginTop: 0,
      marginBottom: 10,
      textAlign: 'center',
      color: '#873695',
      fontWeight: '100',
      fontSize: 40
    },
    placeholder: {
      color: '#4a4a4a',
      marginTop: 0,
      fontSize: 14,
      paddingTop: 0,
      marginBottom: 10,
      fontWeight: '400',
      lineHeight: 'normal'
    },
    textoIndicativo: {
      color: 'rgba(0, 0, 0, 0.6)',
      fontSize: 14,
      maxWidth: 900,
      textAlign: 'center'
    },
    helperText: {
      color: '#817575',
      textAlign: 'right',
      width: 290,
      marginBottom: 0,
      fontSize: 12
    },
    /*
      FIXME refactorizar
      mover a componente `ErrorHelperText` y mantener sincronizado con estilos
      de `InputBase`
       */
    errorHelperText: {
      backgroundColor: 'rgba(247, 141, 42, .2)',
      padding: 5,
      borderRadius: 4,
      marginBottom: 3,
      marginTop: 5,
      color: 'rgb(175, 97, 24)',
      fontWeight: 'normal',
      fontSize: 14,
      marginLeft: 0,
      alignSelf: 'baseline',
      flexWrap: 'wrap'
    },
    formularioRegistro: {
      display: 'flex',
      maxWidth: 900,
      marginTop: 20,
      alignSelf: 'center',
      flexWrap: 'wrap'
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignSelf: 'right',
      width: 290,
      marginTop: 20,
      marginBottom: 30,
      zIndex: 0
    },
    selectWrapper: {
      borderTopColor: 'rgb(102, 102, 102)',
      borderBottomColor: 'rgb(102, 102, 102)',
      borderLeftColor: 'rgb(102, 102, 102)',
      borderRightColor: 'rgb(102, 102, 102)',
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderLeftWidth: 1,
      borderRightWidth: 1,
      borderRadius: 0,
      backgroundColor: '#fff',
      marginBottom: 3,
      marginTop: 5,
      marginRight: 5
    },
    selectWrapperL: {
      borderTopColor: 'rgb(102, 102, 102)',
      borderBottomColor: 'rgb(102, 102, 102)',
      borderLeftColor: 'rgb(102, 102, 102)',
      borderRightColor: 'rgb(102, 102, 102)',
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderLeftWidth: 1,
      borderRightWidth: 1,
      borderRadius: 5,
      backgroundColor: '#fff',
      marginBottom: 3,
      marginTop: 0,
      marginRight: 5,
      height: 40,
      width: 290
    },
    select: {
      backgroundColor: 'transparent',
      color: '#495057',
      borderTopWidth: 1,
      borderLeftWidth: 1,
      borderRightWidth: 1,
      borderBottomWidth: 1,
      borderColor: 'hsl(0, 0%, 82%)',
      borderRadius: 5,
      paddingTop: 10,
      paddingBottom: 10,
      height: 40,
      fontSize: 14,
      zIndex: 1,
      marginTop: 10
    },
    selectWrapperDisabled: {
      borderTopWidth: 0,
      borderBottomWidth: 0,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderRadius: 0,
      backgroundColor: '#fff',
      marginBottom: 3,
      marginTop: 5
    },
    selectDisabled: {
      backgroundColor: 'transparent',
      color: '#454545',
      borderTopWidth: 0,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      fontSize: 14,
      apperance: 'none',
      height: 40,
      fontWeight: 'bold',
      minWidth: 40
    },
    container: {
      display: 'flex',
      paddingLeft: 20,
      paddingRight: 20,
      alignItems: 'center'
    },
    formNotificationContainer: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 0
    },
    divider: {
      backgroundColor: 'transparent',
      height: 0,
      width: '100%'
    },
    svgNumCel: {
      fontWeight: 'bold',
      color: 'dark',
      lineHeight: 40,
      position: 'absolute',
      top: 22,
      left: 0,
      fontSize: 14
    },
    calleContainer: {
      display: 'flex',
      marginRight: 5,
      flexWrap: 'wrap',
      flex: 1
    },
    textInput: {
      fontSize: 14,
      color: '#686868'
    },
    numeroContainer: {
      display: 'flex',
      width: '100%',
      marginRight: 5,
      flexWrap: 'wrap'
    },
    departamentoContainer: {
      width: '100%',
      marginRight: 5,
      flexWrap: 'wrap',
      flex: 1,
      display: 'flex'
    },
    selectArrow: {
      position: 'absolute',
      bottom: 0,
      right: 10,
      width: 20,
      height: 40,
      padding: '5px 0px',
      zIndex: 0
    },
    icon: {
      width: '100%'
    },
    label: {
      color: '#222222',
      marginTop: 0,
      fontSize: 14,
      justifyContent: 'center',
      paddingTop: 0
    },
    picker: {
      flex: 1,
      borderTopWidth: 0,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      borderLeftWidth: 0,
      borderColor: 'transparent',
      backgroundColor: 'white',
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 15,
      color: '#686868',
      fontSize: 14,
      zIndex: 1000,
      borderRadius: 5,
      height: 40,
      position: 'absolute',
      top: '-1px',
      left: '-1px',
      width: 'calc(100% + 2px)'
    }
  };
});