import { apiRequest } from '../../api/api.actions';
import { GET_TYC, GET_TYC_FAIL, GET_TYC_SUCCESS, setTyC, getTyCFail, getTyCSuccess, TYC_APPROVE, TYC_APPROVE_FAIL, TYC_APPROVE_SUCCESS, approveTyCFail, approveTyCSuccess, hideTyC, GET_TYC_HASH, getTyCHashSuccess, getTyCHashFail, GET_TYC_HASH_FAIL, GET_TYC_HASH_SUCCESS, SIGN_TYC_HASH, signTyCHashSuccess, signTyCHashFail, SIGN_TYC_HASH_FAIL, SIGN_TYC_HASH_SUCCESS } from './TyC.actions';
import { showPrimaryProgress, hidePrimaryProgressBar, showSecondaryProgress, hideSecondaryProgressBar, showNotifier } from '../../ui/ui.actions';
import data from './text.json';
import processErrorMessage from '../../api/processErrorMessage';
import { appendDetalle } from '../../usuario/usuario.actions';
import { pushRoute } from '../../router/history.actions';
import { contentV3Endpoints, securityEndpoints, loyaltyEndpoints } from '../../api/endpoints';
import registerEvent from '@cencosud/puntos-web/src/analytics/registerEvent';
import eventTypes from '@cencosud/puntos-web/src/analytics/eventTypes';
export var getTyCFlow = function getTyCFlow(_ref) {
  var dispatch = _ref.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_TYC) {
        return nextResponse;
      }
      return Promise.all([dispatch(showPrimaryProgress()), dispatch(apiRequest(contentV3Endpoints.terminosCondiciones.get(), getTyCSuccess, getTyCFail))]);
    };
  };
};
export var processGetTyC = function processGetTyC(_ref2) {
  var dispatch = _ref2.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      if (type !== GET_TYC_SUCCESS && type !== GET_TYC_FAIL) {
        return nextResponse;
      }
      var progressResponse = dispatch(hidePrimaryProgressBar());
      // eslint-disable-next-line default-case
      switch (type) {
        case GET_TYC_SUCCESS:
          {
            var tyc = payload.payload;
            return Promise.all([progressResponse, dispatch(setTyC(tyc))]);
          }
        case GET_TYC_FAIL:
          {
            var _tyc = data.payload;
            return Promise.all([progressResponse, dispatch(setTyC(_tyc))]);
          }
        default:
          return Promise.resolve();
      }
    };
  };
};
export var approveTyCFlow = function approveTyCFlow(_ref3) {
  var dispatch = _ref3.dispatch,
    getState = _ref3.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== TYC_APPROVE) {
        return nextResponse;
      }
      if (Object.keys(getState().user).length > 0) {
        var _getState$user = getState().user,
          firmaTycIds = _getState$user.firmaTycIds,
          firmaTYC = _getState$user.firmaTYC;
        if (firmaTYC === false && firmaTycIds === 'Y') {
          return Promise.all([dispatch(showSecondaryProgress()), dispatch(apiRequest(securityEndpoints.terminosCondiciones.aceptar(getState().auth.rut), approveTyCSuccess, approveTyCFail, {
            auth: true
          }))]);
        }
        if (firmaTYC === false && firmaTycIds === undefined) {
          return Promise.all([dispatch(showSecondaryProgress()), dispatch(apiRequest(securityEndpoints.terminosCondiciones.aceptar(getState().auth.rut), approveTyCSuccess, approveTyCFail, {
            auth: true
          }))]);
        }
        if (firmaTYC === false && firmaTycIds === null) {
          return Promise.all([dispatch(showSecondaryProgress()), dispatch(apiRequest(securityEndpoints.terminosCondiciones.aceptar(getState().auth.rut), approveTyCSuccess, approveTyCFail, {
            auth: true
          }))]);
        }
        if (firmaTYC === true && firmaTycIds === 'N') {
          return Promise.all([dispatch(showSecondaryProgress()), dispatch(apiRequest(securityEndpoints.terminosCondiciones.aceptarTyc(getState().auth.rut), approveTyCSuccess, approveTyCFail, {
            auth: true
          }))]);
        }
        return Promise.all([dispatch(showSecondaryProgress()), dispatch(apiRequest(securityEndpoints.terminosCondiciones.aceptarTyc(getState().auth.rut), null, approveTyCFail, {
          auth: true
        })), dispatch(apiRequest(securityEndpoints.terminosCondiciones.aceptar(getState().auth.rut), approveTyCSuccess, approveTyCFail, {
          auth: true
        }))]);
      }
      return Promise.resolve();
    };
  };
};
export var processApproveTyC = function processApproveTyC(_ref4) {
  var dispatch = _ref4.dispatch,
    getState = _ref4.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type;
      if (type !== TYC_APPROVE_SUCCESS && type !== TYC_APPROVE_FAIL) {
        return nextResponse;
      }
      var progressResponse = dispatch(hideSecondaryProgressBar());
      // eslint-disable-next-line default-case
      switch (type) {
        case TYC_APPROVE_SUCCESS:
          {
            registerEvent({
              event: eventTypes.event.submit_form_success,
              category: eventTypes.categories.popup,
              action: eventTypes.actions.submit_form_success,
              name: 'Aceptar TyC OK'
            });
            return Promise.all([dispatch(appendDetalle({
              subEstado: null,
              firmaTYC: true,
              firmaTycIds: 'Y'
            })), dispatch(hideTyC()), getState().user.tieneContrasenaTemporal && pushRoute('/cambiar')]);
          }
        case TYC_APPROVE_FAIL:
          {
            return Promise.all([progressResponse, dispatch(showNotifier(processErrorMessage(action.payload)))]);
          }
        default:
          return Promise.resolve();
      }
    };
  };
};
export var getTyCHashFlow = function getTyCHashFlow(_ref5) {
  var dispatch = _ref5.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_TYC_HASH) {
        return nextResponse;
      }
      var hash = action.payload;
      return dispatch(apiRequest(loyaltyEndpoints.tyc.getByHash(hash), getTyCHashSuccess, getTyCHashFail));
    };
  };
};
export var processGetTyCHash = function processGetTyCHash() {
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_TYC_HASH_FAIL && action.type !== GET_TYC_HASH_SUCCESS) {
        return nextResponse;
      }
      switch (action.type) {
        case GET_TYC_HASH_SUCCESS:
          {
            var _action$payload, _action$payload$paylo, _action$payload2, _action$payload2$payl;
            return {
              valido: (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : (_action$payload$paylo = _action$payload.payload) === null || _action$payload$paylo === void 0 ? void 0 : _action$payload$paylo.socioValido,
              nombre: (_action$payload2 = action.payload) === null || _action$payload2 === void 0 ? void 0 : (_action$payload2$payl = _action$payload2.payload) === null || _action$payload2$payl === void 0 ? void 0 : _action$payload2$payl.nombreSocio
            };
          }
        case GET_TYC_HASH_FAIL:
          {
            return {
              valido: false,
              nombre: null
            };
          }
        default:
          return {
            valido: false,
            nombre: null
          };
      }
    };
  };
};
export var signTyCHashFlow = function signTyCHashFlow(_ref6) {
  var dispatch = _ref6.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== SIGN_TYC_HASH) {
        return nextResponse;
      }
      var hash = action.payload;
      return dispatch(apiRequest(loyaltyEndpoints.tyc.signByHash(hash), signTyCHashSuccess, signTyCHashFail));
    };
  };
};
export var processSignTyCHash = function processSignTyCHash(_ref7) {
  var dispatch = _ref7.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== SIGN_TYC_HASH_FAIL && action.type !== SIGN_TYC_HASH_SUCCESS) {
        return nextResponse;
      }
      switch (action.type) {
        case SIGN_TYC_HASH_SUCCESS:
          {
            var _action$payload3, _action$payload3$payl;
            return (_action$payload3 = action.payload) === null || _action$payload3 === void 0 ? void 0 : (_action$payload3$payl = _action$payload3.payload) === null || _action$payload3$payl === void 0 ? void 0 : _action$payload3$payl.aceptaTyc;
          }
        case SIGN_TYC_HASH_FAIL:
          {
            dispatch(showNotifier(processErrorMessage(action.payload)));
            return false;
          }
        default:
          return null;
      }
    };
  };
};
export default [getTyCFlow, processGetTyC, approveTyCFlow, processApproveTyC, getTyCHashFlow, processGetTyCHash, signTyCHashFlow, processSignTyCHash];