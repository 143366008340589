var namespace = '[catalogo]';
export var GET_CATALOGO = "".concat(namespace, " get catalogo");
export var GET_CATALOGO_SEARCH = "".concat(namespace, " get catalogo search");
export var GET_CATALOGO_SUCCESS = "".concat(GET_CATALOGO, ": success");
export var GET_CATALOGO_FAIL = "".concat(GET_CATALOGO, ": fail");
export var SET_CATALOGO = "".concat(namespace, " set catalogo");
export var GET_APPEND_CATALOGO = "".concat(namespace, " append catalogo");
export var APPEND_CATALOGO_SUCCESS = "".concat(GET_APPEND_CATALOGO, ": success");
export var APPEND_CATALOGO_FAIL = "".concat(GET_APPEND_CATALOGO, ": fail");
export var APPEND_CATALOGO = "".concat(namespace, " set append catalogo");
export var GET_BANNERS = "".concat(namespace, " get banners");
export var GET_BANNERS_SUCCESS = "".concat(GET_BANNERS, ": success");
export var GET_BANNERS_FAIL = "".concat(GET_BANNERS, ": fail");
export var SET_BANNER = "".concat(namespace, " set banner");
export var SET_PRODUCTO_PROMOCIONADO = "".concat(namespace, " set producto promocionado");
export var SET_CAMPAGNA = "".concat(namespace, " set campa\xF1a");

/**
 * @typedef {Object} GetCatalogoAction
 * @property {string} type
 * @property {Object.} payload
 * @property {number} payload.page
 * @property {number} payload.size
 * @property {boolean} payload.isDesc
 */

/**
 * @param {number} page
 * @param {number} size
 * @param {number} from
 * @param {number} to
 * @param {object} [categoria]
 * @param {boolean} [isDesc]
 * @param {boolean} [nuevos]
 * @param {boolean} [ofertas]
 * @return {GetCatalogoAction}
 */
export var getCatalogo = function getCatalogo(_ref) {
  var page = _ref.page,
    size = _ref.size,
    from = _ref.from,
    to = _ref.to,
    categoria = _ref.categoria,
    _ref$isDesc = _ref.isDesc,
    isDesc = _ref$isDesc === void 0 ? false : _ref$isDesc,
    nuevos = _ref.nuevos,
    ofertas = _ref.ofertas;
  return {
    type: GET_CATALOGO,
    payload: {
      page: page,
      size: size,
      from: from,
      to: to,
      categoria: categoria,
      isDesc: isDesc,
      nuevos: nuevos,
      ofertas: ofertas
    }
  };
};
export var getCatalogoSuccess = function getCatalogoSuccess(catalogo) {
  return {
    type: GET_CATALOGO_SUCCESS,
    payload: catalogo
  };
};
export var getCatalogoFail = function getCatalogoFail(cause) {
  return {
    type: GET_CATALOGO_FAIL,
    payload: cause
  };
};
export var setCatalogo = function setCatalogo(productos, totalResultados, exclude) {
  return {
    type: SET_CATALOGO,
    payload: {
      productos: productos,
      totalResultados: totalResultados,
      exclude: exclude
    }
  };
};
export var getAppendCatalogo = function getAppendCatalogo(_ref2) {
  var page = _ref2.page,
    size = _ref2.size,
    from = _ref2.from,
    to = _ref2.to,
    categoria = _ref2.categoria,
    _ref2$isDesc = _ref2.isDesc,
    isDesc = _ref2$isDesc === void 0 ? false : _ref2$isDesc,
    nuevos = _ref2.nuevos,
    ofertas = _ref2.ofertas,
    excluidos = _ref2.excluidos;
  return {
    type: GET_APPEND_CATALOGO,
    payload: {
      page: page,
      size: size,
      from: from,
      to: to,
      categoria: categoria,
      isDesc: isDesc,
      nuevos: nuevos,
      ofertas: ofertas,
      excluidos: excluidos
    }
  };
};
export var appendCatalogoSuccess = function appendCatalogoSuccess(productos) {
  return {
    type: APPEND_CATALOGO_SUCCESS,
    payload: productos
  };
};
export var appendCatalogoFail = function appendCatalogoFail(productos) {
  return {
    type: APPEND_CATALOGO_FAIL,
    payload: productos
  };
};
export var appendCatalogo = function appendCatalogo(productos, exclude, totalResultados) {
  return {
    type: APPEND_CATALOGO,
    payload: {
      productos: productos,
      exclude: exclude,
      totalResultados: totalResultados
    }
  };
};
export var getBanners = function getBanners() {
  return {
    type: GET_BANNERS
  };
};
export var getBannersSuccess = function getBannersSuccess(banners) {
  return {
    type: GET_BANNERS_SUCCESS,
    payload: banners
  };
};
export var getBannersFail = function getBannersFail(cause) {
  return {
    type: GET_BANNERS_FAIL,
    payload: cause
  };
};
export var setBanner = function setBanner(banner) {
  return {
    type: SET_BANNER,
    payload: banner
  };
};
export var setProductoPromocionado = function setProductoPromocionado(producto) {
  return {
    type: SET_PRODUCTO_PROMOCIONADO,
    payload: producto
  };
};
export var setCampagna = function setCampagna(campagna) {
  return {
    type: SET_CAMPAGNA,
    payload: campagna
  };
};