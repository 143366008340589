import PropTypes from 'prop-types';
export default {
  id: PropTypes.string,
  nombre: PropTypes.string,
  puntos: PropTypes.number,
  cantidad: PropTypes.number,
  tipo: PropTypes.shape({
    id: PropTypes.number,
    nombre: PropTypes.string
  })
};