import isArray from 'lodash/isArray';
var formatText = function formatText(str) {
  if (str === 'Cencosud S.A') {
    return str;
  }
  return "".concat(str.toLowerCase()).replace(/^([a-z])|\s+([a-z])/g, function ($1) {
    return $1.toUpperCase();
  });
};
export default (function (dashboard) {
  return {
    estimado: dashboard.totalQueHubierasAcumulado,
    lista: isArray(dashboard.listaTransacciones) ? dashboard.listaTransacciones.map(function (transaccion) {
      return {
        fecha: transaccion.fechaTransaccion,
        nombre: formatText(transaccion.nombreSocio),
        lugar: formatText(transaccion.lugarTransaccion),
        lugarTarjeta: formatText(transaccion.descripcionOffer),
        monto: transaccion.montoTransaccion,
        puntos: transaccion.puntos,
        medioDePago: transaccion.metodoPago,
        promocion: formatText(transaccion.tipoPromocion),
        descripcion: transaccion.descripcion,
        tipoDeCanje: transaccion.tipoCanje,
        producto: transaccion.producto,
        active: {
          tienda: transaccion.listaTiendaParticipante,
          tarjeta: transaccion.listaTarjetaCencosud,
          promocion: transaccion.listaPromociones,
          alianza: transaccion.listaAlianza,
          canje: transaccion.listaCanje,
          esAcumulacion: transaccion.esAcumulacion,
          // FIXME: ofertas EECC
          ofertas: transaccion.listaOfertasPersonalizadas
        }
      };
    }).sort(function (a, b) {
      return new Date(b.fecha).getTime() - new Date(a.fecha).getTime();
    }) : []
  };
});