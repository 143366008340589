import { apiRequest } from '@cencosud/puntos-core/src/features/api/api.actions';
import { GET_SEARCHER, GET_SEARCHER_FAIL, GET_SEARCHER_SUCCESS, setSearcher, getSearcherFail, getSearcherSuccess, GET_APPEND_CATALOGO, APPEND_CATALOGO_FAIL, APPEND_CATALOGO_SUCCESS, appendCatalogoSuccess, appendCatalogoFail, appendCatalogo, GET_APPEND_SEARCHER, getSearcherAppendSuccess, getSearcherAppendFail, APPEND_SEARCHER_FAIL, APPEND_SEARCHER_SUCCESS } from './busqueda.actions';
import { pushRoute } from '@cencosud/puntos-core/src/features/router/history.actions';
import { showPrimaryProgress, hidePrimaryProgressBar, showSecondaryProgress, hideSecondaryProgressBar } from '@cencosud/puntos-core/src/features/ui/ui.actions';
import AuthenticationError from '@cencosud/puntos-core/src/features/auth/AuthenticationError';
import { contentV3Endpoints } from '@cencosud/puntos-core/src/features/api/endpoints';
import registerEvent from '@cencosud/puntos-web/src/analytics/registerEvent';
import { createNavButtonPressEvent } from '@cencosud/puntos-web/src/analytics/eventCreators';
import eventTypes from '@cencosud/puntos-web/src/analytics/eventTypes';
import catalogOnlineBinder from "@cencosud/puntos-core/src/features/binder/CatalogOnlineBinder";
export var getSearcherFlow = function getSearcherFlow(_ref) {
  var dispatch = _ref.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_SEARCHER) {
        return nextResponse;
      }
      var _action$payload = action.payload,
        page = _action$payload.page,
        size = _action$payload.size,
        producto = _action$payload.producto;
      return Promise.all([dispatch(apiRequest(contentV3Endpoints.productos.buscarQuery({
        producto: producto,
        page: page,
        size: size
      }), getSearcherSuccess, getSearcherFail)).finally(function () {
        return registerEvent(createNavButtonPressEvent({
          category: eventTypes.categories.buscador,
          name: producto
        }));
      }), dispatch(showPrimaryProgress()), dispatch(setSearcher([], 0))]);
    };
  };
};
export var processGetSearcher = function processGetSearcher(_ref2) {
  var dispatch = _ref2.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      if (type !== GET_SEARCHER_SUCCESS && type !== GET_SEARCHER_FAIL) {
        return nextResponse;
      }
      var busqueda = payload.payload;
      var progressResponse = dispatch(hidePrimaryProgressBar());

      // eslint-disable-next-line default-case
      switch (type) {
        case GET_SEARCHER_SUCCESS:
          {
            return Promise.all([progressResponse, dispatch(setSearcher(catalogOnlineBinder(busqueda.items), busqueda.total))]);
          }
        case GET_SEARCHER_FAIL:
          {
            var event = {
              event: eventTypes.event.error,
              category: eventTypes.categories.paginaError,
              name: 'Error al obtener resultados de busqueda (Content -> /secciones/v3/buscar)'
            };
            registerEvent(event);
            return Promise.all([progressResponse, payload instanceof AuthenticationError ? undefined : dispatch(pushRoute('/error'))]);
          }
        default:
          return Promise.resolve();
      }
    };
  };
};
export var getSearcherAppendFlow = function getSearcherAppendFlow(_ref3) {
  var dispatch = _ref3.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      if (type !== GET_APPEND_SEARCHER) {
        return nextResponse;
      }
      var page = payload.page,
        size = payload.size,
        producto = payload.producto;
      return Promise.all([dispatch(apiRequest(contentV3Endpoints.productos.buscarQuery({
        producto: producto,
        size: size,
        page: page
      }), getSearcherAppendSuccess, getSearcherAppendFail)), dispatch(showPrimaryProgress())]);
    };
  };
};
export var processSearcherAppend = function processSearcherAppend(_ref4) {
  var dispatch = _ref4.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== APPEND_SEARCHER_FAIL || action.type !== APPEND_SEARCHER_SUCCESS) {
        return nextResponse;
      }
      switch (action.type) {
        case APPEND_SEARCHER_SUCCESS:
          {
            var busqueda = action.payload;
            return Promise.all([dispatch(hidePrimaryProgressBar()), dispatch(appendCatalogo(catalogOnlineBinder(busqueda.items)))]);
          }
        case APPEND_SEARCHER_FAIL:
          {
            var payload = action.payload;
            var progressResponse = dispatch(hidePrimaryProgressBar());
            var event = {
              event: eventTypes.event.error,
              category: eventTypes.categories.paginaError,
              name: 'Error al obtener resultados de busqueda (Content -> /secciones/v3/buscar)'
            };
            registerEvent(event);
            return Promise.all([progressResponse, payload instanceof AuthenticationError ? undefined : dispatch(pushRoute('/error'))]);
          }
        default:
          return Promise.resolve();
      }
    };
  };
};
export var getAppendCatalogoFlow = function getAppendCatalogoFlow(_ref5) {
  var dispatch = _ref5.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_APPEND_CATALOGO) {
        return nextResponse;
      }
      var _action$payload2 = action.payload,
        page = _action$payload2.page,
        size = _action$payload2.size,
        producto = _action$payload2.producto;
      return Promise.all([dispatch(apiRequest(contentV3Endpoints.productos.buscarQuery({
        page: page,
        size: size,
        producto: producto
      }), appendCatalogoSuccess, appendCatalogoFail)), dispatch(showSecondaryProgress())]);
    };
  };
};
export var processAppendCatalogo = function processAppendCatalogo(_ref6) {
  var dispatch = _ref6.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      if (type !== APPEND_CATALOGO_SUCCESS && type !== APPEND_CATALOGO_FAIL) {
        return nextResponse;
      }
      var progressResponse = dispatch(hideSecondaryProgressBar());
      // eslint-disable-next-line default-case
      switch (type) {
        case APPEND_CATALOGO_SUCCESS:
          {
            var busqueda = payload.payload;
            return Promise.all([progressResponse, dispatch(appendCatalogo(catalogOnlineBinder(busqueda.items)))]);
          }
        case APPEND_CATALOGO_FAIL:
          {
            var event = {
              event: eventTypes.event.error,
              category: eventTypes.categories.paginaError,
              name: 'Error al obtener resultados de busqueda (Content -> /secciones/v3/buscar)'
            };
            registerEvent(event);
            return Promise.all([progressResponse, payload instanceof AuthenticationError ? undefined : dispatch(pushRoute('/error'))]);
          }
        default:
          return Promise.resolve();
      }
    };
  };
};
export default [getSearcherFlow, processGetSearcher, getAppendCatalogoFlow, processAppendCatalogo, getSearcherAppendFlow, processSearcherAppend];