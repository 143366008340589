import { apiRequest } from '../api/api.actions';
import { ecommerceEndpoints } from '../api/endpoints';
import { DELETE_COMMERCE, DELETE_COMMERCE_SUCCESS, deleteCommerceFail, deleteCommerceSuccess, GET_COMMERCES, GET_COMMERCES_SUCCESS, getCommerces, getCommercesFail, getCommercesSuccess, setCommerces, getCommercesLoading } from './ecommerce.actions';
import { eliminarCaracteresEspeciales } from '../common/rut';
export var getCommercesFlow = function getCommercesFlow(_ref) {
  var dispatch = _ref.dispatch,
    getState = _ref.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_COMMERCES) {
        return nextResponse;
      }
      return Promise.all([dispatch(getCommercesLoading), dispatch(apiRequest(ecommerceEndpoints.commerces.getAll(eliminarCaracteresEspeciales(getState().auth.rut)), getCommercesSuccess, getCommercesFail, {
        headers: {
          Authorization: "Bearer ".concat(getState().auth.session),
          'x-token-app': undefined
        }
      }))]);
    };
  };
};
export var deleteCommercesFlow = function deleteCommercesFlow(_ref2) {
  var dispatch = _ref2.dispatch,
    getState = _ref2.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== DELETE_COMMERCE) {
        return nextResponse;
      }
      return dispatch(apiRequest(ecommerceEndpoints.commerces.delete(), deleteCommerceSuccess, deleteCommerceFail, {
        body: {
          idPartner: action.payload,
          idSocio: eliminarCaracteresEspeciales(getState().auth.rut)
        },
        headers: {
          Authorization: "Bearer ".concat(getState().auth.session),
          'x-token-app': undefined
        }
      }));
    };
  };
};
export var processGetCommerces = function processGetCommerces(_ref3) {
  var dispatch = _ref3.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      if (type !== GET_COMMERCES_SUCCESS) {
        return nextResponse;
      }
      return dispatch(setCommerces(payload.payload));
    };
  };
};
export var processDeleteCommerce = function processDeleteCommerce(_ref4) {
  var dispatch = _ref4.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type;
      if (type !== DELETE_COMMERCE_SUCCESS) {
        return nextResponse;
      }
      return dispatch(getCommerces());
    };
  };
};
export default [getCommercesFlow, processGetCommerces, deleteCommercesFlow, processDeleteCommerce];