import { namespace } from '../usuario/usuario.actions';
export var GET_ACCOUNT_STATUS = "".concat(namespace, " get account status");
export var GET_ACCOUNT_STATUS_SUCCESS = "".concat(GET_ACCOUNT_STATUS, ": success");
export var GET_ACCOUNT_STATUS_FAIL = "".concat(GET_ACCOUNT_STATUS, ": fail");
export var GET_TRANSACTIONS_AND_TOTALS = "".concat(namespace, " get transactions and totals");
export var GET_TRANSACTIONS_AND_TOTALS_SUCCESS = "".concat(GET_TRANSACTIONS_AND_TOTALS, ": success");
export var GET_TRANSACTIONS_AND_TOTALS_FAIL = "".concat(GET_TRANSACTIONS_AND_TOTALS, ": fail");
export var GET_TRANSACTIONS = "".concat(namespace, " get transactions");
export var GET_TRANSACTIONS_SUCCESS = "".concat(GET_TRANSACTIONS, ": success");
export var GET_TRANSACTIONS_FAIL = "".concat(GET_TRANSACTIONS, ": fail");
export var GET_DASHBOARDS = "".concat(namespace, " get dashboards");
export var GET_DASHBOARDS_SUCCESS = "".concat(GET_DASHBOARDS, ": success");
export var GET_DASHBOARDS_FAIL = "".concat(GET_DASHBOARDS, ": fail");
export var SET_ACCOUNT_STATUS = "".concat(namespace, " set status account");
export var SET_TRANSACTIONS_AND_TOTALS = "".concat(namespace, " set transactions and totals");
export var SET_TRANSACTIONS = "".concat(namespace, " set transactions");
export var SET_DASHBOARDS = "".concat(namespace, " set transactions");
export var getAccountStatus = function getAccountStatus(startDate, endDate, boardName, pagNum, pagSize) {
  return {
    type: GET_ACCOUNT_STATUS
  };
};
export var getAccountStatusSuccess = function getAccountStatusSuccess(status) {
  return {
    type: GET_ACCOUNT_STATUS_SUCCESS,
    payload: status
  };
};
export var getAccountStatusFail = function getAccountStatusFail(cause) {
  return {
    type: GET_ACCOUNT_STATUS_FAIL,
    payload: cause
  };
};
export var getTransactions = function getTransactions(startDate, endDate, boardName, pagNum, pagSize) {
  return {
    type: GET_TRANSACTIONS,
    payload: {
      startDate: startDate,
      endDate: endDate,
      boardName: boardName,
      pagNum: pagNum,
      pagSize: pagSize
    }
  };
};
export var getTransactionsSuccess = function getTransactionsSuccess(status) {
  return {
    type: GET_TRANSACTIONS_SUCCESS,
    payload: status
  };
};
export var getTransactionsFail = function getTransactionsFail(cause) {
  return {
    type: GET_TRANSACTIONS_FAIL,
    payload: cause
  };
};
export var getTransactionsAndTotals = function getTransactionsAndTotals(startDate, endDate, boardName, pagNum, pagSize) {
  return {
    type: GET_TRANSACTIONS_AND_TOTALS,
    payload: {
      startDate: startDate,
      endDate: endDate,
      boardName: boardName,
      pagNum: pagNum,
      pagSize: pagSize
    }
  };
};
export var getTransactionsAndTotalsSuccess = function getTransactionsAndTotalsSuccess(status) {
  return {
    type: GET_TRANSACTIONS_AND_TOTALS_SUCCESS,
    payload: status
  };
};
export var getTransactionsAndTotalsFail = function getTransactionsAndTotalsFail(cause) {
  return {
    type: GET_TRANSACTIONS_AND_TOTALS_FAIL,
    payload: cause
  };
};
export var getDashboards = function getDashboards() {
  return {
    type: GET_DASHBOARDS
  };
};
export var getDashboardsSuccess = function getDashboardsSuccess(status) {
  return {
    type: GET_DASHBOARDS_SUCCESS,
    payload: status
  };
};
export var getDashboardsFail = function getDashboardsFail(cause) {
  return {
    type: GET_DASHBOARDS_FAIL,
    payload: cause
  };
};
export var setAccountStatus = function setAccountStatus(account) {
  return {
    type: SET_ACCOUNT_STATUS,
    payload: account
  };
};
export var setTransactions = function setTransactions(transactions) {
  return {
    type: SET_TRANSACTIONS,
    payload: transactions
  };
};
export var setTransactionsAndTotals = function setTransactionsAndTotals(transactionsAndTotals) {
  return {
    type: SET_TRANSACTIONS_AND_TOTALS,
    payload: transactionsAndTotals
  };
};
export var setDashboards = function setDashboards(dashboards) {
  return {
    type: SET_DASHBOARDS,
    payload: dashboards
  };
};