export var namespace = '[ui]';
export var GET_PARAMETERIZE = "".concat(namespace, " get parameterizer");
export var GET_PARAMETERIZE_SUCCESS = "".concat(GET_PARAMETERIZE, ": success");
export var GET_PARAMETERIZE_FAIL = "".concat(GET_PARAMETERIZE, ": fail");
export var SET_PARAMETERIZE = "".concat(namespace, " set parameterizer");
export var getParameterizer = function getParameterizer() {
  return {
    type: GET_PARAMETERIZE
  };
};
export var getParameterizerSuccess = function getParameterizerSuccess(success) {
  return {
    type: GET_PARAMETERIZE_SUCCESS,
    payload: success
  };
};
export var getParameterizerFail = function getParameterizerFail(cause) {
  return {
    type: GET_PARAMETERIZE_FAIL,
    payload: cause
  };
};
export var setParameterize = function setParameterize(parameterizer) {
  return {
    type: SET_PARAMETERIZE,
    payload: parameterizer.parametrizedMsg
  };
};