import * as Yup from 'yup';
import { region, comuna, calle, numero, departamento } from '@cencosud/puntos-core/src/features/common/form/validaciones';
export default (function () {
  return Yup.object().shape({
    region: region,
    comuna: comuna,
    calle: calle,
    numero: numero,
    departamento: departamento
  });
});