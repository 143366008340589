import _defineProperty from "/home/jenkins/agent/workspace/UCTION_FRONT_deploy_front_master/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var namespace = '[promociones]';
export var GET_PROMOCIONES = "".concat(namespace, " get promociones");
export var GET_PROMOCIONES_BY_HASH = "".concat(namespace, " get promociones by hash");
export var GET_PROMOCIONES_SUCCESS = "".concat(GET_PROMOCIONES, ": success");
export var GET_PROMOCIONES_SUCCESS_BY_HASH = "".concat(GET_PROMOCIONES_BY_HASH, ": success");
export var GET_PROMOCIONES_FAIL = "".concat(GET_PROMOCIONES, ": fail");
export var GET_PROMOCIONES_FAIL_BY_HASH = "".concat(GET_PROMOCIONES_BY_HASH, ": fail");
export var SET_PROMOCIONES = "".concat(namespace, " set promociones");
export var GET_CUPONES = "".concat(namespace, " get cupones");
export var GET_CUPONES_BY_HASH = "".concat(namespace, " get cupones by hash");
export var GET_CUPONES_SUCCESS = "".concat(GET_CUPONES, ": success");
export var GET_CUPONES_SUCCESS_BY_HASH = "".concat(GET_CUPONES_BY_HASH, ": success");
export var GET_CUPONES_FAIL = "".concat(GET_CUPONES, ": fail");
export var GET_CUPONES_FAIL_BY_HASH = "".concat(GET_CUPONES_BY_HASH, ": fail");
export var SET_CUPONES = "".concat(namespace, " set cupones");
export var GET_DESAFIOS = "".concat(namespace, " get desafios");
export var GET_DESAFIOS_BY_HASH = "".concat(namespace, " get desafios by hash");
export var GET_DESAFIOS_SUCCESS = "".concat(GET_DESAFIOS, ": success");
export var GET_DESAFIOS_FAIL = "".concat(GET_DESAFIOS, ": fail");
export var SET_DESAFIOS = "".concat(namespace, " set desafios");
export var GET_GANADOS = "".concat(namespace, " get ganados");
export var GET_GANADOS_BY_HASH = "".concat(namespace, " get ganados by hash");
export var GET_GANADOS_SUCCESS = "".concat(GET_GANADOS, ": success");
export var GET_GANADOS_FAIL = "".concat(GET_GANADOS, ": fail");
export var SET_GANADOS = "".concat(namespace, " set ganados");
export var SET_GANADOS_CARGADOS = "".concat(namespace, " set ganados cargados");
export var SET_GANADOS_CACHE = "".concat(namespace, " set ganados cache");
export var ACTIVAR_CUPON = "".concat(namespace, " activar cupon");
export var ACTIVAR_CUPON_DESCUENTO = "".concat(namespace, " activar cupon descuento");
export var ACTIVAR_CUPON_SUCCESS = "".concat(ACTIVAR_CUPON, ": success");
export var ACTIVAR_CUPON_DESCUENTO_SUCCESS = "".concat(ACTIVAR_CUPON_DESCUENTO, ": success");
export var ACTIVAR_CUPON_FAIL = "".concat(ACTIVAR_CUPON, ": fail");
export var ACTIVAR_CUPON_DESCUENTO_FAIL = "".concat(ACTIVAR_CUPON_DESCUENTO, ": fail");
export var SET_CUPON_ACTIVO = "".concat(namespace, " set cupon activo");
export var SET_CUPON_DESCUENTO_ACTIVO = "".concat(namespace, " set cupon descuento activo");
export var GET_IMAGE_COUPON_BY_TOKEN = "".concat(namespace, " get imagen cupon token");
export var GET_MULTI_IMAGE_COUPON_BY_TOKEN = "".concat(namespace, " get multi imagen cupon token");
export var GET_IMAGE_COUPON_BY_HASH = "".concat(namespace, " get imagen cupon hash");
export var GET_MULTI_IMAGE_COUPON_BY_HASH = "".concat(namespace, " get multi imagen cupon hash");
export var GET_IMAGE_SUCCESS = "".concat(namespace, " get imagen cupon token success");
export var GET_MULTI_IMAGE_SUCCESS = "".concat(namespace, " get imagen cupon token success");
export var GET_IMAGE_FAIL = "".concat(namespace, " get imagen cupon token fail");
export var GET_MULTI_IMAGE_FAIL = "".concat(namespace, " get imagen cupon token fail");
export var SET_MULTI_IMAGE_GET_TIMER = "".concat(namespace, " set multi imagen get timer");
export var SET_MULTI_IMAGE_GET_TIME_LEFT = "".concat(namespace, " set multi imagen get time left");
export var ACTIVAR_CHALLENGE = "".concat(namespace, " activar challenge");
export var ACTIVAR_CHALLENGE_SUCCESS = "".concat(ACTIVAR_CHALLENGE, ": success");
export var ACTIVAR_CHALLENGE_FAIL = "".concat(ACTIVAR_CHALLENGE, ": fail");
export var SET_CHALLENGE_ACTIVO = "".concat(namespace, " set challenge activo");
export var ACTIVAR_ALL_CUPON = "".concat(namespace, " activar all cupon");
export var ACTIVAR_ALL_CUPON_BY_HASH = "".concat(namespace, " activar all cupon by hash");
export var ACTIVAR_ALL_CUPON_DESCUENTO = "".concat(namespace, " activar all cupon descuento");
export var ACTIVAR_ALL_CUPON_DESCUENTO_BY_HASH = "".concat(namespace, " activar all cupon descuento by hash");
export var ACTIVAR_ALL_CUPON_SUCCESS = "".concat(ACTIVAR_ALL_CUPON, ": success");
export var ACTIVAR_ALL_CUPON_BY_HASH_SUCCESS = "".concat(ACTIVAR_ALL_CUPON_BY_HASH, ": success");
export var ACTIVAR_ALL_CUPON_DESCUENTO_SUCCESS = "".concat(ACTIVAR_ALL_CUPON_DESCUENTO, ": success");
export var ACTIVAR_ALL_CUPON_DESCUENTO_BY_HASH_SUCCESS = "".concat(ACTIVAR_ALL_CUPON_DESCUENTO_BY_HASH, ": success");
export var ACTIVAR_ALL_CUPON_FAIL = "".concat(ACTIVAR_ALL_CUPON, ": fail");
export var ACTIVAR_ALL_CUPON_BY_HASH_FAIL = "".concat(ACTIVAR_ALL_CUPON_BY_HASH, ": fail");
export var ACTIVAR_ALL_CUPON_DESCUENTO_FAIL = "".concat(ACTIVAR_ALL_CUPON_DESCUENTO, ": fail");
export var ACTIVAR_ALL_CUPON_DESCUENTO_BY_HASH_FAIL = "".concat(ACTIVAR_ALL_CUPON_DESCUENTO_BY_HASH, ": fail");
export var SET_ALL_CUPON_ACTIVO = "".concat(namespace, " set all cupon activo");
export var SET_ALL_CUPON_DESCUENTO_ACTIVO = "".concat(namespace, " set all cupon descuento activo");
export var ACTIVAR_ALL_CHALLENGE = "".concat(namespace, " activar all challenge");
export var ACTIVAR_ALL_CHALLENGE_BY_HASH = "".concat(namespace, " activar all challenge by hash");
export var ACTIVAR_ALL_CHALLENGE_SUCCESS = "".concat(ACTIVAR_ALL_CHALLENGE, ": success");
export var ACTIVAR_ALL_CHALLENGE_BY_HASH_SUCCESS = "".concat(ACTIVAR_ALL_CHALLENGE_BY_HASH, ": success");
export var ACTIVAR_ALL_CHALLENGE_FAIL = "".concat(ACTIVAR_ALL_CHALLENGE, ": fail");
export var ACTIVAR_ALL_CHALLENGE_BY_HASH_FAIL = "".concat(ACTIVAR_ALL_CHALLENGE_BY_HASH, ": fail");
export var SET_ALL_CHALLENGE_ACTIVO = "".concat(namespace, " set all challenge");
export var DESCARTAR_CUPON = "".concat(namespace, " descartar cupon");
export var DESCARTAR_CUPON_DESCUENTO = "".concat(namespace, " descartar cupon descuento");
export var DESCARTAR_CUPON_SUCCESS = "".concat(DESCARTAR_CUPON, ": success");
export var DESCARTAR_CUPON_DESCUENTO_SUCCESS = "".concat(DESCARTAR_CUPON_DESCUENTO, ": success");
export var DESCARTAR_CUPON_FAIL = "".concat(DESCARTAR_CUPON, ": fail");
export var DESCARTAR_CUPON_DESCUENTO_FAIL = "".concat(DESCARTAR_CUPON_DESCUENTO, ": fail");
export var SET_CUPON_DESCARTADO = "".concat(namespace, " set cupon descartado");
export var SET_CUPON_DESCUENTO_DESCARTADO = "".concat(namespace, " set cupon descuento descartado");
export var PRESS_DESCARGAR_CUPONES = "".concat(namespace, " press descargar cupones");
export var SET_TIME_DESCARGAR_CUPONES = "".concat(namespace, " set time descargar cupones");
export var GET_OFERTAS_HOME = "".concat(namespace, " get ofertas home");
export var GET_OFERTAS_HOME_SUCCESS = "".concat(GET_OFERTAS_HOME, ": success");
export var GET_OFERTAS_HOME_FAIL = "".concat(GET_OFERTAS_HOME, ": fail");
export var SET_HOME = "".concat(namespace, " set home");
export var getPromocionesByHash = function getPromocionesByHash(hash) {
  return {
    type: GET_PROMOCIONES_BY_HASH,
    payload: {
      hash: hash
    }
  };
};
export var getPromocionesSuccessByHash = function getPromocionesSuccessByHash(response) {
  return {
    type: GET_PROMOCIONES_SUCCESS,
    payload: response
  };
};
export var getPromocionesFailByHash = function getPromocionesFailByHash(cause) {
  return {
    type: GET_PROMOCIONES_FAIL_BY_HASH,
    payload: cause
  };
};
export var getPromociones = function getPromociones() {
  var mode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'token';
  return {
    type: GET_PROMOCIONES,
    payload: {
      mode: mode
    }
  };
};
export var getPromocionesSuccess = function getPromocionesSuccess(response) {
  return {
    type: GET_PROMOCIONES_SUCCESS,
    payload: response
  };
};
export var getPromocionesFail = function getPromocionesFail(cause) {
  return {
    type: GET_PROMOCIONES_FAIL,
    payload: cause
  };
};
export var setPromociones = function setPromociones(promociones) {
  return {
    type: SET_PROMOCIONES,
    payload: promociones
  };
};
export var getCupones = function getCupones() {
  var mode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'token';
  return {
    type: GET_CUPONES,
    payload: {
      mode: mode
    }
  };
};
export var getCuponesSuccessByHash = function getCuponesSuccessByHash(response) {
  return {
    type: GET_CUPONES_SUCCESS,
    payload: response
  };
};
export var getCuponesFailByHash = function getCuponesFailByHash(cause) {
  return {
    type: GET_CUPONES_FAIL_BY_HASH,
    payload: cause
  };
};
export var getCuponesByHash = function getCuponesByHash(hash) {
  return {
    type: GET_CUPONES_BY_HASH,
    payload: {
      hash: hash
    }
  };
};
export var getCuponesSuccess = function getCuponesSuccess(response) {
  return {
    type: GET_CUPONES_SUCCESS,
    payload: response
  };
};
export var getCuponesFail = function getCuponesFail(cause) {
  return {
    type: GET_CUPONES_FAIL,
    payload: cause
  };
};
export var setCupones = function setCupones(cupones) {
  return {
    type: SET_CUPONES,
    payload: cupones
  };
};
export var getDesafios = function getDesafios() {
  var mode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'token';
  return {
    type: GET_DESAFIOS,
    payload: {
      mode: mode
    }
  };
};
export var getDesafiosByHash = function getDesafiosByHash(hash) {
  return {
    type: GET_DESAFIOS_BY_HASH,
    payload: {
      hash: hash
    }
  };
};
export var getDesafiosSuccess = function getDesafiosSuccess(response) {
  return {
    type: GET_DESAFIOS_SUCCESS,
    payload: response
  };
};
export var getDesafiosFail = function getDesafiosFail(cause) {
  return {
    type: GET_DESAFIOS_FAIL,
    payload: cause
  };
};
export var getImageSuccess = function getImageSuccess(response) {
  return {
    type: GET_IMAGE_SUCCESS,
    payload: response
  };
};
export var getMultiImageSuccess = function getMultiImageSuccess(response) {
  return {
    type: GET_MULTI_IMAGE_SUCCESS,
    payload: response
  };
};
export var getImageFail = function getImageFail(cause) {
  return {
    type: GET_IMAGE_FAIL,
    payload: cause
  };
};
export var getMultiImageFail = function getMultiImageFail(cause) {
  return {
    type: GET_MULTI_IMAGE_FAIL,
    payload: cause
  };
};
export var setDesafios = function setDesafios(desafios) {
  return {
    type: SET_DESAFIOS,
    payload: desafios
  };
};
export var getGanados = function getGanados() {
  var mode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'token';
  var date = arguments.length > 1 ? arguments[1] : undefined;
  return {
    type: GET_GANADOS,
    payload: {
      mode: mode,
      date: date
    }
  };
};
export var getGanadosByHash = function getGanadosByHash(hash, date) {
  return {
    type: GET_GANADOS_BY_HASH,
    payload: {
      hash: hash,
      date: date
    }
  };
};
export var getImageByToken = function getImageByToken() {
  var mode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'token';
  var id = arguments.length > 1 ? arguments[1] : undefined;
  return {
    type: GET_IMAGE_COUPON_BY_TOKEN,
    payload: {
      mode: mode,
      id: id
    }
  };
};
export var getMultiImageByToken = function getMultiImageByToken() {
  var mode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'token';
  return {
    type: GET_MULTI_IMAGE_COUPON_BY_TOKEN,
    payload: {
      mode: mode
    }
  };
};
export var getImageByHash = function getImageByHash(hash, id) {
  return {
    type: GET_IMAGE_COUPON_BY_HASH,
    payload: {
      hash: hash,
      id: id
    }
  };
};
export var getMultiImageByHash = function getMultiImageByHash(hash) {
  return {
    type: GET_MULTI_IMAGE_COUPON_BY_HASH,
    payload: {
      hash: hash
    }
  };
};
export var getGanadosSuccess = function getGanadosSuccess(response) {
  return {
    type: GET_GANADOS_SUCCESS,
    payload: response
  };
};
export var getGanadosFail = function getGanadosFail(cause) {
  return {
    type: GET_GANADOS_FAIL,
    payload: cause
  };
};
export var setGanados = function setGanados(ganados) {
  return {
    type: SET_GANADOS,
    payload: ganados
  };
};
export var setGanadosCargados = function setGanadosCargados(ganadosCargados) {
  return {
    type: SET_GANADOS_CARGADOS,
    payload: ganadosCargados
  };
};
export var setGanadosCache = function setGanadosCache(ganadosCache) {
  return {
    type: SET_GANADOS_CACHE,
    payload: ganadosCache
  };
};
export var activarCupon = function activarCupon() {
  var mode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'token';
  var hash = arguments.length > 1 ? arguments[1] : undefined;
  var cupon = arguments.length > 2 ? arguments[2] : undefined;
  var tipo = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'promocion';
  return {
    type: ACTIVAR_CUPON,
    payload: {
      mode: mode,
      hash: hash,
      cupon: cupon,
      tipo: tipo
    }
  };
};
export var activarCuponDescuento = function activarCuponDescuento() {
  var mode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'token';
  var hash = arguments.length > 1 ? arguments[1] : undefined;
  var cupon = arguments.length > 2 ? arguments[2] : undefined;
  var tipo = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'promocion';
  return {
    type: ACTIVAR_CUPON_DESCUENTO,
    payload: {
      mode: mode,
      hash: hash,
      cupon: cupon,
      tipo: tipo
    }
  };
};
export var activarCuponSuccess = function activarCuponSuccess(response, tipo) {
  return {
    type: ACTIVAR_CUPON_SUCCESS,
    payload: {
      promo: response,
      tipo: tipo
    }
  };
};
export var activarCuponDescuentoSuccess = function activarCuponDescuentoSuccess(response, tipo) {
  return {
    type: ACTIVAR_CUPON_DESCUENTO_SUCCESS,
    payload: {
      promo: response,
      tipo: tipo
    }
  };
};
export var activarCuponFail = function activarCuponFail(cause) {
  return {
    type: ACTIVAR_CUPON_FAIL,
    payload: cause
  };
};
export var activarCuponDescuentoFail = function activarCuponDescuentoFail(cause) {
  return {
    type: ACTIVAR_CUPON_DESCUENTO_FAIL,
    payload: cause
  };
};
export var activarChallenge = function activarChallenge() {
  var mode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'token';
  var hash = arguments.length > 1 ? arguments[1] : undefined;
  var challenge = arguments.length > 2 ? arguments[2] : undefined;
  var tipo = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'desafio';
  return {
    type: ACTIVAR_CHALLENGE,
    payload: {
      mode: mode,
      hash: hash,
      challenge: challenge,
      tipo: tipo
    }
  };
};
export var activarChallengeSuccess = function activarChallengeSuccess(response, tipo) {
  return {
    type: ACTIVAR_CHALLENGE_SUCCESS,
    payload: {
      promo: response,
      tipo: tipo
    }
  };
};
export var activarChallengeFail = function activarChallengeFail(cause) {
  return {
    type: ACTIVAR_CHALLENGE_FAIL,
    payload: cause
  };
};
export var descartarCupon = function descartarCupon() {
  var mode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'token';
  var hash = arguments.length > 1 ? arguments[1] : undefined;
  var cupon = arguments.length > 2 ? arguments[2] : undefined;
  return {
    type: DESCARTAR_CUPON,
    payload: {
      mode: mode,
      hash: hash,
      cupon: cupon
    }
  };
};
export var descartarCuponDescuento = function descartarCuponDescuento() {
  var mode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'token';
  var hash = arguments.length > 1 ? arguments[1] : undefined;
  var cupon = arguments.length > 2 ? arguments[2] : undefined;
  return {
    type: DESCARTAR_CUPON_DESCUENTO,
    payload: {
      mode: mode,
      hash: hash,
      cupon: cupon
    }
  };
};
export var descartarCuponSuccess = function descartarCuponSuccess(response) {
  return {
    type: DESCARTAR_CUPON_SUCCESS,
    payload: response
  };
};
export var descartarCuponDescuentoSuccess = function descartarCuponDescuentoSuccess(response) {
  return {
    type: DESCARTAR_CUPON_DESCUENTO_SUCCESS,
    payload: response
  };
};
export var descartarCuponFail = function descartarCuponFail(cause) {
  return {
    type: DESCARTAR_CUPON_FAIL,
    payload: cause
  };
};
export var descartarCuponDescuentoFail = function descartarCuponDescuentoFail(cause) {
  return {
    type: DESCARTAR_CUPON_DESCUENTO_FAIL,
    payload: cause
  };
};
export var setCuponActivo = function setCuponActivo(promo, tipo) {
  return {
    type: SET_CUPON_ACTIVO,
    payload: {
      promo: promo,
      tipo: tipo
    }
  };
};
export var setCuponDescuentoActivo = function setCuponDescuentoActivo(promo, tipo) {
  return {
    type: SET_CUPON_DESCUENTO_ACTIVO,
    payload: {
      promo: promo,
      tipo: tipo
    }
  };
};
export var setCuponDescartado = function setCuponDescartado(promo, tipo) {
  return {
    type: SET_CUPON_DESCARTADO,
    payload: {
      promo: promo,
      tipo: tipo
    }
  };
};
export var setCuponDescuentoDescartado = function setCuponDescuentoDescartado(promo, tipo) {
  return {
    type: SET_CUPON_DESCUENTO_DESCARTADO,
    payload: {
      promo: promo,
      tipo: tipo
    }
  };
};
export var getOfertasHome = function getOfertasHome() {
  return {
    type: GET_OFERTAS_HOME
  };
};
export var getOfertasHomeSuccess = function getOfertasHomeSuccess(home) {
  return {
    type: GET_OFERTAS_HOME_SUCCESS,
    payload: home
  };
};
export var getOfertasHomeFail = function getOfertasHomeFail(cause) {
  return {
    type: GET_OFERTAS_HOME_FAIL,
    payload: cause
  };
};
export var setHome = function setHome(home) {
  return {
    type: SET_HOME,
    payload: home
  };
};
export var pressDescargarCupones = function pressDescargarCupones(payload) {
  return _objectSpread({
    type: PRESS_DESCARGAR_CUPONES
  }, payload);
};
export var setTimeDescargarCupones = function setTimeDescargarCupones(time) {
  return {
    type: SET_TIME_DESCARGAR_CUPONES,
    payload: time
  };
};
export var activarAllCupon = function activarAllCupon() {
  var mode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'token';
  var hash = arguments.length > 1 ? arguments[1] : undefined;
  var cupon = arguments.length > 2 ? arguments[2] : undefined;
  var tipo = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'promocion';
  return {
    type: ACTIVAR_ALL_CUPON,
    payload: {
      mode: mode,
      hash: hash,
      tipo: tipo
    }
  };
};
export var activarAllCuponByHash = function activarAllCuponByHash() {
  var mode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'token';
  var hash = arguments.length > 1 ? arguments[1] : undefined;
  var tipo = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'promocion';
  return {
    type: ACTIVAR_ALL_CUPON_BY_HASH,
    payload: {
      mode: mode,
      hash: hash,
      tipo: tipo
    }
  };
};
export var activarAllCuponDescuento = function activarAllCuponDescuento() {
  var mode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'token';
  var hash = arguments.length > 1 ? arguments[1] : undefined;
  var tipo = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'promocion';
  return {
    type: ACTIVAR_ALL_CUPON_DESCUENTO,
    payload: {
      mode: mode,
      hash: hash,
      tipo: tipo
    }
  };
};
export var activarAllCuponDescuentoByHash = function activarAllCuponDescuentoByHash() {
  var mode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'token';
  var hash = arguments.length > 1 ? arguments[1] : undefined;
  var tipo = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'promocion';
  return {
    type: ACTIVAR_ALL_CUPON_DESCUENTO_BY_HASH,
    payload: {
      mode: mode,
      hash: hash,
      tipo: tipo
    }
  };
};
export var activarAllCuponSuccess = function activarAllCuponSuccess(response, tipo) {
  return {
    type: ACTIVAR_ALL_CUPON_SUCCESS,
    payload: {
      promo: response,
      tipo: tipo
    }
  };
};
export var activarAllCuponByHashSuccess = function activarAllCuponByHashSuccess(response, tipo) {
  return {
    type: ACTIVAR_ALL_CUPON_BY_HASH_SUCCESS,
    payload: {
      promo: response,
      tipo: tipo
    }
  };
};
export var activarAllCuponDescuentoSuccess = function activarAllCuponDescuentoSuccess(response, tipo) {
  return {
    type: ACTIVAR_ALL_CUPON_DESCUENTO_SUCCESS,
    payload: {
      promo: response,
      tipo: tipo
    }
  };
};
export var activarAllCuponDescuentoByHashSuccess = function activarAllCuponDescuentoByHashSuccess(response, tipo) {
  return {
    type: ACTIVAR_ALL_CUPON_DESCUENTO_BY_HASH_SUCCESS,
    payload: {
      promo: response,
      tipo: tipo
    }
  };
};
export var activarAllCuponFail = function activarAllCuponFail(cause) {
  return {
    type: ACTIVAR_ALL_CUPON_FAIL,
    payload: cause
  };
};
export var activarAllCuponByHashFail = function activarAllCuponByHashFail(cause) {
  return {
    type: ACTIVAR_ALL_CUPON_BY_HASH_FAIL,
    payload: cause
  };
};
export var activarAllCuponDescuentoFail = function activarAllCuponDescuentoFail(cause) {
  return {
    type: ACTIVAR_ALL_CUPON_DESCUENTO_FAIL,
    payload: cause
  };
};
export var activarAllCuponDescuentoByHashFail = function activarAllCuponDescuentoByHashFail(cause) {
  return {
    type: ACTIVAR_ALL_CUPON_DESCUENTO_BY_HASH_FAIL,
    payload: cause
  };
};
export var setAllCuponActivo = function setAllCuponActivo(cupon) {
  return {
    type: SET_ALL_CUPON_ACTIVO,
    payload: cupon
  };
};
export var setAllCuponDescuentoActivo = function setAllCuponDescuentoActivo(cupon) {
  return {
    type: SET_ALL_CUPON_DESCUENTO_ACTIVO,
    payload: cupon
  };
};
export var activarAllChallenge = function activarAllChallenge() {
  var mode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'token';
  var hash = arguments.length > 1 ? arguments[1] : undefined;
  var tipo = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'desafio';
  return {
    type: ACTIVAR_ALL_CHALLENGE,
    payload: {
      mode: mode,
      hash: hash,
      tipo: tipo
    }
  };
};
export var activarAllChallengeByHash = function activarAllChallengeByHash() {
  var mode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'token';
  var hash = arguments.length > 1 ? arguments[1] : undefined;
  var tipo = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'desafio';
  return {
    type: ACTIVAR_ALL_CHALLENGE_BY_HASH,
    payload: {
      mode: mode,
      hash: hash,
      tipo: tipo
    }
  };
};
export var activarAllChallengeSuccess = function activarAllChallengeSuccess(response, tipo) {
  return {
    type: ACTIVAR_ALL_CHALLENGE_SUCCESS,
    payload: {
      promo: response,
      tipo: tipo
    }
  };
};
export var activarAllChallengeByHashSuccess = function activarAllChallengeByHashSuccess(response, tipo) {
  return {
    type: ACTIVAR_ALL_CHALLENGE_BY_HASH_SUCCESS,
    payload: {
      promo: response,
      tipo: tipo
    }
  };
};
export var activarAllChallengeFail = function activarAllChallengeFail(cause) {
  return {
    type: ACTIVAR_ALL_CHALLENGE_FAIL,
    payload: cause
  };
};
export var activarAllChallengeByHashFail = function activarAllChallengeByHashFail(cause) {
  return {
    type: ACTIVAR_ALL_CHALLENGE_BY_HASH_FAIL,
    payload: cause
  };
};
export var setAllChallengeActivo = function setAllChallengeActivo(challenge) {
  return {
    type: SET_ALL_CHALLENGE_ACTIVO,
    payload: challenge
  };
};