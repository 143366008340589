var namespace = '[ui]';
export var DEACTIVATE_HOME_CAMPAGNA = "".concat(namespace, " desactivar campa\xF1a inicio");
export var DEACTIVATE_CATALOGO_CAMPAGNA = "".concat(namespace, " desactivar campa\xF1a catalogo");
export var deactivateHomeCampagna = function deactivateHomeCampagna() {
  return {
    type: DEACTIVATE_HOME_CAMPAGNA
  };
};
export var deactivateCatalogoCampagna = function deactivateCatalogoCampagna() {
  return {
    type: DEACTIVATE_CATALOGO_CAMPAGNA
  };
};