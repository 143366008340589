var namespace = '[contacto]';
export var CONTACTO = "".concat(namespace, " inteto de envio");
export var CONTACTO_SUCCESS = "".concat(CONTACTO, ": success");
export var CONTACTO_FAIL = "".concat(CONTACTO, ": fail");
export var GET_MOTIVO = "".concat(namespace, " lista motivos");
export var GET_MOTIVO_SUCCESS = "".concat(GET_MOTIVO, ": success");
export var GET_MOTIVO_FAIL = "".concat(GET_MOTIVO, ": fail");
export var SET_MOTIVO = "".concat(namespace, " set lista motivos");
export var sendContacto = function sendContacto(rut, nombre, apellidos, email, motivo, telefono, mensaje, captcha) {
  return {
    type: CONTACTO,
    payload: {
      rut: rut,
      nombre: nombre,
      apellidos: apellidos,
      email: email,
      motivo: motivo,
      telefono: telefono,
      mensaje: mensaje,
      captcha: captcha
    }
  };
};
export var sendContactoSuccess = function sendContactoSuccess(response) {
  return {
    type: CONTACTO_SUCCESS,
    payload: response
  };
};
export var sendContactoFail = function sendContactoFail(cause) {
  return {
    type: CONTACTO_FAIL,
    payload: cause
  };
};
export var getMotivo = function getMotivo() {
  return {
    type: GET_MOTIVO
  };
};
export var getMotivoSuccess = function getMotivoSuccess(response) {
  return {
    type: GET_MOTIVO_SUCCESS,
    payload: response
  };
};
export var getMotivoFail = function getMotivoFail(cause) {
  return {
    type: GET_MOTIVO_FAIL,
    payload: cause
  };
};
export var setMotivo = function setMotivo(motivos) {
  return {
    type: SET_MOTIVO,
    payload: motivos
  };
};