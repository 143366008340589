import _defineProperty from "/home/jenkins/agent/workspace/UCTION_FRONT_deploy_front_master/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import URITemplate from 'urijs/src/URITemplate';
import Uri from 'urijs/src/URI';

/**
 * Crea una uri usando interpolación de strings sobre una plantilla.
 *
 * E.g. La plantilla "/{test}/end" con valores `{ test: 'start' }` es
 * transformada en "/start/end".
 *
 * @param {string|Uri} uriTemplate - Plantilla de uri
 * @param {Object} values - {@link Endpoint#uriParams}
 * @return {string} Uri transformada a partir de la plantilla
 */
export var createUri = function createUri(uriTemplate, values) {
  try {
    // URITemplate arroja error al recibir URI en lugar de string
    var stringUriTemplate = uriTemplate instanceof Uri ? uriTemplate.resource() : uriTemplate;
    return new URITemplate(stringUriTemplate).expand(values);
  } catch (e) {
    var message = "Error al intentar interpolar valores \"".concat(values, "\" en la\n plantilla \"").concat(uriTemplate, ".\" Asegurar que los valores fueron provistos\n correctamente").replace(/\n/g, '');
    throw new Error(message);
  }
};
export default (function (_ref) {
  var method = _ref.method,
    uri = _ref.uri,
    _ref$uriParams = _ref.uriParams,
    uriParams = _ref$uriParams === void 0 ? {} : _ref$uriParams;
  return function (o) {
    return _objectSpread(_objectSpread({}, o), {}, {
      method: method,
      uri: uri,
      uriParams: uriParams,
      withUriParams: function withUriParams(params) {
        return _objectSpread(_objectSpread({}, this), {}, {
          uriParams: params
        });
      },
      getComputedUri: function getComputedUri() {
        return createUri(this.uri, this.uriParams);
      }
    });
  };
});