import * as Yup from 'yup';
import moment from 'moment';
import { separar, valido } from '../rut';
var nombreRegex = /^[a-zA-ZñÑ\sÁÉÍÓÚáéíóú]+$/;
export var regExpPassword = /(\d)\1{3,}|(?:0123|1234|2345|3456|4567|5678|6789|7890)/;
export var email = Yup.string().email('Por favor, corrige este Correo Electrónico ya que no es válido.').required('Por favor, ingresa Correo Electrónico.');
export var emailConfirmation = function emailConfirmation(_email) {
  return Yup.string().email('Este Correo Electrónico no es válido, Por favor corrígelo.').matches(new RegExp("^".concat(_email, "$")), 'Las direcciones de Correo Electrónico no coinciden entre sí. Por favor, vuelve a ingresarlas.').required('Por favor, ingresa Correo Electrónico.');
};
export var password = Yup.string().matches(/^\d{6}$/, ' ').required('Campo requerido').test('Check Consecutive and Equals Numbers', ' ', function (value) {
  return !regExpPassword.test(value);
});
export var passwordLogin = Yup.string().max(15, 'Largo 15 caracteres').min(6, 'Largo mínimo 6 caracteres').required('Por favor, ingresa tu Contraseña.');
export var escapeRegExp = function escapeRegExp(text) {
  return text && text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};
export var passwordConfirmation = function passwordConfirmation(_password) {
  var newReg = new RegExp("^".concat(escapeRegExp(_password), "$"));
  return Yup.string().min(6, ' ').test('Check Consecutive and Equals Numbers', ' ', function (value) {
    return !regExpPassword.test(value);
  }).matches(newReg, 'Las contraseñas no coinciden entre sí. Por favor, vuelve a ingresarlas.').required('Por favor, ingresa tu Contraseña.');
};
export var referidoValidation = function referidoValidation(_inscriptionRut) {
  var equalsRuts = function equalsRuts(string) {
    return string === _inscriptionRut;
  };
  return Yup.string().test('Should not be the same referer and rut', 'El rut del referido no puede ser el mismo que el rut de inscripción', function (value) {
    return !equalsRuts(value);
  }).notRequired();
};
export var rut = Yup.string().test('rut', 'Por favor, verifica Rut.', function (_rut) {
  return separar(_rut).numero < 50000000;
}).test('rut', 'Por favor, verifica Rut.', function (_rut) {
  return valido(_rut);
}).required('Por favor, ingresa Rut.');
export var fechaNacimiento = Yup.date().max(moment().subtract(18, 'years'), 'Debes tener al menos 18 años para poder inscribirte.').typeError('Por favor, ingresa Fecha de Nacimiento.').required('Por favor, ingresa Fecha de Nacimiento.');
export var nombre = Yup.string().matches(nombreRegex, 'Por favor, verifica Nombre, solo debe llevar letras.').max(30, 'El nombre debe tener como máximo 30 caracteres.').required('Por favor, ingresa Nombre.');
export var apellidoPaterno = Yup.string().matches(nombreRegex, 'Por favor, verifica Apellido, solo debe llevar letras.').max(30, 'El Apellido debe tener como máximo 30 caracteres.').required('Por favor, ingresa Primer Apellido.');
export var apellido = Yup.string().matches(nombreRegex, 'Por favor, verifica Apellido, solo debe llevar letras.').max(30, 'El Apellido debe tener como máximo 30 caracteres.').required('Por favor, ingresa tú Apellido.');
export var apellidoMaterno = Yup.string().matches(nombreRegex, 'Por favor, verifica Apellido, solo debe llevar letras.').max(30, 'El Apellido debe tener como máximo 30 caracteres').required('Por favor, ingresa Segundo Apellido.');
export var telefono = Yup.string().matches(/\d{8}/, 'Por favor, verifica Número Celular.').matches(/^[2-9].*$/, 'Por favor, verifica Número Celular.').matches(/^([0-9]){8,8}$/, 'Son 8 dígitos como máximo, se incluye +569.').matches(/^\d*$/, 'Por favor, el Número Celular sólo debe llevar números.').nullable(true).required('Por favor, ingresa Número Celular.');
export var region = Yup.string().required('Por favor, selecciona una Región.');
export var comuna = Yup.string().required('Por favor, selecciona una Comuna.');
export var calle = Yup.string().max(100, 'Excede los caracteres').required('Ingresa nombre de calle.');
export var numero = Yup.string().max(9, 'Solamente se admiten un máximo de 9 caracteres.').matches(/^[a-zA-ZñÑ\dÁÉÍÓÚáéíóú]+$/, 'Ingresa solo caracteres alfanuméricos').nullable(true).required('Ingresa número de calle.');
export var departamento = Yup.string().max(5, 'Solamente se admiten un máximo de 5 caracteres.').matches(/^[a-zA-ZñÑ\dÁÉÍÓÚáéíóú]+$/, 'Ingresa solo caracteres alfanuméricos').nullable(true);
export var codigo = Yup.string().max(6, 'El código de confirmación debe tener 6 dígitos.').min(6, 'El código de confirmación debe tener 6 dígitos.').matches(/^[a-zA-ZñÑ\dÁÉÍÓÚáéíóú]+$/, 'Solo se admiten números y letras.').required('Por favor, ingresa el código de confirmación.');