import get from 'lodash/get';
import { apiRequest } from '@cencosud/puntos-core/src/features/api/api.actions';
import { formatear } from '@cencosud/puntos-core/src/features/common/rut';
import { GET_ADICIONALES_PENDIENTES_FAIL, GET_ADICIONALES_PENDIENTES_SUCCESS, GET_ADICIONALES_PENDIENTES, obtenerAdicionalesPendientesSuccess, obtenerAdicionalesPendientesFail, setAdicionalesPendientes } from './adicionalesPendientes.actions';
import { loyaltyEndpoints } from '@cencosud/puntos-core/src/features/api/endpoints';
export var obtenerAdicionalesPendientesFlow = function obtenerAdicionalesPendientesFlow(_ref) {
  var dispatch = _ref.dispatch,
    getState = _ref.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_ADICIONALES_PENDIENTES) {
        return nextResponse;
      }
      return dispatch(apiRequest(loyaltyEndpoints.usuario.adicionales.getPendientes(getState().auth.rut), obtenerAdicionalesPendientesSuccess, obtenerAdicionalesPendientesFail, {
        auth: true
      }));
    };
  };
};
export var processOtorgarPermisoSocio = function processOtorgarPermisoSocio(_ref2) {
  var dispatch = _ref2.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      switch (type) {
        case GET_ADICIONALES_PENDIENTES_SUCCESS:
          {
            var adicionales = get(payload, 'payload');
            return dispatch(setAdicionalesPendientes(adicionales.map(function (adicional) {
              return {
                rut: formatear(adicional.Id),
                nombre: "".concat(adicional.nombre, " ").concat(adicional.ApellidoPaterno),
                isPendienteTyC: adicional.SubEstado === 'Pendiente firma T&C'
              };
            })));
          }
        case GET_ADICIONALES_PENDIENTES_FAIL:
          {
            return dispatch(setAdicionalesPendientes([]));
          }
        default:
          return nextResponse;
      }
    };
  };
};
export default [obtenerAdicionalesPendientesFlow, processOtorgarPermisoSocio];