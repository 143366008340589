import isObject from 'lodash/isObject';
export default (function (banner) {
  return isObject(banner) ? {
    id: null,
    altText: null,
    landing: null,
    name: banner.name,
    subtype: 'BannerVideo',
    nombreClub: null,
    titulo: banner.title,
    video: banner.video,
    descripcion: banner.description,
    puntosExtra1: null,
    medioPago: null,
    medioPago2: null,
    puntosExtra2: null,
    tarjetaCencosud: null,
    nombreBoton: banner.buttonName,
    colorBoton: null,
    legal1: null,
    legal2: null,
    productos: null,
    linkBoton: banner.buttonLink,
    imagenBanner: null,
    imagenBanner2: null,
    textoSuperior: null,
    bajadaSuperior: null,
    bajadaPrincipal: null,
    externalInternalLink: null,
    urlImagen: null,
    urlImagenMobile: null,
    link: null
  } : {};
});