import { namespace } from '@cencosud/puntos-core/src/features/usuario/usuario.actions';
export var AGREGAR_ADICIONAL = "".concat(namespace, " agregar adicional");
export var AGREGAR_ADICIONAL_SUCCESS = "".concat(AGREGAR_ADICIONAL, ": success");
export var AGREGAR_ADICIONAL_FAIL = "".concat(AGREGAR_ADICIONAL, ": fail");
export var agregarAdicional = function agregarAdicional(_ref) {
  var rut = _ref.rut,
    nombre = _ref.nombre,
    apellidoPaterno = _ref.apellidoPaterno,
    apellidoMaterno = _ref.apellidoMaterno,
    email = _ref.email,
    telefono = _ref.telefono,
    fechaNacimiento = _ref.fechaNacimiento;
  return {
    type: AGREGAR_ADICIONAL,
    payload: {
      rut: rut,
      nombre: nombre,
      apellidoPaterno: apellidoPaterno,
      apellidoMaterno: apellidoMaterno,
      email: email,
      telefono: telefono,
      fechaNacimiento: fechaNacimiento
    }
  };
};
export var agregarAdicionalSuccess = function agregarAdicionalSuccess(adicional) {
  return {
    type: AGREGAR_ADICIONAL_SUCCESS,
    payload: adicional
  };
};
export var agregarAdicionalFail = function agregarAdicionalFail(cause) {
  return {
    type: AGREGAR_ADICIONAL_FAIL,
    payload: cause
  };
};