/**
 * dicionarioDeTextos
 * registrar titulos para paginas
 */
var dicionarioDeTextos = function dicionarioDeTextos(key) {
  var textos = {
    home: {
      titulo: 'Home',
      descripcion: 'Puntos Cencosud, mientras más los usas, más ganas.'
    },
    catalogo: {
      titulo: 'Catálogo ',
      descripcion: 'Descubre nuestro catálogo y disfruta canjeando tus puntos!'
    },
    ingresar: {
      titulo: 'Ingresar',
      descripcion: 'Ingresa y disfruta ahora de tus Puntos Cencosud'
    },
    inscribir: {
      titulo: 'Inscríbete',
      descripcion: 'Inscríbete en nuestro programa de puntos'
    },
    recuperar: {
      titulo: '¿Olvidaste tu Contraseña?',
      descripcion: 'Recupera tu cuenta de tus Puntos Cencosud'
    },
    promociones: {
      titulo: 'Promociones',
      descripcion: 'Descubre nuestras promociones y ofertas personalizadas'
    },
    default: {
      titulo: 'Falta titulo'
    }
  };
  return textos[key] || {
    titulo: key
  };
};
var getOrCreateDescription = function getOrCreateDescription() {
  var maybeDescription = document.querySelectorAll('meta[name="Description"]');
  if (maybeDescription[0]) {
    return maybeDescription[0];
  }
  var description = document.createElement('meta');
  description.setAttribute('name', 'Description');
  document.head.appendChild(description);
  return description;
};

/**
 * @param {string} key - el nombre de la llave del texto a
 * mostrar o de no coincidir, el texto en si mismo, se debe registrar el texto en la propiedad superior 'diccionarioDeDatos'
 */
export default (function () {
  var key = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'default';
  // solo se ejecutara el codigo si es web, pues en movil pierde sentido
  var prefix = 'Puntos Cencosud |';
  var titleNode = document.querySelector('title');
  titleNode.text = "".concat(prefix, " ").concat(dicionarioDeTextos(key).titulo);
  var description = getOrCreateDescription();
  description.setAttribute('content', dicionarioDeTextos(key).descripcion);
});