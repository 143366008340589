import _defineProperty from "/home/jenkins/agent/workspace/UCTION_FRONT_deploy_front_master/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "/home/jenkins/agent/workspace/UCTION_FRONT_deploy_front_master/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["run", "telefono", "titular", "puntos"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { apiRequest } from '@cencosud/puntos-core/src/features/api/api.actions';
import { hideFullscreenProgress, hideSecondaryProgressBar, showFullscreenProgress, showNotifier, showSecondaryProgress } from '@cencosud/puntos-core/src/features/ui/ui.actions';
import generos from '@cencosud/puntos-core/src/features/common/generos';
import { ACTUALIZAR_DETALLE, ACTUALIZAR_DETALLE_FAIL, ACTUALIZAR_DETALLE_SUCCESS, ACTUALIZAR_TELEFONO, actualizarDetalleFail, actualizarDetalleSuccess, GENERAR_CODIGO_USUARIO, generarCodigoUsuarioFail, generarCodigoUsuarioSuccess, GET_DETALLE, GET_DETALLE_FAIL, GET_DETALLE_SUCCESS, getDetalleFail, getDetalleSuccess, setDetalle, ACTUALIZAR_PREFERENCIAS_CONTACTO, actualizarPreferenciasSuccess, actualizarPreferenciasFail, ACTUALIZAR_PREFERENCIAS_SUCCESS, ACTUALIZAR_PREFERENCIAS_FAIL, editarPreferencias, VALIDAR_IDENTIDAD, validarIdentidadSuccess, validarIdentidadFail, VALIDAR_IDENTIDAD_SUCCESS, VALIDAR_IDENTIDAD_FAIL, actualizarTelefonoSuccess, actualizarTelefonoFail, ACTUALIZAR_CORREO, actualizarCorreoSuccess, actualizarCorreoFail, ACTUALIZAR_CORREO_FAIL, ACTUALIZAR_CORREO_SUCCESS, ACTUALIZAR_TELEFONO_SUCCESS, ACTUALIZAR_TELEFONO_FAIL, confirmarDatosSuccess, confirmarDatosFail, CONFIRMAR_DATOS, CONFIRMAR_DATOS_SUCCESS, CONFIRMAR_DATOS_FAIL } from './usuario.actions';
import { eliminarCaracteresEspeciales } from '../common/rut';
import processErrorMessage from '../api/processErrorMessage';
import { loyaltyEndpoints } from '../api/endpoints';
import { setLoggedOutState } from '../auth/auth.mdl';
export var getDetalleFlow = function getDetalleFlow(_ref) {
  var dispatch = _ref.dispatch,
    getState = _ref.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_DETALLE) {
        return nextResponse;
      }
      var rut = get(getState(), 'auth.rut');
      if (!rut) {
        return nextResponse;
      }
      return Promise.all([dispatch(apiRequest(loyaltyEndpoints.usuario.get(getState().auth.rut), function (usuario) {
        return getDetalleSuccess(usuario, action.meta);
      }, function (cause) {
        return getDetalleFail(cause, action.meta);
      }, {
        auth: true
      })), action.meta.fullscreenProgress && dispatch(showFullscreenProgress())]);
    };
  };
};
export var processGetDetalle = function processGetDetalle(_ref2) {
  var dispatch = _ref2.dispatch,
    getState = _ref2.getState;
  return function (next) {
    return function (action) {
      var _getState$regiones;
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      if (type === GET_DETALLE_FAIL) {
        if (action.meta.fullscreenProgress) {
          return dispatch(hideFullscreenProgress());
        }
        setLoggedOutState(dispatch);
        return nextResponse;
      }
      if (type !== GET_DETALLE_SUCCESS) {
        return nextResponse;
      }

      // TODO normalizar datos de usuario en otra acción
      var _payload$payload = payload.payload,
        run = _payload$payload.run,
        telefono = _payload$payload.telefono,
        titular = _payload$payload.titular,
        puntos = _payload$payload.puntos,
        usuario = _objectWithoutProperties(_payload$payload, _excluded);
      var region = (_getState$regiones = getState().regiones) === null || _getState$regiones === void 0 ? void 0 : _getState$regiones.find(function (x) {
        return (x === null || x === void 0 ? void 0 : x.nombre) === usuario.region;
      });
      var comuna = isArray(region && region.comunas) && region.comunas.find(function (x) {
        return x.nombre === usuario.comuna;
      });
      var genero = generos.find(function (x) {
        return x.nombre === usuario.genero;
      });
      return Promise.all([dispatch(setDetalle(_objectSpread(_objectSpread({}, usuario), {}, {
        rut: run,
        telefono: telefono ? telefono.slice(-8) : null,
        region: region,
        comuna: comuna,
        genero: genero,
        esAdicional: !titular,
        puntos: parseInt(puntos, 10),
        tieneContrasenaTemporal: action.meta.tieneContrasenaTemporal || getState().user.tieneContrasenaTemporal || false
      }))), action.meta.fullscreenProgress && dispatch(hideFullscreenProgress())]);
    };
  };
};
export var actualizarTelefono = function actualizarTelefono(_ref3) {
  var dispatch = _ref3.dispatch,
    getState = _ref3.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== ACTUALIZAR_TELEFONO) {
        return nextResponse;
      }
      var _action$payload = action.payload,
        sexo = _action$payload.sexo,
        calle = _action$payload.calle,
        numero = _action$payload.numero,
        departamento = _action$payload.departamento,
        region = _action$payload.region,
        comuna = _action$payload.comuna,
        telefono = _action$payload.telefono,
        type = _action$payload.type,
        numeroSecreto = _action$payload.numeroSecreto;
      return Promise.all([dispatch(showSecondaryProgress()), dispatch(apiRequest(loyaltyEndpoints.usuario.actualizarPhone(getState().auth.rut), actualizarTelefonoSuccess, actualizarTelefonoFail, {
        body: {
          canal: 'Portal',
          mobilePhone: "9".concat(telefono),
          type: type,
          numeroSecreto: numeroSecreto,
          genero: sexo.id,
          address: {
            calle: calle,
            numero: numero,
            departamento: departamento,
            region: region.nombre,
            provincia: comuna.provincia.nombre,
            comuna: comuna.nombre
          }
        },
        auth: true
      }))]);
    };
  };
};
export var actualizarCorreo = function actualizarCorreo(_ref4) {
  var dispatch = _ref4.dispatch,
    getState = _ref4.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== ACTUALIZAR_CORREO) {
        return nextResponse;
      }
      var _action$payload2 = action.payload,
        sexo = _action$payload2.sexo,
        calle = _action$payload2.calle,
        numero = _action$payload2.numero,
        departamento = _action$payload2.departamento,
        region = _action$payload2.region,
        comuna = _action$payload2.comuna,
        email = _action$payload2.email,
        type = _action$payload2.type,
        numeroSecreto = _action$payload2.numeroSecreto;
      return Promise.all([dispatch(showSecondaryProgress()), dispatch(apiRequest(loyaltyEndpoints.usuario.actualizarEmail(getState().auth.rut), actualizarCorreoSuccess, actualizarCorreoFail, {
        body: {
          canal: 'Portal',
          correo: email,
          type: type,
          numeroSecreto: numeroSecreto,
          genero: sexo.id,
          address: {
            calle: calle,
            numero: numero,
            departamento: departamento,
            region: region.nombre,
            provincia: comuna.provincia.nombre,
            comuna: comuna.nombre
          }
        },
        auth: true
      }))]);
    };
  };
};
export var actualizarDetalleFlow = function actualizarDetalleFlow(_ref5) {
  var dispatch = _ref5.dispatch,
    getState = _ref5.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== ACTUALIZAR_DETALLE) {
        return nextResponse;
      }
      var _action$payload3 = action.payload,
        sexo = _action$payload3.sexo,
        calle = _action$payload3.calle,
        numero = _action$payload3.numero,
        departamento = _action$payload3.departamento,
        region = _action$payload3.region,
        comuna = _action$payload3.comuna;
      return Promise.all([dispatch(showSecondaryProgress()), dispatch(apiRequest(loyaltyEndpoints.usuario.actualizar(getState().auth.rut), actualizarDetalleSuccess, actualizarDetalleFail, {
        body: {
          genero: sexo.id,
          canal: 'Portal',
          address: {
            calle: calle,
            numero: numero,
            departamento: departamento,
            region: region.nombre,
            provincia: comuna.provincia.nombre,
            comuna: comuna.nombre
          }
        },
        auth: true
      }))]);
    };
  };
};
export var processActualizarDetalle = function processActualizarDetalle(_ref6) {
  var dispatch = _ref6.dispatch,
    getState = _ref6.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type;
      if (type !== ACTUALIZAR_DETALLE_FAIL && type !== ACTUALIZAR_DETALLE_SUCCESS && type !== ACTUALIZAR_CORREO_SUCCESS && type !== ACTUALIZAR_CORREO_FAIL && type !== ACTUALIZAR_TELEFONO_SUCCESS && type !== ACTUALIZAR_TELEFONO_FAIL && type !== CONFIRMAR_DATOS_SUCCESS && type !== CONFIRMAR_DATOS_FAIL) {
        return nextResponse;
      }
      var progressResponse = dispatch(hideSecondaryProgressBar());
      // eslint-disable-next-line default-case
      switch (type) {
        case ACTUALIZAR_DETALLE_FAIL:
          {
            var message = processErrorMessage(action.payload, getState().ui.parameterize);
            return Promise.all([progressResponse, dispatch(showNotifier(message))]);
          }
        case ACTUALIZAR_TELEFONO_FAIL:
          {
            var _message = processErrorMessage(action.payload, getState().ui.parameterize);
            return Promise.all([progressResponse, dispatch(showNotifier(_message))]);
          }
        case CONFIRMAR_DATOS_FAIL:
          {
            var _message2 = processErrorMessage(action.payload, getState().ui.parameterize);
            return Promise.all([progressResponse, dispatch(showNotifier(_message2))]);
          }
        case ACTUALIZAR_CORREO_FAIL:
          {
            var _message3 = '';
            if (action.payload.code === 'LOY-070') {
              _message3 = 'El correo electrónico ingresado ya se encuentra registrado a otro usuario';
            } else {
              _message3 = processErrorMessage(action.payload, getState().ui.parameterize);
            }
            return Promise.all([progressResponse, dispatch(showNotifier(_message3))]);
          }
        case ACTUALIZAR_DETALLE_SUCCESS:
          return Promise.all([progressResponse, dispatch(setDetalle(null)), nextResponse]);
        case ACTUALIZAR_TELEFONO_SUCCESS:
          return Promise.all([progressResponse, dispatch(setDetalle(null)), nextResponse]);
        case ACTUALIZAR_CORREO_SUCCESS:
          return Promise.all([progressResponse, dispatch(setDetalle(null)), nextResponse]);
        case CONFIRMAR_DATOS_SUCCESS:
          return Promise.all([progressResponse, dispatch(setDetalle(null)), nextResponse]);
        default:
          return [];
      }
    };
  };
};
export var obtenerCodigoFlow = function obtenerCodigoFlow(_ref7) {
  var dispatch = _ref7.dispatch,
    getState = _ref7.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GENERAR_CODIGO_USUARIO) {
        return nextResponse;
      }
      var _action$payload4 = action.payload,
        type = _action$payload4.type,
        numeroSecreto = _action$payload4.numeroSecreto;
      return Promise.all([dispatch(apiRequest(loyaltyEndpoints.usuario.codigo.generar(), generarCodigoUsuarioSuccess, generarCodigoUsuarioFail, {
        body: {
          type: type,
          numeroSecreto: numeroSecreto,
          id: eliminarCaracteresEspeciales(getState().auth.rut)
        },
        auth: true
      }))]);
    };
  };
};
export var processActualizarPref = function processActualizarPref(_ref8) {
  var dispatch = _ref8.dispatch,
    getState = _ref8.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== ACTUALIZAR_PREFERENCIAS_CONTACTO) {
        return nextResponse;
      }
      var rut = getState().auth.rut;
      var _action$payload5 = action.payload,
        sms = _action$payload5.sms,
        call = _action$payload5.call,
        email = _action$payload5.email,
        mail = _action$payload5.mail,
        ecologicalReceipt = _action$payload5.ecologicalReceipt;
      return Promise.all([dispatch(apiRequest(loyaltyEndpoints.usuario.setPreferenciasContacto(rut), function (response) {
        return actualizarPreferenciasSuccess(response, {
          sms: sms,
          call: call,
          email: email,
          mail: mail,
          ecologicalReceipt: ecologicalReceipt
        });
      }, actualizarPreferenciasFail, {
        body: {
          run: rut,
          permitirLlamadoTelefonico: call,
          permitirEnvioEmail: email,
          permitirEnvioCorrespondencia: mail,
          permitirEnvioSMS: sms,
          noRecibirOfertas: false,
          permitirTodo: false,
          permitirBoletaEcologica: ecologicalReceipt
        }
      }))]);
    };
  };
};
export var procesarActualizarPreferencias = function procesarActualizarPreferencias(_ref9) {
  var dispatch = _ref9.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type;
      if (type !== ACTUALIZAR_PREFERENCIAS_SUCCESS && type !== ACTUALIZAR_PREFERENCIAS_FAIL) {
        return nextResponse;
      }
      var _action$payload6 = action.payload,
        mail = _action$payload6.mail,
        sms = _action$payload6.sms,
        call = _action$payload6.call,
        email = _action$payload6.email,
        ecologicalReceipt = _action$payload6.ecologicalReceipt;
      // eslint-disable-next-line default-case
      switch (type) {
        case ACTUALIZAR_PREFERENCIAS_FAIL:
          {
            return nextResponse;
          }
        case ACTUALIZAR_PREFERENCIAS_SUCCESS:
          return dispatch(editarPreferencias({
            mail: mail,
            sms: sms,
            call: call,
            email: email,
            ecologicalReceipt: ecologicalReceipt
          }));
        default:
          return [];
      }
    };
  };
};
export var validarIdentidadFlow = function validarIdentidadFlow(_ref10) {
  var dispatch = _ref10.dispatch,
    getState = _ref10.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var rut = getState().auth.rut;
      var numDocSocio = action.payload;
      var type = action.type;
      if (type !== VALIDAR_IDENTIDAD || !rut) {
        return nextResponse;
      }
      return dispatch(apiRequest(loyaltyEndpoints.usuario.validarIdentidad(rut), validarIdentidadSuccess, validarIdentidadFail, {
        body: {
          numDocSocio: numDocSocio,
          runSocio: eliminarCaracteresEspeciales(rut)
        }
      }));
    };
  };
};
export var procesarValidarIdentidad = function procesarValidarIdentidad() {
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type;
      if (type !== VALIDAR_IDENTIDAD_SUCCESS && type !== VALIDAR_IDENTIDAD_FAIL) {
        return nextResponse;
      }
      switch (type) {
        case VALIDAR_IDENTIDAD_SUCCESS:
          {
            var _action$payload$paylo;
            return (_action$payload$paylo = action.payload.payload) === null || _action$payload$paylo === void 0 ? void 0 : _action$payload$paylo.socioValido;
          }
        case VALIDAR_IDENTIDAD_FAIL:
          {
            return action.payload;
          }
        default:
          return [];
      }
    };
  };
};
export var proccessConfirmationData = function proccessConfirmationData(_ref11) {
  var dispatch = _ref11.dispatch,
    getState = _ref11.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== CONFIRMAR_DATOS) {
        return nextResponse;
      }
      return Promise.all([dispatch(showSecondaryProgress()), dispatch(apiRequest(loyaltyEndpoints.usuario.confirmarDatos(getState().auth.rut), confirmarDatosSuccess, confirmarDatosFail, {
        auth: true
      }))]);
    };
  };
};
export default [getDetalleFlow, processGetDetalle, actualizarTelefono, actualizarCorreo, actualizarDetalleFlow, processActualizarDetalle, obtenerCodigoFlow, processActualizarPref, procesarActualizarPreferencias, validarIdentidadFlow, procesarValidarIdentidad, proccessConfirmationData];