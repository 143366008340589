import namespace from './LandingsNamespace';
export var GET_LANDING_DATA = "".concat(namespace, " get landing");
export var GET_LANDING_DATA_SUCCESS = "".concat(namespace, " 1 success");
export var GET_LANDING_DATA_FAIL = "".concat(namespace, " fail");
export var SET_LANDING_DATA = "".concat(namespace, " set landing1");
export var getLandingData = function getLandingData(link) {
  return {
    type: GET_LANDING_DATA,
    payload: {
      link: link
    }
  };
};
export var getLandingDataSuccess = function getLandingDataSuccess(landingData) {
  return {
    type: GET_LANDING_DATA_SUCCESS,
    payload: landingData
  };
};
export var getLandingDataFail = function getLandingDataFail(cause) {
  return {
    type: GET_LANDING_DATA_FAIL,
    payload: cause
  };
};
export var setLandingData = function setLandingData(landingPage) {
  return {
    type: SET_LANDING_DATA,
    payload: landingPage
  };
};