var transformsToLowercase = function transformsToLowercase(text) {
  return text === null || text === void 0 ? void 0 : text.toLowerCase();
};
var removeDiacritics = function removeDiacritics(text) {
  var _text$normalize, _text$normalize$repla;
  return text && (text === null || text === void 0 ? void 0 : (_text$normalize = text.normalize('NFD')) === null || _text$normalize === void 0 ? void 0 : (_text$normalize$repla = _text$normalize.replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, '$1$2')) === null || _text$normalize$repla === void 0 ? void 0 : _text$normalize$repla.normalize());
};
/**
 * Estandarizacion de textos hacia Analitics
 * Definición:  Todo los textos en minúsculas y sin acentos
 * @param {text} text
 */

export default (function (text) {
  return transformsToLowercase(removeDiacritics(text));
});

//   export default text => {
//       return
//     const state = store.getState()
//     return window.dataLayer.push(
//       isLoggedIn(state) ? withUser(event, state.user) : event
//     )
//   }