import { CONTACTO, CONTACTO_SUCCESS, CONTACTO_FAIL, sendContactoSuccess, sendContactoFail, GET_MOTIVO, GET_MOTIVO_SUCCESS, GET_MOTIVO_FAIL, setMotivo, getMotivoSuccess, getMotivoFail } from './contacto.actions';
import { showSecondaryProgress, hideSecondaryProgressBar } from '@cencosud/puntos-core/src/features/ui/ui.actions';
import { apiRequest } from '@cencosud/puntos-core/src/features/api/api.actions';
import { pushRoute } from '@cencosud/puntos-core/src/features/router/history.actions';
import { loyaltyEndpoints } from '@cencosud/puntos-core/src/features/api/endpoints';
export var contactoFlow = function contactoFlow(_ref) {
  var dispatch = _ref.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== CONTACTO) {
        return nextResponse;
      }
      var _action$payload = action.payload,
        rut = _action$payload.rut,
        nombre = _action$payload.nombre,
        apellidos = _action$payload.apellidos,
        email = _action$payload.email,
        motivo = _action$payload.motivo,
        telefono = _action$payload.telefono,
        mensaje = _action$payload.mensaje,
        captcha = _action$payload.captcha;
      return Promise.all([dispatch(showSecondaryProgress()), dispatch(apiRequest(loyaltyEndpoints.contacto.enviarFormulario(), sendContactoSuccess, sendContactoFail, {
        body: {
          run: rut,
          nombre: nombre,
          apellido: apellidos,
          telefono: telefono,
          correo: email,
          motivo: motivo.nombre,
          descripcion: mensaje,
          gRecaptchaResponse: captcha
        }
      }))]);
    };
  };
};
export var processContacto = function processContacto(_ref2) {
  var dispatch = _ref2.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== CONTACTO_SUCCESS && action.type !== CONTACTO_FAIL) {
        return nextResponse;
      }
      var progressResponse = dispatch(hideSecondaryProgressBar());
      // eslint-disable-next-line default-case
      switch (action.type) {
        case CONTACTO_SUCCESS:
          return Promise.all([progressResponse, dispatch(pushRoute('/contacto/mensaje'))]);
        case CONTACTO_FAIL:
          {
            return progressResponse;
          }
        default:
          return Promise.resolve();
      }
    };
  };
};
export var motivosFlow = function motivosFlow(_ref3) {
  var dispatch = _ref3.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_MOTIVO) {
        return nextResponse;
      }
      return dispatch(apiRequest(loyaltyEndpoints.contacto.getMotivos(), getMotivoSuccess, getMotivoFail));
    };
  };
};
export var processMotivos = function processMotivos(_ref4) {
  var dispatch = _ref4.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_MOTIVO_SUCCESS && action.type !== GET_MOTIVO_FAIL) {
        return nextResponse;
      }

      // eslint-disable-next-line default-case
      switch (action.type) {
        case GET_MOTIVO_SUCCESS:
          var motivos = action.payload.payload;
          return dispatch(setMotivo(motivos));
        case GET_MOTIVO_FAIL:
          {
            var motivosFail = [{
              codigo: 'C',
              nombre: 'Consulta'
            }, {
              codigo: 'S',
              nombre: 'Solicitud'
            }, {
              codigo: 'R',
              nombre: 'Reclamo'
            }, {
              codigo: 'O',
              nombre: 'Otro'
            }];
            return dispatch(setMotivo(motivosFail));
          }
        default:
          return Promise.resolve();
      }
    };
  };
};
export default [contactoFlow, processContacto, motivosFlow, processMotivos];