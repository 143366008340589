var namespace = '[producto tienda]';
export var GET_PRODUCTO_TIENDA = "".concat(namespace, " get producto");
export var GET_PRODUCTO_TIENDA_SUCCESS = "".concat(GET_PRODUCTO_TIENDA, ": success");
export var GET_PRODUCTO_TIENDA_FAIL = "".concat(GET_PRODUCTO_TIENDA, ": fail");
export var SET_PRODUCTO_TIENDA = "".concat(namespace, " set producto");
export var getProductoTienda = function getProductoTienda(id) {
  return {
    type: GET_PRODUCTO_TIENDA,
    payload: {
      id: id
    }
  };
};
export var getProductoTiendaSuccess = function getProductoTiendaSuccess(success) {
  return {
    type: GET_PRODUCTO_TIENDA_SUCCESS,
    payload: success
  };
};
export var getProductoTiendaFail = function getProductoTiendaFail(cause) {
  return {
    type: GET_PRODUCTO_TIENDA_FAIL,
    payload: cause
  };
};
export var setProductoTienda = function setProductoTienda(producto) {
  return {
    type: SET_PRODUCTO_TIENDA,
    payload: producto
  };
};