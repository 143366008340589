var namespace = '[history]';
export var PUSH = "".concat(namespace, " push");
export var pushRoute = function pushRoute(uri, state) {
  return {
    type: PUSH,
    payload: {
      uri: uri,
      state: state
    }
  };
};