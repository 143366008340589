import { apiRequest } from '@cencosud/puntos-core/src/features/api/api.actions';
import { GET_CATEGORIAS_NAVBAR, GET_CATEGORIAS_NAVBAR_SUCCESS, getCategoriasNavBarSuccess, getCategoriasNavBarFail, setCategoriasNavBar } from './categorias.actions';
import { contentV3Endpoints } from '../../api/endpoints';
import categoryBinder from "@cencosud/puntos-core/src/features/binder/CategoryBinder";
export var getCategoriasNavBarFlujo = function getCategoriasNavBarFlujo(_ref) {
  var dispatch = _ref.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type === GET_CATEGORIAS_NAVBAR) {
        return dispatch(apiRequest(contentV3Endpoints.productos.getNavBarCategories(), getCategoriasNavBarSuccess, getCategoriasNavBarFail));
      }
      return nextResponse;
    };
  };
};
export var getCategoriasNavBarProcesar = function getCategoriasNavBarProcesar(_ref2) {
  var dispatch = _ref2.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      if (type !== GET_CATEGORIAS_NAVBAR_SUCCESS) {
        return nextResponse;
      }
      return dispatch(setCategoriasNavBar(categoryBinder(payload.categorias)));
    };
  };
};
export default [getCategoriasNavBarFlujo, getCategoriasNavBarProcesar];