var namespace = '[captcha]';
export var GET_CAPTCHA = "".concat(namespace, " get chaptcha list");
export var GET_CAPTCHA_SUCCESS = "".concat(GET_CAPTCHA, ": success");
export var GET_CAPTCHA_FAIL = "".concat(GET_CAPTCHA, ": fail");
export var SET_CAPTCHA_RESPONSE = "".concat(namespace, " set chaptcha response");
export var getCaptcha = function getCaptcha() {
  return {
    type: GET_CAPTCHA
  };
};
export var getCaptchaSuccess = function getCaptchaSuccess(captcha) {
  return {
    type: GET_CAPTCHA_SUCCESS,
    payload: captcha
  };
};
export var getCaptchaFail = function getCaptchaFail(cause) {
  return {
    type: GET_CAPTCHA_FAIL,
    payload: cause
  };
};
export var setCaptchaResponse = function setCaptchaResponse(response) {
  return {
    type: SET_CAPTCHA_RESPONSE,
    payload: response
  };
};