/**
 * @typedef {{name: string, url: string}} Api
 */

/**
 * Crea una api
 * @param {Object} params
 * @param {string} params.kongName
 * @param {string} params.name
 * @param {string} params.url
 * @return {Api}
 */
export default (function (_ref) {
  var name = _ref.name,
    url = _ref.url,
    _ref$kongName = _ref.kongName,
    kongName = _ref$kongName === void 0 ? name : _ref$kongName;
  return {
    kongName: kongName,
    name: name,
    url: url
  };
});