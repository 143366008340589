var namespace = '[dupla]';
export var GET_DUPLAS = "".concat(namespace, " get dupla list");
export var GET_DUPLAS_SUCCESS = "".concat(GET_DUPLAS, ": success");
export var GET_DUPLAS_FAIL = "".concat(GET_DUPLAS, ": fail");
export var SET_DUPLA_LIST = "".concat(namespace, " set dupla list");
export var getDuplas = function getDuplas() {
  return {
    type: GET_DUPLAS
  };
};
export var getDuplasSuccess = function getDuplasSuccess(duplas) {
  return {
    type: GET_DUPLAS_SUCCESS,
    payload: duplas
  };
};
export var getDuplasFail = function getDuplasFail(cause) {
  return {
    type: GET_DUPLAS_FAIL,
    payload: cause
  };
};
export var setDuplaList = function setDuplaList(response) {
  return {
    type: SET_DUPLA_LIST,
    payload: response
  };
};