import namespace from '../productoActionNamespace';
export var GET_CATEGORIAS_PRODUCTO = "".concat(namespace, " get categorias");
export var GET_CATEGORIAS_PRODUCTO_SUCCESS = "".concat(GET_CATEGORIAS_PRODUCTO, ": success");
export var GET_CATEGORIAS_PRODUCTO_FAIL = "".concat(GET_CATEGORIAS_PRODUCTO, ": fail");
export var SET_CATEGORIAS_PRODUCTO = "".concat(namespace, " set categorias");
export var getCategoriasProducto = function getCategoriasProducto() {
  return {
    type: GET_CATEGORIAS_PRODUCTO
  };
};
export var getCategoriasProductoSuccess = function getCategoriasProductoSuccess(categorias) {
  return {
    type: GET_CATEGORIAS_PRODUCTO_SUCCESS,
    payload: {
      categorias: categorias
    }
  };
};
export var getCategoriasProductoFail = function getCategoriasProductoFail(cause) {
  return {
    type: GET_CATEGORIAS_PRODUCTO_FAIL,
    payload: cause
  };
};
export var setCategoriasProducto = function setCategoriasProducto(categorias) {
  return {
    type: SET_CATEGORIAS_PRODUCTO,
    payload: categorias
  };
};