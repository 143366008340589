var regions = {
  result: {
    message: 'OK'
  },
  payload: [{
    id: '0',
    comunas: [{
      provincia: {
        nombre: 'ANTOFAGASTA',
        codigo: '021'
      },
      nombre: 'ANTOFAGASTA',
      codigo: '02101'
    }, {
      provincia: {
        nombre: 'EL LOA',
        codigo: '022'
      },
      nombre: 'CALAMA',
      codigo: '02201'
    }, {
      provincia: {
        nombre: 'TOCOPILLA',
        codigo: '023'
      },
      nombre: 'MARIA ELENA',
      codigo: '02302'
    }, {
      provincia: {
        nombre: 'ANTOFAGASTA',
        codigo: '021'
      },
      nombre: 'MEJILLONES',
      codigo: '02102'
    }, {
      provincia: {
        nombre: 'EL LOA',
        codigo: '022'
      },
      nombre: 'OLLAGÜE',
      codigo: '02202'
    }, {
      provincia: {
        nombre: 'EL LOA',
        codigo: '022'
      },
      nombre: 'SAN PEDRO DE ATACAMA',
      codigo: '02203'
    }, {
      provincia: {
        nombre: 'ANTOFAGASTA',
        codigo: '021'
      },
      nombre: 'SIERRA GORDA',
      codigo: '02103'
    }, {
      provincia: {
        nombre: 'ANTOFAGASTA',
        codigo: '021'
      },
      nombre: 'TALTAL',
      codigo: '02104'
    }, {
      provincia: {
        nombre: 'TOCOPILLA',
        codigo: '023'
      },
      nombre: 'TOCOPILLA',
      codigo: '02301'
    }],
    nombre: 'ANTOFAGASTA',
    codigo: '02'
  }, {
    id: '1',
    comunas: [{
      provincia: {
        nombre: 'ARICA',
        codigo: '151'
      },
      nombre: 'ARICA',
      codigo: '15101'
    }, {
      provincia: {
        nombre: 'ARICA',
        codigo: '151'
      },
      nombre: 'CAMARONES',
      codigo: '15102'
    }, {
      provincia: {
        nombre: 'PARINACOTA',
        codigo: '152'
      },
      nombre: 'GENERAL LAGOS',
      codigo: '15202'
    }, {
      provincia: {
        nombre: 'PARINACOTA',
        codigo: '152'
      },
      nombre: 'PUTRE',
      codigo: '15201'
    }],
    nombre: 'ARICA Y PARINACOTA',
    codigo: '15'
  }, {
    id: '2',
    comunas: [{
      provincia: {
        nombre: 'HUASCO',
        codigo: '033'
      },
      nombre: 'ALTO DEL CARMEN',
      codigo: '03302'
    }, {
      provincia: {
        nombre: 'COPIAPO',
        codigo: '031'
      },
      nombre: 'CALDERA',
      codigo: '03102'
    }, {
      provincia: {
        nombre: 'CHAÑARAL',
        codigo: '032'
      },
      nombre: 'CHAÑARAL',
      codigo: '03201'
    }, {
      provincia: {
        nombre: 'COPIAPO',
        codigo: '031'
      },
      nombre: 'COPIAPO',
      codigo: '03101'
    }, {
      provincia: {
        nombre: 'CHAÑARAL',
        codigo: '032'
      },
      nombre: 'DIEGO DE ALMAGRO',
      codigo: '03202'
    }, {
      provincia: {
        nombre: 'HUASCO',
        codigo: '033'
      },
      nombre: 'FREIRINA',
      codigo: '03303'
    }, {
      provincia: {
        nombre: 'HUASCO',
        codigo: '033'
      },
      nombre: 'HUASCO',
      codigo: '03304'
    }, {
      provincia: {
        nombre: 'COPIAPO',
        codigo: '031'
      },
      nombre: 'TIERRA AMARILLA',
      codigo: '03103'
    }, {
      provincia: {
        nombre: 'HUASCO',
        codigo: '033'
      },
      nombre: 'VALLENAR',
      codigo: '03301'
    }],
    nombre: 'ATACAMA',
    codigo: '03'
  }, {
    id: '3',
    comunas: [{
      provincia: {
        nombre: 'BIOBIO',
        codigo: '083'
      },
      nombre: 'ALTO BIOBIO',
      codigo: '08314'
    }, {
      provincia: {
        nombre: 'BIOBIO',
        codigo: '083'
      },
      nombre: 'ANTUCO',
      codigo: '08302'
    }, {
      provincia: {
        nombre: 'ARAUCO',
        codigo: '082'
      },
      nombre: 'ARAUCO',
      codigo: '08202'
    }, {
      provincia: {
        nombre: 'ÑUBLE',
        codigo: '084'
      },
      nombre: 'BULNES',
      codigo: '08402'
    }, {
      provincia: {
        nombre: 'BIOBIO',
        codigo: '083'
      },
      nombre: 'CABRERO',
      codigo: '08303'
    }, {
      provincia: {
        nombre: 'ARAUCO',
        codigo: '082'
      },
      nombre: 'CAÑETE',
      codigo: '08203'
    }, {
      provincia: {
        nombre: 'CONCEPCION',
        codigo: '081'
      },
      nombre: 'CHIGUAYANTE',
      codigo: '08103'
    }, {
      provincia: {
        nombre: 'ÑUBLE',
        codigo: '084'
      },
      nombre: 'CHILLAN',
      codigo: '08401'
    }, {
      provincia: {
        nombre: 'ÑUBLE',
        codigo: '084'
      },
      nombre: 'CHILLAN VIEJO',
      codigo: '08406'
    }, {
      provincia: {
        nombre: 'ÑUBLE',
        codigo: '084'
      },
      nombre: 'COBQUECURA',
      codigo: '08403'
    }, {
      provincia: {
        nombre: 'ÑUBLE',
        codigo: '084'
      },
      nombre: 'COELEMU',
      codigo: '08404'
    }, {
      provincia: {
        nombre: 'ÑUBLE',
        codigo: '084'
      },
      nombre: 'COIHUECO',
      codigo: '08405'
    }, {
      provincia: {
        nombre: 'CONCEPCION',
        codigo: '081'
      },
      nombre: 'CONCEPCION',
      codigo: '08101'
    }, {
      provincia: {
        nombre: 'ARAUCO',
        codigo: '082'
      },
      nombre: 'CONTULMO',
      codigo: '08204'
    }, {
      provincia: {
        nombre: 'CONCEPCION',
        codigo: '081'
      },
      nombre: 'CORONEL',
      codigo: '08102'
    }, {
      provincia: {
        nombre: 'ARAUCO',
        codigo: '082'
      },
      nombre: 'CURANILAHUE',
      codigo: '08205'
    }, {
      provincia: {
        nombre: 'ÑUBLE',
        codigo: '084'
      },
      nombre: 'EL CARMEN',
      codigo: '08407'
    }, {
      provincia: {
        nombre: 'CONCEPCION',
        codigo: '081'
      },
      nombre: 'FLORIDA',
      codigo: '08104'
    }, {
      provincia: {
        nombre: 'CONCEPCION',
        codigo: '081'
      },
      nombre: 'HUALPEN',
      codigo: '08112'
    }, {
      provincia: {
        nombre: 'CONCEPCION',
        codigo: '081'
      },
      nombre: 'HUALQUI',
      codigo: '08105'
    }, {
      provincia: {
        nombre: 'BIOBIO',
        codigo: '083'
      },
      nombre: 'LAJA',
      codigo: '08304'
    }, {
      provincia: {
        nombre: 'ARAUCO',
        codigo: '082'
      },
      nombre: 'LEBU',
      codigo: '08201'
    }, {
      provincia: {
        nombre: 'ARAUCO',
        codigo: '082'
      },
      nombre: 'LOS ALAMOS',
      codigo: '08206'
    }, {
      provincia: {
        nombre: 'BIOBIO',
        codigo: '083'
      },
      nombre: 'LOS ANGELES',
      codigo: '08301'
    }, {
      provincia: {
        nombre: 'CONCEPCION',
        codigo: '081'
      },
      nombre: 'LOTA',
      codigo: '08106'
    }, {
      provincia: {
        nombre: 'BIOBIO',
        codigo: '083'
      },
      nombre: 'MULCHEN',
      codigo: '08305'
    }, {
      provincia: {
        nombre: 'BIOBIO',
        codigo: '083'
      },
      nombre: 'NACIMIENTO',
      codigo: '08306'
    }, {
      provincia: {
        nombre: 'BIOBIO',
        codigo: '083'
      },
      nombre: 'NEGRETE',
      codigo: '08307'
    }, {
      provincia: {
        nombre: 'ÑUBLE',
        codigo: '084'
      },
      nombre: 'NINHUE',
      codigo: '08408'
    }, {
      provincia: {
        nombre: 'ÑUBLE',
        codigo: '084'
      },
      nombre: 'PEMUCO',
      codigo: '08410'
    }, {
      provincia: {
        nombre: 'CONCEPCION',
        codigo: '081'
      },
      nombre: 'PENCO',
      codigo: '08107'
    }, {
      provincia: {
        nombre: 'ÑUBLE',
        codigo: '084'
      },
      nombre: 'PINTO',
      codigo: '08411'
    }, {
      provincia: {
        nombre: 'ÑUBLE',
        codigo: '084'
      },
      nombre: 'PORTEZUELO',
      codigo: '08412'
    }, {
      provincia: {
        nombre: 'BIOBIO',
        codigo: '083'
      },
      nombre: 'QUILACO',
      codigo: '08308'
    }, {
      provincia: {
        nombre: 'BIOBIO',
        codigo: '083'
      },
      nombre: 'QUILLECO',
      codigo: '08309'
    }, {
      provincia: {
        nombre: 'ÑUBLE',
        codigo: '084'
      },
      nombre: 'QUILLON',
      codigo: '08413'
    }, {
      provincia: {
        nombre: 'ÑUBLE',
        codigo: '084'
      },
      nombre: 'QUIRIHUE',
      codigo: '08414'
    }, {
      provincia: {
        nombre: 'ÑUBLE',
        codigo: '084'
      },
      nombre: 'RANQUIL',
      codigo: '08415'
    }, {
      provincia: {
        nombre: 'ÑUBLE',
        codigo: '084'
      },
      nombre: 'SAN CARLOS',
      codigo: '08416'
    }, {
      provincia: {
        nombre: 'ÑUBLE',
        codigo: '084'
      },
      nombre: 'SAN FABIAN',
      codigo: '08417'
    }, {
      provincia: {
        nombre: 'ÑUBLE',
        codigo: '084'
      },
      nombre: 'SAN IGNACIO',
      codigo: '08418'
    }, {
      provincia: {
        nombre: 'ÑUBLE',
        codigo: '084'
      },
      nombre: 'SAN NICOLAS',
      codigo: '08419'
    }, {
      provincia: {
        nombre: 'CONCEPCION',
        codigo: '081'
      },
      nombre: 'SAN PEDRO DE LA PAZ',
      codigo: '08108'
    }, {
      provincia: {
        nombre: 'BIOBIO',
        codigo: '083'
      },
      nombre: 'SAN ROSENDO',
      codigo: '08310'
    }, {
      provincia: {
        nombre: 'BIOBIO',
        codigo: '083'
      },
      nombre: 'SANTA BARBARA',
      codigo: '08311'
    }, {
      provincia: {
        nombre: 'CONCEPCION',
        codigo: '081'
      },
      nombre: 'SANTA JUANA',
      codigo: '08109'
    }, {
      provincia: {
        nombre: 'CONCEPCION',
        codigo: '081'
      },
      nombre: 'TALCAHUANO',
      codigo: '08110'
    }, {
      provincia: {
        nombre: 'ARAUCO',
        codigo: '082'
      },
      nombre: 'TIRUA',
      codigo: '08207'
    }, {
      provincia: {
        nombre: 'CONCEPCION',
        codigo: '081'
      },
      nombre: 'TOME',
      codigo: '08111'
    }, {
      provincia: {
        nombre: 'ÑUBLE',
        codigo: '084'
      },
      nombre: 'TREGUACO',
      codigo: '08420'
    }, {
      provincia: {
        nombre: 'BIOBIO',
        codigo: '083'
      },
      nombre: 'TUCAPEL',
      codigo: '08312'
    }, {
      provincia: {
        nombre: 'BIOBIO',
        codigo: '083'
      },
      nombre: 'YUMBEL',
      codigo: '08313'
    }, {
      provincia: {
        nombre: 'ÑUBLE',
        codigo: '084'
      },
      nombre: 'YUNGAY',
      codigo: '08421'
    }, {
      provincia: {
        nombre: 'ÑUBLE',
        codigo: '084'
      },
      nombre: 'ÑIQUEN',
      codigo: '08409'
    }],
    nombre: 'BIOBIO',
    codigo: '08'
  }, {
    id: '4',
    comunas: [{
      provincia: {
        nombre: 'ELQUI',
        codigo: '041'
      },
      nombre: 'ANDACOLLO',
      codigo: '04103'
    }, {
      provincia: {
        nombre: 'CHOAPA',
        codigo: '042'
      },
      nombre: 'CANELA',
      codigo: '04202'
    }, {
      provincia: {
        nombre: 'LIMARI',
        codigo: '043'
      },
      nombre: 'COMBARBALA',
      codigo: '04302'
    }, {
      provincia: {
        nombre: 'ELQUI',
        codigo: '041'
      },
      nombre: 'COQUIMBO',
      codigo: '04102'
    }, {
      provincia: {
        nombre: 'CHOAPA',
        codigo: '042'
      },
      nombre: 'ILLAPEL',
      codigo: '04201'
    }, {
      provincia: {
        nombre: 'ELQUI',
        codigo: '041'
      },
      nombre: 'LA HIGUERA',
      codigo: '04104'
    }, {
      provincia: {
        nombre: 'ELQUI',
        codigo: '041'
      },
      nombre: 'LA SERENA',
      codigo: '04101'
    }, {
      provincia: {
        nombre: 'CHOAPA',
        codigo: '042'
      },
      nombre: 'LOS VILOS',
      codigo: '04203'
    }, {
      provincia: {
        nombre: 'LIMARI',
        codigo: '043'
      },
      nombre: 'MONTE PATRIA',
      codigo: '04303'
    }, {
      provincia: {
        nombre: 'LIMARI',
        codigo: '043'
      },
      nombre: 'OVALLE',
      codigo: '04301'
    }, {
      provincia: {
        nombre: 'ELQUI',
        codigo: '041'
      },
      nombre: 'PAIGUANO',
      codigo: '04105'
    }, {
      provincia: {
        nombre: 'LIMARI',
        codigo: '043'
      },
      nombre: 'PUNITAQUI',
      codigo: '04304'
    }, {
      provincia: {
        nombre: 'LIMARI',
        codigo: '043'
      },
      nombre: 'RIO HURTADO',
      codigo: '04305'
    }, {
      provincia: {
        nombre: 'CHOAPA',
        codigo: '042'
      },
      nombre: 'SALAMANCA',
      codigo: '04204'
    }, {
      provincia: {
        nombre: 'ELQUI',
        codigo: '041'
      },
      nombre: 'VICUÑA',
      codigo: '04106'
    }],
    nombre: 'COQUIMBO',
    codigo: '04'
  }, {
    id: '5',
    comunas: [{
      provincia: {
        nombre: 'COLCHAGUA',
        codigo: '063'
      },
      nombre: 'CHEPICA',
      codigo: '06302'
    }, {
      provincia: {
        nombre: 'COLCHAGUA',
        codigo: '063'
      },
      nombre: 'CHIMBARONGO',
      codigo: '06303'
    }, {
      provincia: {
        nombre: 'CARDENAL CARO',
        codigo: '062'
      },
      nombre: 'LA ESTRELLA',
      codigo: '06202'
    }, {
      provincia: {
        nombre: 'CARDENAL CARO',
        codigo: '062'
      },
      nombre: 'LITUECHE',
      codigo: '06203'
    }, {
      provincia: {
        nombre: 'COLCHAGUA',
        codigo: '063'
      },
      nombre: 'LOLOL',
      codigo: '06304'
    }, {
      provincia: {
        nombre: 'CARDENAL CARO',
        codigo: '062'
      },
      nombre: 'MARCHIHUE',
      codigo: '06204'
    }, {
      provincia: {
        nombre: 'COLCHAGUA',
        codigo: '063'
      },
      nombre: 'NANCAGUA',
      codigo: '06305'
    }, {
      provincia: {
        nombre: 'CARDENAL CARO',
        codigo: '062'
      },
      nombre: 'NAVIDAD',
      codigo: '06205'
    }, {
      provincia: {
        nombre: 'COLCHAGUA',
        codigo: '063'
      },
      nombre: 'PALMILLA',
      codigo: '06306'
    }, {
      provincia: {
        nombre: 'CARDENAL CARO',
        codigo: '062'
      },
      nombre: 'PAREDONES',
      codigo: '06206'
    }, {
      provincia: {
        nombre: 'COLCHAGUA',
        codigo: '063'
      },
      nombre: 'PERALILLO',
      codigo: '06307'
    }, {
      provincia: {
        nombre: 'CARDENAL CARO',
        codigo: '062'
      },
      nombre: 'PICHILEMU',
      codigo: '06201'
    }, {
      provincia: {
        nombre: 'COLCHAGUA',
        codigo: '063'
      },
      nombre: 'PLACILLA',
      codigo: '06308'
    }, {
      provincia: {
        nombre: 'COLCHAGUA',
        codigo: '063'
      },
      nombre: 'PUMANQUE',
      codigo: '06309'
    }, {
      provincia: {
        nombre: 'COLCHAGUA',
        codigo: '063'
      },
      nombre: 'SAN FERNANDO',
      codigo: '06301'
    }, {
      provincia: {
        nombre: 'COLCHAGUA',
        codigo: '063'
      },
      nombre: 'SANTA CRUZ',
      codigo: '06310'
    }],
    nombre: "GRAL. BERNARDO O'HIGGINS",
    codigo: '06'
  }, {
    id: '6',
    comunas: [{
      provincia: {
        nombre: 'AYSEN',
        codigo: '112'
      },
      nombre: 'AYSEN',
      codigo: '11201'
    }, {
      provincia: {
        nombre: 'GENERAL CARRERA',
        codigo: '114'
      },
      nombre: 'CHILE CHICO',
      codigo: '11401'
    }, {
      provincia: {
        nombre: 'AYSEN',
        codigo: '112'
      },
      nombre: 'CISNES',
      codigo: '11202'
    }, {
      provincia: {
        nombre: 'CAPITAN PRAT',
        codigo: '113'
      },
      nombre: 'COCHRANE',
      codigo: '11301'
    }, {
      provincia: {
        nombre: 'COYHAIQUE',
        codigo: '111'
      },
      nombre: 'COYHAIQUE',
      codigo: '11101'
    }, {
      provincia: {
        nombre: 'AYSEN',
        codigo: '112'
      },
      nombre: 'GUAITECAS',
      codigo: '11203'
    }, {
      provincia: {
        nombre: 'COYHAIQUE',
        codigo: '111'
      },
      nombre: 'LAGO VERDE',
      codigo: '11102'
    }, {
      provincia: {
        nombre: 'CAPITAN PRAT',
        codigo: '113'
      },
      nombre: 'O’HIGGINS',
      codigo: '11302'
    }, {
      provincia: {
        nombre: 'GENERAL CARRERA',
        codigo: '114'
      },
      nombre: 'RIO IBAÑEZ',
      codigo: '11402'
    }, {
      provincia: {
        nombre: 'CAPITAN PRAT',
        codigo: '113'
      },
      nombre: 'TORTEL',
      codigo: '11303'
    }],
    nombre: 'GRAL. CARLOS IBAÑEZ DEL CAMPO',
    codigo: '11'
  }, {
    id: '7',
    comunas: [{
      provincia: {
        nombre: 'MALLECO',
        codigo: '092'
      },
      nombre: 'ANGOL',
      codigo: '09201'
    }, {
      provincia: {
        nombre: 'CAUTIN',
        codigo: '091'
      },
      nombre: 'CARAHUE',
      codigo: '09102'
    }, {
      provincia: {
        nombre: 'CAUTIN',
        codigo: '091'
      },
      nombre: 'CHOLCHOL',
      codigo: '09121'
    }, {
      provincia: {
        nombre: 'MALLECO',
        codigo: '092'
      },
      nombre: 'COLLIPULLI',
      codigo: '09202'
    }, {
      provincia: {
        nombre: 'CAUTIN',
        codigo: '091'
      },
      nombre: 'CUNCO',
      codigo: '09103'
    }, {
      provincia: {
        nombre: 'MALLECO',
        codigo: '092'
      },
      nombre: 'CURACAUTIN',
      codigo: '09203'
    }, {
      provincia: {
        nombre: 'CAUTIN',
        codigo: '091'
      },
      nombre: 'CURARREHUE',
      codigo: '09104'
    }, {
      provincia: {
        nombre: 'MALLECO',
        codigo: '092'
      },
      nombre: 'ERCILLA',
      codigo: '09204'
    }, {
      provincia: {
        nombre: 'CAUTIN',
        codigo: '091'
      },
      nombre: 'FREIRE',
      codigo: '09105'
    }, {
      provincia: {
        nombre: 'CAUTIN',
        codigo: '091'
      },
      nombre: 'GALVARINO',
      codigo: '09106'
    }, {
      provincia: {
        nombre: 'CAUTIN',
        codigo: '091'
      },
      nombre: 'GORBEA',
      codigo: '09107'
    }, {
      provincia: {
        nombre: 'CAUTIN',
        codigo: '091'
      },
      nombre: 'LAUTARO',
      codigo: '09108'
    }, {
      provincia: {
        nombre: 'CAUTIN',
        codigo: '091'
      },
      nombre: 'LONCOCHE',
      codigo: '09109'
    }, {
      provincia: {
        nombre: 'MALLECO',
        codigo: '092'
      },
      nombre: 'LONQUIMAY',
      codigo: '09205'
    }, {
      provincia: {
        nombre: 'MALLECO',
        codigo: '092'
      },
      nombre: 'LOS SAUCES',
      codigo: '09206'
    }, {
      provincia: {
        nombre: 'MALLECO',
        codigo: '092'
      },
      nombre: 'LUMACO',
      codigo: '09207'
    }, {
      provincia: {
        nombre: 'CAUTIN',
        codigo: '091'
      },
      nombre: 'MELIPEUCO',
      codigo: '09110'
    }, {
      provincia: {
        nombre: 'CAUTIN',
        codigo: '091'
      },
      nombre: 'NUEVA IMPERIAL',
      codigo: '09111'
    }, {
      provincia: {
        nombre: 'CAUTIN',
        codigo: '091'
      },
      nombre: 'PADRE LAS CASAS',
      codigo: '09112'
    }, {
      provincia: {
        nombre: 'CAUTIN',
        codigo: '091'
      },
      nombre: 'PERQUENCO',
      codigo: '09113'
    }, {
      provincia: {
        nombre: 'CAUTIN',
        codigo: '091'
      },
      nombre: 'PITRUFQUEN',
      codigo: '09114'
    }, {
      provincia: {
        nombre: 'CAUTIN',
        codigo: '091'
      },
      nombre: 'PUCON',
      codigo: '09115'
    }, {
      provincia: {
        nombre: 'MALLECO',
        codigo: '092'
      },
      nombre: 'PUREN',
      codigo: '09208'
    }, {
      provincia: {
        nombre: 'MALLECO',
        codigo: '092'
      },
      nombre: 'RENAICO',
      codigo: '09209'
    }, {
      provincia: {
        nombre: 'CAUTIN',
        codigo: '091'
      },
      nombre: 'SAAVEDRA',
      codigo: '09116'
    }, {
      provincia: {
        nombre: 'CAUTIN',
        codigo: '091'
      },
      nombre: 'TEMUCO',
      codigo: '09101'
    }, {
      provincia: {
        nombre: 'MALLECO',
        codigo: '092'
      },
      nombre: 'TEMUCO',
      codigo: '09101'
    }, {
      provincia: {
        nombre: 'CAUTIN',
        codigo: '091'
      },
      nombre: 'TEODORO SCHMIDT',
      codigo: '09117'
    }, {
      provincia: {
        nombre: 'CAUTIN',
        codigo: '091'
      },
      nombre: 'TOLTEN',
      codigo: '09118'
    }, {
      provincia: {
        nombre: 'MALLECO',
        codigo: '092'
      },
      nombre: 'TRAIGUEN',
      codigo: '09210'
    }, {
      provincia: {
        nombre: 'MALLECO',
        codigo: '092'
      },
      nombre: 'VICTORIA',
      codigo: '09211'
    }, {
      provincia: {
        nombre: 'CAUTIN',
        codigo: '091'
      },
      nombre: 'VILCUN',
      codigo: '09119'
    }, {
      provincia: {
        nombre: 'CAUTIN',
        codigo: '091'
      },
      nombre: 'VILLARRICA',
      codigo: '09120'
    }],
    nombre: 'LA ARAUCANIA',
    codigo: '09'
  }, {
    id: '8',
    comunas: [{
      provincia: {
        nombre: 'CHILOE',
        codigo: '102'
      },
      nombre: 'ANCUD',
      codigo: '10202'
    }, {
      provincia: {
        nombre: 'LLANQUIHUE',
        codigo: '101'
      },
      nombre: 'CALBUCO',
      codigo: '10102'
    }, {
      provincia: {
        nombre: 'CHILOE',
        codigo: '102'
      },
      nombre: 'CASTRO',
      codigo: '10201'
    }, {
      provincia: {
        nombre: 'PALENA',
        codigo: '104'
      },
      nombre: 'CHAITEN',
      codigo: '10401'
    }, {
      provincia: {
        nombre: 'CHILOE',
        codigo: '102'
      },
      nombre: 'CHONCHI',
      codigo: '10203'
    }, {
      provincia: {
        nombre: 'LLANQUIHUE',
        codigo: '101'
      },
      nombre: 'COCHAMO',
      codigo: '10103'
    }, {
      provincia: {
        nombre: 'CHILOE',
        codigo: '102'
      },
      nombre: 'CURACO DE VELEZ',
      codigo: '10204'
    }, {
      provincia: {
        nombre: 'CHILOE',
        codigo: '102'
      },
      nombre: 'DALCAHUE',
      codigo: '10205'
    }, {
      provincia: {
        nombre: 'LLANQUIHUE',
        codigo: '101'
      },
      nombre: 'FRESIA',
      codigo: '10104'
    }, {
      provincia: {
        nombre: 'LLANQUIHUE',
        codigo: '101'
      },
      nombre: 'FRUTILLAR',
      codigo: '10105'
    }, {
      provincia: {
        nombre: 'PALENA',
        codigo: '104'
      },
      nombre: 'FUTALEUFU',
      codigo: '10402'
    }, {
      provincia: {
        nombre: 'PALENA',
        codigo: '104'
      },
      nombre: 'HUALAIHUE',
      codigo: '10403'
    }, {
      provincia: {
        nombre: 'LLANQUIHUE',
        codigo: '101'
      },
      nombre: 'LLANQUIHUE',
      codigo: '10107'
    }, {
      provincia: {
        nombre: 'LLANQUIHUE',
        codigo: '101'
      },
      nombre: 'LOS MUERMOS',
      codigo: '10106'
    }, {
      provincia: {
        nombre: 'LLANQUIHUE',
        codigo: '101'
      },
      nombre: 'MAULLIN',
      codigo: '10108'
    }, {
      provincia: {
        nombre: 'OSORNO',
        codigo: '103'
      },
      nombre: 'OSORNO',
      codigo: '10301'
    }, {
      provincia: {
        nombre: 'PALENA',
        codigo: '104'
      },
      nombre: 'PALENA',
      codigo: '10404'
    }, {
      provincia: {
        nombre: 'LLANQUIHUE',
        codigo: '101'
      },
      nombre: 'PUERTO MONTT',
      codigo: '10101'
    }, {
      provincia: {
        nombre: 'OSORNO',
        codigo: '103'
      },
      nombre: 'PUERTO OCTAY',
      codigo: '10302'
    }, {
      provincia: {
        nombre: 'LLANQUIHUE',
        codigo: '101'
      },
      nombre: 'PUERTO VARAS',
      codigo: '10109'
    }, {
      provincia: {
        nombre: 'CHILOE',
        codigo: '102'
      },
      nombre: 'PUQUELDON',
      codigo: '10206'
    }, {
      provincia: {
        nombre: 'OSORNO',
        codigo: '103'
      },
      nombre: 'PURRANQUE',
      codigo: '10303'
    }, {
      provincia: {
        nombre: 'OSORNO',
        codigo: '103'
      },
      nombre: 'PUYEHUE',
      codigo: '10304'
    }, {
      provincia: {
        nombre: 'CHILOE',
        codigo: '102'
      },
      nombre: 'QUEILEN',
      codigo: '10207'
    }, {
      provincia: {
        nombre: 'CHILOE',
        codigo: '102'
      },
      nombre: 'QUELLON',
      codigo: '10208'
    }, {
      provincia: {
        nombre: 'CHILOE',
        codigo: '102'
      },
      nombre: 'QUEMCHI',
      codigo: '10209'
    }, {
      provincia: {
        nombre: 'CHILOE',
        codigo: '102'
      },
      nombre: 'QUINCHAO',
      codigo: '10210'
    }, {
      provincia: {
        nombre: 'OSORNO',
        codigo: '103'
      },
      nombre: 'RIO NEGRO',
      codigo: '10305'
    }, {
      provincia: {
        nombre: 'OSORNO',
        codigo: '103'
      },
      nombre: 'SAN JUAN DE LA COSTA',
      codigo: '10306'
    }, {
      provincia: {
        nombre: 'OSORNO',
        codigo: '103'
      },
      nombre: 'SAN PABLO',
      codigo: '10307'
    }],
    nombre: 'LOS LAGOS',
    codigo: '10'
  }, {
    id: '9',
    comunas: [{
      provincia: {
        nombre: 'VALDIVIA',
        codigo: '141'
      },
      nombre: 'CORRAL',
      codigo: '14102'
    }, {
      provincia: {
        nombre: 'RANCO',
        codigo: '142'
      },
      nombre: 'FUTRONO',
      codigo: '14202'
    }, {
      provincia: {
        nombre: 'RANCO',
        codigo: '142'
      },
      nombre: 'LA UNION',
      codigo: '14201'
    }, {
      provincia: {
        nombre: 'RANCO',
        codigo: '142'
      },
      nombre: 'LAGO RANCO',
      codigo: '14203'
    }, {
      provincia: {
        nombre: 'VALDIVIA',
        codigo: '141'
      },
      nombre: 'LANCO',
      codigo: '14103'
    }, {
      provincia: {
        nombre: 'VALDIVIA',
        codigo: '141'
      },
      nombre: 'LOS LAGOS',
      codigo: '14104'
    }, {
      provincia: {
        nombre: 'VALDIVIA',
        codigo: '141'
      },
      nombre: 'MAFIL',
      codigo: '14105'
    }, {
      provincia: {
        nombre: 'VALDIVIA',
        codigo: '141'
      },
      nombre: 'MARIQUINA',
      codigo: '14106'
    }, {
      provincia: {
        nombre: 'VALDIVIA',
        codigo: '141'
      },
      nombre: 'PAILLACO',
      codigo: '14107'
    }, {
      provincia: {
        nombre: 'VALDIVIA',
        codigo: '141'
      },
      nombre: 'PANGUIPULLI',
      codigo: '14108'
    }, {
      provincia: {
        nombre: 'RANCO',
        codigo: '142'
      },
      nombre: 'RIO BUENO',
      codigo: '14204'
    }, {
      provincia: {
        nombre: 'VALDIVIA',
        codigo: '141'
      },
      nombre: 'VALDIVIA',
      codigo: '14101'
    }],
    nombre: 'LOS RIOS',
    codigo: '14'
  }, {
    id: '10',
    comunas: [{
      provincia: {
        nombre: 'ANTARTICA CHILENA',
        codigo: '122'
      },
      nombre: 'ANTARTICA',
      codigo: '12202'
    }, {
      provincia: {
        nombre: 'ANTARTICA CHILENA',
        codigo: '122'
      },
      nombre: 'CABO DE HORNOS (EX - NAVARINO)',
      codigo: '12201'
    }, {
      provincia: {
        nombre: 'MAGALLANES',
        codigo: '121'
      },
      nombre: 'LAGUNA BLANCA',
      codigo: '12102'
    }, {
      provincia: {
        nombre: 'ULTIMA ESPERANZA',
        codigo: '124'
      },
      nombre: 'NATALES',
      codigo: '12401'
    }, {
      provincia: {
        nombre: 'TIERRA DEL FUEGO',
        codigo: '123'
      },
      nombre: 'PORVENIR',
      codigo: '12301'
    }, {
      provincia: {
        nombre: 'TIERRA DEL FUEGO',
        codigo: '123'
      },
      nombre: 'PRIMAVERA',
      codigo: '12302'
    }, {
      provincia: {
        nombre: 'MAGALLANES',
        codigo: '121'
      },
      nombre: 'PUNTA ARENAS',
      codigo: '12101'
    }, {
      provincia: {
        nombre: 'MAGALLANES',
        codigo: '121'
      },
      nombre: 'RIO VERDE',
      codigo: '12103'
    }, {
      provincia: {
        nombre: 'MAGALLANES',
        codigo: '121'
      },
      nombre: 'SAN GREGORIO',
      codigo: '12104'
    }, {
      provincia: {
        nombre: 'TIERRA DEL FUEGO',
        codigo: '123'
      },
      nombre: 'TIMAUKEL',
      codigo: '12303'
    }, {
      provincia: {
        nombre: 'ULTIMA ESPERANZA',
        codigo: '124'
      },
      nombre: 'TORRES DEL PAINE',
      codigo: '12402'
    }],
    nombre: 'MAGALLANES Y ANTARTICA CHILENA',
    codigo: '12'
  }, {
    id: '11',
    comunas: [{
      provincia: {
        nombre: 'CAUQUENES',
        codigo: '072'
      },
      nombre: 'CAUQUENES',
      codigo: '07201'
    }, {
      provincia: {
        nombre: 'CAUQUENES',
        codigo: '072'
      },
      nombre: 'CHANCO',
      codigo: '07202'
    }, {
      provincia: {
        nombre: 'LINARES',
        codigo: '074'
      },
      nombre: 'COLBUN',
      codigo: '07402'
    }, {
      provincia: {
        nombre: 'TALCA',
        codigo: '071'
      },
      nombre: 'CONSTITUCION',
      codigo: '07102'
    }, {
      provincia: {
        nombre: 'TALCA',
        codigo: '071'
      },
      nombre: 'CUREPTO',
      codigo: '07103'
    }, {
      provincia: {
        nombre: 'CURICO',
        codigo: '073'
      },
      nombre: 'CURICO',
      codigo: '07301'
    }, {
      provincia: {
        nombre: 'TALCA',
        codigo: '071'
      },
      nombre: 'EMPEDRADO',
      codigo: '07104'
    }, {
      provincia: {
        nombre: 'CURICO',
        codigo: '073'
      },
      nombre: 'HUALAÑE',
      codigo: '07302'
    }, {
      provincia: {
        nombre: 'CURICO',
        codigo: '073'
      },
      nombre: 'LICANTEN',
      codigo: '07303'
    }, {
      provincia: {
        nombre: 'LINARES',
        codigo: '074'
      },
      nombre: 'LINARES',
      codigo: '07401'
    }, {
      provincia: {
        nombre: 'LINARES',
        codigo: '074'
      },
      nombre: 'LONGAVI',
      codigo: '07403'
    }, {
      provincia: {
        nombre: 'TALCA',
        codigo: '071'
      },
      nombre: 'MAULE',
      codigo: '07105'
    }, {
      provincia: {
        nombre: 'CURICO',
        codigo: '073'
      },
      nombre: 'MOLINA',
      codigo: '07304'
    }, {
      provincia: {
        nombre: 'LINARES',
        codigo: '074'
      },
      nombre: 'PARRAL',
      codigo: '07404'
    }, {
      provincia: {
        nombre: 'TALCA',
        codigo: '071'
      },
      nombre: 'PELARCO',
      codigo: '07106'
    }, {
      provincia: {
        nombre: 'CAUQUENES',
        codigo: '072'
      },
      nombre: 'PELLUHUE',
      codigo: '07203'
    }, {
      provincia: {
        nombre: 'TALCA',
        codigo: '071'
      },
      nombre: 'PENCAHUE',
      codigo: '07107'
    }, {
      provincia: {
        nombre: 'CURICO',
        codigo: '073'
      },
      nombre: 'RAUCO',
      codigo: '07305'
    }, {
      provincia: {
        nombre: 'LINARES',
        codigo: '074'
      },
      nombre: 'RETIRO',
      codigo: '07405'
    }, {
      provincia: {
        nombre: 'TALCA',
        codigo: '071'
      },
      nombre: 'RIO CLARO',
      codigo: '07108'
    }, {
      provincia: {
        nombre: 'CURICO',
        codigo: '073'
      },
      nombre: 'ROMERAL',
      codigo: '07306'
    }, {
      provincia: {
        nombre: 'CURICO',
        codigo: '073'
      },
      nombre: 'SAGRADA FAMILIA',
      codigo: '07307'
    }, {
      provincia: {
        nombre: 'TALCA',
        codigo: '071'
      },
      nombre: 'SAN CLEMENTE',
      codigo: '07109'
    }, {
      provincia: {
        nombre: 'LINARES',
        codigo: '074'
      },
      nombre: 'SAN JAVIER',
      codigo: '07406'
    }, {
      provincia: {
        nombre: 'TALCA',
        codigo: '071'
      },
      nombre: 'SAN RAFAEL',
      codigo: '07110'
    }, {
      provincia: {
        nombre: 'TALCA',
        codigo: '071'
      },
      nombre: 'TALCA',
      codigo: '07101'
    }, {
      provincia: {
        nombre: 'CURICO',
        codigo: '073'
      },
      nombre: 'TENO',
      codigo: '07308'
    }, {
      provincia: {
        nombre: 'CURICO',
        codigo: '073'
      },
      nombre: 'VICHUQUEN',
      codigo: '07309'
    }, {
      provincia: {
        nombre: 'LINARES',
        codigo: '074'
      },
      nombre: 'VILLA ALEGRE',
      codigo: '07407'
    }, {
      provincia: {
        nombre: 'LINARES',
        codigo: '074'
      },
      nombre: 'YERBAS BUENAS',
      codigo: '07408'
    }],
    nombre: 'MAULE',
    codigo: '07'
  }, {
    id: '12',
    comunas: [{
      provincia: {
        nombre: 'MELIPILLA',
        codigo: '135'
      },
      nombre: 'ALHUE',
      codigo: '13502'
    }, {
      provincia: {
        nombre: 'MAIPO',
        codigo: '134'
      },
      nombre: 'BUIN',
      codigo: '13402'
    }, {
      provincia: {
        nombre: 'MAIPO',
        codigo: '134'
      },
      nombre: 'CALERA DE TANGO',
      codigo: '13403'
    }, {
      provincia: {
        nombre: 'SANTIAGO',
        codigo: '131'
      },
      nombre: 'CERRILLOS',
      codigo: '13102'
    }, {
      provincia: {
        nombre: 'SANTIAGO',
        codigo: '131'
      },
      nombre: 'CERRO NAVIA',
      codigo: '13103'
    }, {
      provincia: {
        nombre: 'CHACABUCO',
        codigo: '133'
      },
      nombre: 'COLINA',
      codigo: '13301'
    }, {
      provincia: {
        nombre: 'SANTIAGO',
        codigo: '131'
      },
      nombre: 'CONCHALI',
      codigo: '13104'
    }, {
      provincia: {
        nombre: 'MELIPILLA',
        codigo: '135'
      },
      nombre: 'CURACAVI',
      codigo: '13503'
    }, {
      provincia: {
        nombre: 'SANTIAGO',
        codigo: '131'
      },
      nombre: 'EL BOSQUE',
      codigo: '13105'
    }, {
      provincia: {
        nombre: 'TALAGANTE',
        codigo: '136'
      },
      nombre: 'EL MONTE',
      codigo: '13602'
    }, {
      provincia: {
        nombre: 'SANTIAGO',
        codigo: '131'
      },
      nombre: 'ESTACION CENTRAL',
      codigo: '13106'
    }, {
      provincia: {
        nombre: 'SANTIAGO',
        codigo: '131'
      },
      nombre: 'HUECHURABA',
      codigo: '13107'
    }, {
      provincia: {
        nombre: 'SANTIAGO',
        codigo: '131'
      },
      nombre: 'INDEPENDENCIA',
      codigo: '13108'
    }, {
      provincia: {
        nombre: 'TALAGANTE',
        codigo: '136'
      },
      nombre: 'ISLA DE MAIPO',
      codigo: '13603'
    }, {
      provincia: {
        nombre: 'SANTIAGO',
        codigo: '131'
      },
      nombre: 'LA CISTERNA',
      codigo: '13109'
    }, {
      provincia: {
        nombre: 'SANTIAGO',
        codigo: '131'
      },
      nombre: 'LA FLORIDA',
      codigo: '13110'
    }, {
      provincia: {
        nombre: 'SANTIAGO',
        codigo: '131'
      },
      nombre: 'LA GRANJA',
      codigo: '13111'
    }, {
      provincia: {
        nombre: 'SANTIAGO',
        codigo: '131'
      },
      nombre: 'LA PINTANA',
      codigo: '13112'
    }, {
      provincia: {
        nombre: 'SANTIAGO',
        codigo: '131'
      },
      nombre: 'LA REINA',
      codigo: '13113'
    }, {
      provincia: {
        nombre: 'CHACABUCO',
        codigo: '133'
      },
      nombre: 'LAMPA',
      codigo: '13302'
    }, {
      provincia: {
        nombre: 'SANTIAGO',
        codigo: '131'
      },
      nombre: 'LAS CONDES',
      codigo: '13114'
    }, {
      provincia: {
        nombre: 'SANTIAGO',
        codigo: '131'
      },
      nombre: 'LO BARNECHEA',
      codigo: '13115'
    }, {
      provincia: {
        nombre: 'SANTIAGO',
        codigo: '131'
      },
      nombre: 'LO ESPEJO',
      codigo: '13116'
    }, {
      provincia: {
        nombre: 'SANTIAGO',
        codigo: '131'
      },
      nombre: 'LO PRADO',
      codigo: '13117'
    }, {
      provincia: {
        nombre: 'SANTIAGO',
        codigo: '131'
      },
      nombre: 'MACUL',
      codigo: '13118'
    }, {
      provincia: {
        nombre: 'SANTIAGO',
        codigo: '131'
      },
      nombre: 'MAIPU',
      codigo: '13119'
    }, {
      provincia: {
        nombre: 'MELIPILLA',
        codigo: '135'
      },
      nombre: 'MARIA PINTO',
      codigo: '13504'
    }, {
      provincia: {
        nombre: 'MELIPILLA',
        codigo: '135'
      },
      nombre: 'MELIPILLA',
      codigo: '13501'
    }, {
      provincia: {
        nombre: 'TALAGANTE',
        codigo: '136'
      },
      nombre: 'PADRE HURTADO',
      codigo: '13604'
    }, {
      provincia: {
        nombre: 'MAIPO',
        codigo: '134'
      },
      nombre: 'PAINE',
      codigo: '13404'
    }, {
      provincia: {
        nombre: 'SANTIAGO',
        codigo: '131'
      },
      nombre: 'PEDRO AGUIRRE CERDA',
      codigo: '13121'
    }, {
      provincia: {
        nombre: 'TALAGANTE',
        codigo: '136'
      },
      nombre: 'PEÑAFLOR',
      codigo: '13605'
    }, {
      provincia: {
        nombre: 'SANTIAGO',
        codigo: '131'
      },
      nombre: 'PEÑALOLEN',
      codigo: '13122'
    }, {
      provincia: {
        nombre: 'CORDILLERA',
        codigo: '132'
      },
      nombre: 'PIRQUE',
      codigo: '13202'
    }, {
      provincia: {
        nombre: 'SANTIAGO',
        codigo: '131'
      },
      nombre: 'PROVIDENCIA',
      codigo: '13123'
    }, {
      provincia: {
        nombre: 'SANTIAGO',
        codigo: '131'
      },
      nombre: 'PUDAHUEL',
      codigo: '13124'
    }, {
      provincia: {
        nombre: 'CORDILLERA',
        codigo: '132'
      },
      nombre: 'PUENTE ALTO',
      codigo: '13201'
    }, {
      provincia: {
        nombre: 'SANTIAGO',
        codigo: '131'
      },
      nombre: 'QUILICURA',
      codigo: '13125'
    }, {
      provincia: {
        nombre: 'SANTIAGO',
        codigo: '131'
      },
      nombre: 'QUINTA NORMAL',
      codigo: '13126'
    }, {
      provincia: {
        nombre: 'SANTIAGO',
        codigo: '131'
      },
      nombre: 'RECOLETA',
      codigo: '13127'
    }, {
      provincia: {
        nombre: 'SANTIAGO',
        codigo: '131'
      },
      nombre: 'RENCA',
      codigo: '13128'
    }, {
      provincia: {
        nombre: 'MAIPO',
        codigo: '134'
      },
      nombre: 'SAN BERNARDO',
      codigo: '13401'
    }, {
      provincia: {
        nombre: 'SANTIAGO',
        codigo: '131'
      },
      nombre: 'SAN JOAQUIN',
      codigo: '13129'
    }, {
      provincia: {
        nombre: 'CORDILLERA',
        codigo: '132'
      },
      nombre: 'SAN JOSE DE MAIPO',
      codigo: '13203'
    }, {
      provincia: {
        nombre: 'SANTIAGO',
        codigo: '131'
      },
      nombre: 'SAN MIGUEL',
      codigo: '13130'
    }, {
      provincia: {
        nombre: 'MELIPILLA',
        codigo: '135'
      },
      nombre: 'SAN PEDRO',
      codigo: '13505'
    }, {
      provincia: {
        nombre: 'SANTIAGO',
        codigo: '131'
      },
      nombre: 'SAN RAMON',
      codigo: '13131'
    }, {
      provincia: {
        nombre: 'SANTIAGO',
        codigo: '131'
      },
      nombre: 'SANTIAGO',
      codigo: '13101'
    }, {
      provincia: {
        nombre: 'TALAGANTE',
        codigo: '136'
      },
      nombre: 'TALAGANTE',
      codigo: '13601'
    }, {
      provincia: {
        nombre: 'CHACABUCO',
        codigo: '133'
      },
      nombre: 'TILTIL',
      codigo: '13303'
    }, {
      provincia: {
        nombre: 'SANTIAGO',
        codigo: '131'
      },
      nombre: 'VITACURA',
      codigo: '13132'
    }, {
      provincia: {
        nombre: 'SANTIAGO',
        codigo: '131'
      },
      nombre: 'ÑUÑOA',
      codigo: '13120'
    }],
    nombre: 'METROPOLITANA DE SANTIAGO',
    codigo: '13'
  }, {
    id: '13',
    comunas: [{
      provincia: {
        nombre: 'IQUIQUE',
        codigo: '011'
      },
      nombre: 'ALTO HOSPICIO',
      codigo: '01107'
    }, {
      provincia: {
        nombre: 'TAMARUGAL',
        codigo: '014'
      },
      nombre: 'CAMIÑA',
      codigo: '01402'
    }, {
      provincia: {
        nombre: 'TAMARUGAL',
        codigo: '014'
      },
      nombre: 'COLCHANE',
      codigo: '01403'
    }, {
      provincia: {
        nombre: 'TAMARUGAL',
        codigo: '014'
      },
      nombre: 'HUARA',
      codigo: '01404'
    }, {
      provincia: {
        nombre: 'IQUIQUE',
        codigo: '011'
      },
      nombre: 'IQUIQUE',
      codigo: '01101'
    }, {
      provincia: {
        nombre: 'TAMARUGAL',
        codigo: '014'
      },
      nombre: 'PICA',
      codigo: '01405'
    }, {
      provincia: {
        nombre: 'TAMARUGAL',
        codigo: '014'
      },
      nombre: 'POZO ALMONTE',
      codigo: '01401'
    }],
    nombre: 'TARAPACA',
    codigo: '01'
  }, {
    id: '14',
    comunas: [{
      provincia: {
        nombre: 'SAN ANTONIO',
        codigo: '056'
      },
      nombre: 'ALGARROBO',
      codigo: '05602'
    }, {
      provincia: {
        nombre: 'PETORCA',
        codigo: '054'
      },
      nombre: 'CABILDO',
      codigo: '05402'
    }, {
      provincia: {
        nombre: 'QUILLOTA',
        codigo: '055'
      },
      nombre: 'CALERA',
      codigo: '05502'
    }, {
      provincia: {
        nombre: 'LOS ANDES',
        codigo: '053'
      },
      nombre: 'CALLE LARGA',
      codigo: '05302'
    }, {
      provincia: {
        nombre: 'SAN ANTONIO',
        codigo: '056'
      },
      nombre: 'CARTAGENA',
      codigo: '05603'
    }, {
      provincia: {
        nombre: 'VALPARAISO',
        codigo: '051'
      },
      nombre: 'CASABLANCA',
      codigo: '05102'
    }, {
      provincia: {
        nombre: 'SAN FELIPE DE ACONCAGUA',
        codigo: '057'
      },
      nombre: 'CATEMU',
      codigo: '05702'
    }, {
      provincia: {
        nombre: 'VALPARAISO',
        codigo: '051'
      },
      nombre: 'CONCON',
      codigo: '05103'
    }, {
      provincia: {
        nombre: 'SAN ANTONIO',
        codigo: '056'
      },
      nombre: 'EL QUISCO',
      codigo: '05604'
    }, {
      provincia: {
        nombre: 'SAN ANTONIO',
        codigo: '056'
      },
      nombre: 'EL TABO',
      codigo: '05605'
    }, {
      provincia: {
        nombre: 'QUILLOTA',
        codigo: '055'
      },
      nombre: 'HIJUELAS',
      codigo: '05503'
    }, {
      provincia: {
        nombre: 'ISLA DE PASCUA',
        codigo: '052'
      },
      nombre: 'ISLA DE PASCUA',
      codigo: '05201'
    }, {
      provincia: {
        nombre: 'VALPARAISO',
        codigo: '051'
      },
      nombre: 'JUAN FERNANDEZ',
      codigo: '05104'
    }, {
      provincia: {
        nombre: 'QUILLOTA',
        codigo: '055'
      },
      nombre: 'LA CRUZ',
      codigo: '05504'
    }, {
      provincia: {
        nombre: 'PETORCA',
        codigo: '054'
      },
      nombre: 'LA LIGUA',
      codigo: '05401'
    }, {
      provincia: {
        nombre: 'MARGA MARGA',
        codigo: '058'
      },
      nombre: 'LIMACHE',
      codigo: '05802'
    }, {
      provincia: {
        nombre: 'SAN FELIPE DE ACONCAGUA',
        codigo: '057'
      },
      nombre: 'LLAILLAY',
      codigo: '05703'
    }, {
      provincia: {
        nombre: 'LOS ANDES',
        codigo: '053'
      },
      nombre: 'LOS ANDES',
      codigo: '05301'
    }, {
      provincia: {
        nombre: 'QUILLOTA',
        codigo: '055'
      },
      nombre: 'NOGALES',
      codigo: '05506'
    }, {
      provincia: {
        nombre: 'MARGA MARGA',
        codigo: '058'
      },
      nombre: 'OLMUE',
      codigo: '05803'
    }, {
      provincia: {
        nombre: 'SAN FELIPE DE ACONCAGUA',
        codigo: '057'
      },
      nombre: 'PANQUEHUE',
      codigo: '05704'
    }, {
      provincia: {
        nombre: 'PETORCA',
        codigo: '054'
      },
      nombre: 'PAPUDO',
      codigo: '05403'
    }, {
      provincia: {
        nombre: 'PETORCA',
        codigo: '054'
      },
      nombre: 'PETORCA',
      codigo: '05404'
    }, {
      provincia: {
        nombre: 'VALPARAISO',
        codigo: '051'
      },
      nombre: 'PUCHUNCAVI',
      codigo: '05105'
    }, {
      provincia: {
        nombre: 'SAN FELIPE DE ACONCAGUA',
        codigo: '057'
      },
      nombre: 'PUTAENDO',
      codigo: '05705'
    }, {
      provincia: {
        nombre: 'QUILLOTA',
        codigo: '055'
      },
      nombre: 'QUILLOTA',
      codigo: '05501'
    }, {
      provincia: {
        nombre: 'MARGA MARGA',
        codigo: '058'
      },
      nombre: 'QUILPUE',
      codigo: '05801'
    }, {
      provincia: {
        nombre: 'VALPARAISO',
        codigo: '051'
      },
      nombre: 'QUINTERO',
      codigo: '05107'
    }, {
      provincia: {
        nombre: 'LOS ANDES',
        codigo: '053'
      },
      nombre: 'RINCONADA',
      codigo: '05303'
    }, {
      provincia: {
        nombre: 'SAN ANTONIO',
        codigo: '056'
      },
      nombre: 'SAN ANTONIO',
      codigo: '05601'
    }, {
      provincia: {
        nombre: 'LOS ANDES',
        codigo: '053'
      },
      nombre: 'SAN ESTEBAN',
      codigo: '05304'
    }, {
      provincia: {
        nombre: 'SAN FELIPE DE ACONCAGUA',
        codigo: '057'
      },
      nombre: 'SAN FELIPE',
      codigo: '05701'
    }, {
      provincia: {
        nombre: 'SAN FELIPE DE ACONCAGUA',
        codigo: '057'
      },
      nombre: 'SANTA MARIA',
      codigo: '05706'
    }, {
      provincia: {
        nombre: 'SAN ANTONIO',
        codigo: '056'
      },
      nombre: 'SANTO DOMINGO',
      codigo: '05606'
    }, {
      provincia: {
        nombre: 'VALPARAISO',
        codigo: '051'
      },
      nombre: 'VALPARAISO',
      codigo: '05101'
    }, {
      provincia: {
        nombre: 'MARGA MARGA',
        codigo: '058'
      },
      nombre: 'VILLA ALEMANA',
      codigo: '05804'
    }, {
      provincia: {
        nombre: 'VALPARAISO',
        codigo: '051'
      },
      nombre: 'VIÑA DEL MAR',
      codigo: '05109'
    }, {
      provincia: {
        nombre: 'PETORCA',
        codigo: '054'
      },
      nombre: 'ZAPALLAR',
      codigo: '05405'
    }],
    nombre: 'VALPARAISO',
    codigo: '05'
  }]
};
export default regions;