import { GET_LANDING_DATA, getLandingDataSuccess, getLandingDataFail, GET_LANDING_DATA_SUCCESS, setLandingData } from './Landings.actions';
import { apiRequest } from '@cencosud/puntos-core/src/features/api/api.actions';
import contentEndpoints from '@cencosud/puntos-core/src/features/api/endpoints/contentEndpoints';
export var getLandingFlujo = function getLandingFlujo(_ref) {
  var dispatch = _ref.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_LANDING_DATA) {
        return nextResponse;
      }
      var link = action.payload.link;
      return dispatch(apiRequest(contentEndpoints.landingPages.get(link), getLandingDataSuccess, getLandingDataFail));
    };
  };
};
export var getProcesarLandingData = function getProcesarLandingData(_ref2) {
  var dispatch = _ref2.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      if (type !== GET_LANDING_DATA_SUCCESS) {
        return nextResponse;
      }
      return dispatch(setLandingData(payload.payload));
    };
  };
};
export default [getLandingFlujo, getProcesarLandingData];