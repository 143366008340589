import { namespace } from '@cencosud/puntos-core/src/features/usuario/usuario.actions';
export var GET_ADICIONALES_PENDIENTES = "".concat(namespace, " obtener adicionales P. de T&C");
export var GET_ADICIONALES_PENDIENTES_SUCCESS = "".concat(GET_ADICIONALES_PENDIENTES, ": success");
export var GET_ADICIONALES_PENDIENTES_FAIL = "".concat(GET_ADICIONALES_PENDIENTES, ": fail");
export var SET_ADICIONALES_PENDIENTES = "".concat(namespace, " set adicionales P. de T&C");
export var obtenerAdicionalesPendientes = function obtenerAdicionalesPendientes() {
  return {
    type: GET_ADICIONALES_PENDIENTES
  };
};
export var obtenerAdicionalesPendientesSuccess = function obtenerAdicionalesPendientesSuccess(adicionales) {
  return {
    type: GET_ADICIONALES_PENDIENTES_SUCCESS,
    payload: adicionales
  };
};
export var obtenerAdicionalesPendientesFail = function obtenerAdicionalesPendientesFail(cause) {
  return {
    type: GET_ADICIONALES_PENDIENTES_FAIL,
    payload: cause
  };
};
export var setAdicionalesPendientes = function setAdicionalesPendientes(pendientes) {
  return {
    type: SET_ADICIONALES_PENDIENTES,
    payload: pendientes
  };
};