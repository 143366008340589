import { apiRequest } from '../api/api.actions';
import { contentEndpoints } from '../api/endpoints';
import { GET_FEATURE_TOGGLES, GET_FEATURE_TOGGLES_SUCCESS, getFeatureTogglesSuccess, getFeatureTogglesFail, setFeatureToggles } from './featureToggles.actions';
import normalize from './normalizeFeatureToggles';
export var getFeatureToggles = function getFeatureToggles(_ref) {
  var dispatch = _ref.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type === GET_FEATURE_TOGGLES) {
        return dispatch(apiRequest(contentEndpoints.featureToggles.get(), getFeatureTogglesSuccess, getFeatureTogglesFail));
      }
      return nextResponse;
    };
  };
};
export var processFeatureToggles = function processFeatureToggles(_ref2) {
  var dispatch = _ref2.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      if (type !== GET_FEATURE_TOGGLES_SUCCESS) {
        return nextResponse;
      }
      return dispatch(setFeatureToggles(normalize(payload.featureToggle)));
    };
  };
};
export default [getFeatureToggles, processFeatureToggles];