import _defineProperty from "/home/jenkins/agent/workspace/UCTION_FRONT_deploy_front_master/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import isString from 'lodash/isString';
import withAnalyticsOnPress from './withAnalyticsOnPress';
import { createButtonPressEvent } from './eventCreators';

/**
 * @param {GoogleTagEvent} event
 * @return {function(React.Component): WithAnalyticsOnPress}
 */
export default (function (event) {
  return function (Component) {
    return withAnalyticsOnPress(createButtonPressEvent(event), function (event, _ref) {
      var text = _ref.text,
        lowerCase = _ref.lowerCase;
      return _objectSpread({
        name: isString(text) ? lowerCase ? text === null || text === void 0 ? void 0 : text.toLowerCase() : text : undefined
      }, event);
    })(Component);
  };
});