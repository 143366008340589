import { apiRequest } from '../api/api.actions';
import { contentV3Endpoints } from '../api/endpoints';
import { GET_SEGURIDAD_DIGITAL, getSeguridadDigitalSuccess, getSeguridadDigitalFail, GET_SEGURIDAD_DIGITAL_SUCCESS, setSeguridadDigital } from './seguridadDigital.actions';
export var getSeguridadDigitalFlow = function getSeguridadDigitalFlow(_ref) {
  var dispatch = _ref.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_SEGURIDAD_DIGITAL) {
        return nextResponse;
      }
      return dispatch(apiRequest(contentV3Endpoints.landingDigitalSecurity.get(), getSeguridadDigitalSuccess, getSeguridadDigitalFail));
    };
  };
};
export var processGetSeguridadDigital = function processGetSeguridadDigital(_ref2) {
  var dispatch = _ref2.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      if (type !== GET_SEGURIDAD_DIGITAL_SUCCESS) {
        return nextResponse;
      }
      return dispatch(setSeguridadDigital(payload.payload));
    };
  };
};
export default [getSeguridadDigitalFlow, processGetSeguridadDigital];