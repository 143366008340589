var namespace = '[producto]';
export var GET_PRODUCTO_DETALLE = "".concat(namespace, " get producto");
export var GET_PRODUCTO_DETALLE_SUCCESS = "".concat(GET_PRODUCTO_DETALLE, ": success");
export var GET_PRODUCTO_DETALLE_FAIL = "".concat(GET_PRODUCTO_DETALLE, ": fail");
export var SET_PRODUCTO_DETALLE = "".concat(namespace, " set producto");
export var CHECK_COPEC = "".concat(namespace, " check copec");
export var CHECK_COPEC_SUCCESS = "".concat(CHECK_COPEC, " success");
export var CHECK_COPEC_FAIL = "".concat(CHECK_COPEC, " fail");
export var SET_COPEC = "".concat(CHECK_COPEC, ": set copec");
export var getProductoDetalle = function getProductoDetalle(id) {
  return {
    type: GET_PRODUCTO_DETALLE,
    payload: {
      id: id
    }
  };
};
export var getProductoDetalleSuccess = function getProductoDetalleSuccess(PRODUCTO_DETALLE) {
  return {
    type: GET_PRODUCTO_DETALLE_SUCCESS,
    payload: PRODUCTO_DETALLE
  };
};
export var getProductoDetalleFail = function getProductoDetalleFail(cause) {
  return {
    type: GET_PRODUCTO_DETALLE_FAIL,
    payload: cause
  };
};
export var setProductoDetalle = function setProductoDetalle(product) {
  return {
    type: SET_PRODUCTO_DETALLE,
    payload: product
  };
};
export var checkCopec = function checkCopec() {
  return {
    type: CHECK_COPEC
  };
};
export var checkCopecSuccess = function checkCopecSuccess(authorized) {
  return {
    type: CHECK_COPEC_SUCCESS,
    payload: authorized
  };
};
export var checkCopecFail = function checkCopecFail(cause) {
  return {
    type: CHECK_COPEC_FAIL,
    payload: cause
  };
};
export var setCopecIdentity = function setCopecIdentity(identified) {
  return {
    type: SET_COPEC,
    payload: identified
  };
};