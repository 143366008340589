// COLORES PARA PUNTOS CENCOSUD

var colores = {
  violetaCorporativo: '#733387',
  violetaCorporativo2: '#743387',
  violetaCorporativoClaro: '#873695',
  grisTexto: '#686868',
  grisClaro: '#ced4da',
  grisClaro2: '#ebebeb',
  verde: '#00a650',
  grisDivisor: '#ababab',
  naranja: '#f78d2a',
  rosa: '#f1c6ff',
  negro: '#212529'
};
export default (function () {
  return colores;
});