import _defineProperty from "/home/jenkins/agent/workspace/UCTION_FRONT_deploy_front_master/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { HIDE_NOTIFIER, SHOW_NOTIFIER, HIDE_PROGRESS_BAR, SHOW_PROGRESS_BAR, SHOW_PRIMARY_PROGRESS, HIDE_PRIMARY_PROGRESS, SHOW_SECONDARY_PROGRESS, HIDE_SECONDARY_PROGRESS, SHOW_FULLSCREEN_PROGRESS, HIDE_FULLSCREEN_PROGRESS, SHOW_TOAST_NOTIFIER } from './ui.actions';
// prettier-ignore
export var notifierReducer = function notifierReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _ref = arguments.length > 1 ? arguments[1] : undefined,
    type = _ref.type,
    payload = _ref.payload;
  // NOSONAR
  switch (type) {
    case SHOW_NOTIFIER:
      {
        var message = payload.message;
        return _objectSpread(_objectSpread({}, state), {}, {
          message: message
        });
      }
    case HIDE_NOTIFIER:
      return _objectSpread(_objectSpread({}, state), {}, {
        message: null
      });
    default:
      return state;
  }
};
// prettier-ignore
export var progressBarReducer = function progressBarReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _ref2 = arguments.length > 1 ? arguments[1] : undefined,
    type = _ref2.type;
  // NOSONAR
  switch (type) {
    case SHOW_PROGRESS_BAR:
      return _objectSpread(_objectSpread({}, state), {}, {
        active: true
      });
    case HIDE_PROGRESS_BAR:
      return _objectSpread(_objectSpread({}, state), {}, {
        active: false
      });
    default:
      return state;
  }
};
// prettier-ignore
export var primaryProgressReducer = function primaryProgressReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  var _ref3 = arguments.length > 1 ? arguments[1] : undefined,
    type = _ref3.type;
  // NOSONAR
  switch (type) {
    case SHOW_PRIMARY_PROGRESS:
      return true;
    case HIDE_PRIMARY_PROGRESS:
      return false;
    default:
      return state;
  }
};
export var toastReducer = function toastReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  var _ref4 = arguments.length > 1 ? arguments[1] : undefined,
    type = _ref4.type;
  switch (type) {
    case SHOW_TOAST_NOTIFIER:
      return true;
    default:
      return state;
  }
};
// prettier-ignore
export var secondaryProgressReducer = function secondaryProgressReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  var _ref5 = arguments.length > 1 ? arguments[1] : undefined,
    type = _ref5.type;
  // NOSONAR
  switch (type) {
    case SHOW_SECONDARY_PROGRESS:
      return true;
    case HIDE_SECONDARY_PROGRESS:
      return false;
    default:
      return state;
  }
};
// prettier-ignore
export var fullscreenProgressReducer = function fullscreenProgressReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  var _ref6 = arguments.length > 1 ? arguments[1] : undefined,
    type = _ref6.type;
  // NOSONAR
  switch (type) {
    case SHOW_FULLSCREEN_PROGRESS:
      return true;
    case HIDE_FULLSCREEN_PROGRESS:
      return false;
    default:
      return state;
  }
};