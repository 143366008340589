// TODO extraer a módulo común para acciones de contraseña
var namespace = '[contrasena]';
export var CAMBIAR = "".concat(namespace, " cambiar contrase\xF1a");
export var CAMBIAR_SUCCESS = "".concat(CAMBIAR, ": success");
export var CAMBIAR_FAIL = "".concat(CAMBIAR, ": fail");
export function cambiar(actual, nueva) {
  return {
    type: CAMBIAR,
    payload: {
      actual: actual,
      nueva: nueva
    }
  };
}
export function cambiarSuccess() {
  return {
    type: CAMBIAR_SUCCESS
  };
}
export function cambiarFail(cause) {
  return {
    type: CAMBIAR_FAIL,
    payload: cause
  };
}