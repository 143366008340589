import isString from 'lodash/isString';
import isInteger from 'lodash/isInteger';
import parseInt from 'lodash/parseInt';
import isNil from 'lodash/isNil';
import { rutFormat } from './rut-functions';
export var eliminarCaracteresEspeciales = function eliminarCaracteresEspeciales(rut) {
  return isNil(rut) ? null : rut.toString().replace(/[-.\s]/g, '');
};
export var separar = function separar(rutCompleto) {
  var crearRut = function crearRut(numero, dv) {
    return {
      numero: numero,
      dv: dv
    };
  };
  if (!rutCompleto || !isString(rutCompleto)) {
    return crearRut();
  }
  if (!rutCompleto.match(/-\d|k/gi)) {
    return crearRut();
  }
  // Eliminar puntos y guión
  var rutFormateado = eliminarCaracteresEspeciales(rutCompleto);

  // Aislar rut y dígito verificador
  var rut = rutFormateado.slice(0, -1);
  var dv = rutFormateado.slice(-1);
  var dvMaybeNumber = parseInt(dv);
  if (isInteger(dvMaybeNumber)) {
    dv = dvMaybeNumber;
  }
  return crearRut(rut, dv);
};
export var formatear = function formatear(text) {
  return text.length < 2 ? '' : rutFormat(text);
};
var clean = function clean(rut) {
  return rut.replace(/^0+|[^0-9kK]+/g, '').toUpperCase();
};
export var valido = function valido(rut) {
  if (!rut || rut.length < 2) {
    return false;
  }
  var rutaux = clean(rut);
  var n = rutaux.slice(0, -1);
  var t = parseInt(n, 10);
  var m = 0;
  var s = 1;
  while (t > 0) {
    // eslint-disable-next-line no-plusplus
    s = (s + t % 10 * (9 - m++ % 6)) % 11;
    t = Math.floor(t / 10);
  }
  var v = s > 0 ? "".concat(s - 1) : 'K';
  return v === rutaux.slice(-1);
};