var namespace = '[buscador]';
export var GET_SEARCHER = "".concat(namespace, " get searcher");
export var GET_SEARCHER_SUCCESS = "".concat(GET_SEARCHER, ": success");
export var GET_SEARCHER_FAIL = "".concat(GET_SEARCHER, ": fail");
export var SET_SEARCHER = "".concat(namespace, " set Searcher");
export var GET_APPEND_SEARCHER = "".concat(namespace, " get searcher append");
export var APPEND_SEARCHER_SUCCESS = "".concat(namespace, " searcher append: success");
export var APPEND_SEARCHER_FAIL = "".concat(namespace, " searcher append: fail");
export var GET_APPEND_CATALOGO = "".concat(namespace, " append catalogo");
export var APPEND_CATALOGO_SUCCESS = "".concat(GET_APPEND_CATALOGO, ": success");
export var APPEND_CATALOGO_FAIL = "".concat(GET_APPEND_CATALOGO, ": fail");
export var APPEND_CATALOGO = "".concat(namespace, " set append catalogo");
export var getSearcher = function getSearcher(page, size, producto) {
  return {
    type: GET_SEARCHER,
    payload: {
      page: page,
      size: size,
      producto: producto
    }
  };
};
export var getSearcherSuccess = function getSearcherSuccess(busqueda) {
  return {
    type: GET_SEARCHER_SUCCESS,
    payload: busqueda
  };
};
export var getSearcherFail = function getSearcherFail(cause) {
  return {
    type: GET_SEARCHER_FAIL,
    payload: cause
  };
};
export var setSearcher = function setSearcher(productos, totalResultados) {
  return {
    type: SET_SEARCHER,
    payload: {
      productos: productos,
      totalResultados: totalResultados
    }
  };
};
export var getSearcherAppend = function getSearcherAppend(page, size, producto) {
  return {
    type: GET_APPEND_SEARCHER,
    payload: {
      page: page,
      size: size,
      producto: producto
    }
  };
};
export var getSearcherAppendSuccess = function getSearcherAppendSuccess(busqueda) {
  return {
    type: APPEND_SEARCHER_SUCCESS,
    payload: busqueda
  };
};
export var getSearcherAppendFail = function getSearcherAppendFail(cause) {
  return {
    type: APPEND_SEARCHER_FAIL,
    payload: cause
  };
};
export var getAppendCatalogo = function getAppendCatalogo(page, size, producto) {
  return {
    type: GET_APPEND_CATALOGO,
    payload: {
      page: page,
      size: size,
      producto: producto
    }
  };
};
export var appendCatalogoSuccess = function appendCatalogoSuccess(productos) {
  return {
    type: APPEND_CATALOGO_SUCCESS,
    payload: productos
  };
};
export var appendCatalogoFail = function appendCatalogoFail(productos) {
  return {
    type: APPEND_CATALOGO_FAIL,
    payload: productos
  };
};
export var appendCatalogo = function appendCatalogo(productos) {
  return {
    type: APPEND_CATALOGO,
    payload: productos
  };
};