import _defineProperty from "/home/jenkins/agent/workspace/UCTION_FRONT_deploy_front_master/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import * as Yup from 'yup';
import { rut, email, password, passwordConfirmation, nombre, apellidoPaterno, apellidoMaterno, emailConfirmation, referidoValidation } from '@cencosud/puntos-core/src/features/common/form/validaciones';
export default (function (isCaptchaActive, isFirstStage) {
  return function (values) {
    var schema = Yup.object().shape(_objectSpread(_objectSpread({
      rut: rut,
      numeroSerie: Yup.string().test('numeroSerie', 'Por favor, verifica el Nº de documento', function (serie) {
        return serie.match(/^[a-zA-Z]\d.*/) ? serie.match(/^[a-zA-Z]{1}\d{9}$/) : serie.match(/^\d{9}$/);
      }).required('Por favor, ingresa el Nº de documento'),
      email: email,
      emailConfirmation: emailConfirmation(values.email),
      telefono: Yup.string().matches(/^[3-9].*$/, 'Por favor, verifica Número Celular.').nullable(true).required('Por favor, ingresa Número Celular.')
    }, isCaptchaActive && {
      captcha: Yup.string().required('Por favor, recuerda validar el captcha.')
    }), {}, {
      token: Yup.string().nullable()
    }));
    if (!isFirstStage) {
      var secondStage = Yup.object().shape({
        password: password,
        passwordConfirmation: passwordConfirmation(values && values.password),
        fechaNacimiento: Yup.string().required('Por favor, ingresa Fecha de Nacimiento.'),
        aceptaCondiciones: Yup.boolean().test('aceptaCondiciones', 'Debes aceptar los términos y condiciones para inscribirte.', function (value) {
          return value === true;
        }),
        nombre: nombre,
        apellidoPaterno: apellidoPaterno,
        apellidoMaterno: apellidoMaterno,
        referido: referidoValidation(values.rut)
      });
      schema = schema.concat(secondStage);
    }
    return schema;
  };
});