import isObject from 'lodash/isObject';
export default (function (vouchers) {
  var vouchersList = vouchers.payload.voucherList.map(function (voucher) {
    if (!isObject(voucher)) {
      return {};
    }
    return {
      producto: voucher.producto,
      codigo: voucher.codigoVoucher,
      estado: voucher.estado,
      expiracion: voucher.fechaExpiracion
    };
  });
  var response = {
    vouchersList: vouchersList,
    numero: vouchers.payload.count
  };
  return response;
});