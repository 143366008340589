import _defineProperty from "/home/jenkins/agent/workspace/UCTION_FRONT_deploy_front_master/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
export default function validate(getValidationSchema) {
  return function (values) {
    var validationSchema = getValidationSchema(values);
    try {
      validationSchema.validateSync(values, {
        abortEarly: false
      });
      return {};
    } catch (error) {
      return getErrorsFromValidationError(error);
    }
  };
}
function getErrorsFromValidationError(validationError) {
  var FIRST_ERROR = 0;
  return validationError.inner.reduce(function (errors, error) {
    return _objectSpread(_objectSpread({}, errors), {}, _defineProperty({}, error.path, error.errors[FIRST_ERROR]));
  }, {});
}