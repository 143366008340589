var namespace = '[categoriesV3]';
export var GET_CATEGORIAS_NAVBAR = "".concat(namespace, " get categorias navbar");
export var GET_CATEGORIAS_NAVBAR_SUCCESS = "".concat(GET_CATEGORIAS_NAVBAR, ": success navbar");
export var GET_CATEGORIAS_NAVBAR_FAIL = "".concat(GET_CATEGORIAS_NAVBAR, ": fail navbar");
export var SET_CATEGORIAS_NAVBAR = "".concat(namespace, " set categorias navbar");
export var getCategoriasNavBar = function getCategoriasNavBar() {
  return {
    type: GET_CATEGORIAS_NAVBAR
  };
};
export var getCategoriasNavBarSuccess = function getCategoriasNavBarSuccess(categorias) {
  return {
    type: GET_CATEGORIAS_NAVBAR_SUCCESS,
    payload: {
      categorias: categorias
    }
  };
};
export var getCategoriasNavBarFail = function getCategoriasNavBarFail(cause) {
  return {
    type: GET_CATEGORIAS_NAVBAR_FAIL,
    payload: cause
  };
};
export var setCategoriasNavBar = function setCategoriasNavBar(categorias) {
  return {
    type: SET_CATEGORIAS_NAVBAR,
    payload: categorias
  };
};