export default (function (usuario) {
  if (Object.keys(usuario).length > 0) {
    var firmaTycIds = usuario.firmaTycIds,
      firmaTYC = usuario.firmaTYC;
    if (firmaTYC === false && firmaTycIds === 'Y') {
      return true;
    }
    if (firmaTYC === false && firmaTycIds === undefined) {
      return true;
    }
    if (firmaTYC === false && firmaTycIds === null) {
      return true;
    }
    if (firmaTYC === true && firmaTycIds === 'N') {
      return true;
    }
    return firmaTYC === false && firmaTycIds === 'N';
  }
  return false;
});