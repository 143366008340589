/**
 * toLocaleDefaultString - funciona igual que toLocaleString() revisar
 * documentacion https://developer.mozilla.org/es/docs/Web/JavaScript/Referencia/Objetos_globales/Number/toLocaleString
 * @param {number} numero
 * @returns {string}  retorna una cadena que contiene una representación del número acorde al idioma de `${locale}`
 */

var formatNumber = function formatNumber(nStr) {
  nStr = nStr.replace(',', '');
  nStr = nStr.replace('.', '');
  nStr += '';
  var x = nStr.split('.');
  var x1 = x[0];
  var x2 = x.length > 1 ? x[1] : '';
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1.$2');
  }
  return x1 + x2;
};
export default (function (number) {
  return number && formatNumber(number.toString());
});