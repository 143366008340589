import _defineProperty from "/home/jenkins/agent/workspace/UCTION_FRONT_deploy_front_master/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "/home/jenkins/agent/workspace/UCTION_FRONT_deploy_front_master/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["onSuccess"],
  _excluded2 = ["onSuccess", "onCancel"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var namespace = '[ui]';
export var SHOW_ALERT = "".concat(namespace, " show alert");
export var SHOW_CONFIRM = "".concat(namespace, " show confirm");
export var HIDE_ALERT = "".concat(namespace, " hide alert");
export function showAlert(config) {
  var onSuccess = config.onSuccess,
    sweetAlertConfig = _objectWithoutProperties(config, _excluded);
  return {
    type: SHOW_ALERT,
    payload: _objectSpread(_objectSpread({}, sweetAlertConfig), {}, {
      onSuccess: onSuccess
    })
  };
}
export function hideAlert() {
  return {
    type: HIDE_ALERT
  };
}
export var showConfirm = function showConfirm(config) {
  var onSuccess = config.onSuccess,
    onCancel = config.onCancel,
    sweetAlertConfig = _objectWithoutProperties(config, _excluded2);
  return {
    type: SHOW_CONFIRM,
    payload: _objectSpread(_objectSpread({
      buttons: true
    }, sweetAlertConfig), {}, {
      onSuccess: onSuccess,
      onCancel: onCancel
    })
  };
};