import { namespace } from '../../usuario/usuario.actions';
export var GET_DASHBOARD_CUENTA = "".concat(namespace, " get dashboard");
export var GET_DASHBOARD_CUENTA_SUCCESS = "".concat(GET_DASHBOARD_CUENTA, ": success");
export var GET_DASHBOARD_CUENTA_FAIL = "".concat(GET_DASHBOARD_CUENTA, ": fail");
export var SET_DASHBOARD_CUENTA = "".concat(namespace, " set dashboard ");
export var getDashboardCuenta = function getDashboardCuenta() {
  return {
    type: GET_DASHBOARD_CUENTA
  };
};
export var getDashboardCuentaSuccess = function getDashboardCuentaSuccess(estado) {
  return {
    type: GET_DASHBOARD_CUENTA_SUCCESS,
    payload: estado
  };
};
export var getDashboardCuentaFail = function getDashboardCuentaFail(cause) {
  return {
    type: GET_DASHBOARD_CUENTA_FAIL,
    payload: cause
  };
};
export var setDashboardCuenta = function setDashboardCuenta(dashboard) {
  return {
    type: SET_DASHBOARD_CUENTA,
    payload: dashboard
  };
};