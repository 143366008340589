import bannerVideoBinder from '@cencosud/puntos-core/src/features/binder/BannerVideoBinder';
import bannerBinder from '@cencosud/puntos-core/src/features/binder/BannerBinder';
import isObject from 'lodash/isObject';
var bannerOffersBinder = function bannerOffersBinder(bannerList) {
  return bannerList.map(function (banner) {
    if ((banner === null || banner === void 0 ? void 0 : banner.contentType) === 'banner') return bannerBinder(banner);
    if ((banner === null || banner === void 0 ? void 0 : banner.contentType) === 'clubBanner') return bannerVideoBinder(banner);
    return {};
  });
};
export default (function (home) {
  return isObject(home) ? {
    bannerSuperior: home !== null && home !== void 0 && home.topBanner ? bannerBinder(home === null || home === void 0 ? void 0 : home.topBanner) : {},
    bannersOfertas: home !== null && home !== void 0 && home.offersBanner ? bannerOffersBinder(home === null || home === void 0 ? void 0 : home.offersBanner) : [],
    videoBanner: home !== null && home !== void 0 && home.videoBanner ? bannerVideoBinder(home === null || home === void 0 ? void 0 : home.videoBanner) : {}
  } : {};
});