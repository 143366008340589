import { hideNotifier, showNotifier, hideSecondaryProgressBar, showSecondaryProgress } from '@cencosud/puntos-core/src/features/ui/ui.actions';
import { apiRequest } from '@cencosud/puntos-core/src/features/api/api.actions';
import { formatear } from '@cencosud/puntos-core/src/features/common/rut';
import processErrorMessage from '@cencosud/puntos-core/src/features/api/processErrorMessage';
import registerEvent from '@cencosud/puntos-web/src/analytics/registerEvent';
import { createFailedFormEvent, createSuccessfulFormEvent } from '@cencosud/puntos-web/src/analytics/eventCreators';
import eventTypes from '@cencosud/puntos-web/src/analytics/eventTypes';
import moment from 'moment';
import { loyaltyEndpoints } from '../../api/endpoints';
import { INTENTO_REGISTRO, REGISTRO_FALLIDO, REGISTRO_EXITOSO, INTENTO_EQUIFAX, EQUIFAX_EXITOSO, EQUIFAX_FALLIDO, registroFallido, registroExitoso, equifaxExitoso, equifaxFallido } from './registro.actions';
export var flujoEquifax = function flujoEquifax(_ref) {
  var dispatch = _ref.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== INTENTO_EQUIFAX) {
        return nextResponse;
      }
      var _action$payload = action.payload,
        equifax = _action$payload.equifax,
        nextStep = _action$payload.nextStep,
        fillForm = _action$payload.fillForm,
        setFormErrors = _action$payload.setFormErrors;
      return Promise.all([dispatch(showSecondaryProgress()), dispatch(apiRequest(loyaltyEndpoints.usuario.checkEquifax(), function (response) {
        return equifaxExitoso(response, nextStep, fillForm);
      }, function (cause) {
        return equifaxFallido(cause, setFormErrors);
      }, {
        body: {
          runSocio: formatear(equifax.rut),
          numDocSocio: equifax.numDoc,
          email: equifax.email,
          phone: equifax.phone,
          gRecaptchaResponse: equifax.captcha,
          localEnrolment: equifax.campaign
        }
      }))]);
    };
  };
};
export var procesarEquifax = function procesarEquifax(_ref2) {
  var dispatch = _ref2.dispatch,
    getState = _ref2.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      var getCmsTexts = function getCmsTexts() {
        return getState().ui.parameterize;
      };
      switch (type) {
        case EQUIFAX_EXITOSO:
          registerEvent(createSuccessfulFormEvent({
            category: eventTypes.categories.usuarioNuevo,
            name: 'Paso 1 - Inscribirme: OK'
          }));
          var response = payload.response,
            nextStep = payload.nextStep,
            fillForm = payload.fillForm;
          nextStep();
          fillForm({
            apellidoPaterno: response.payload.apellidoPaterno || '',
            apellidoMaterno: response.payload.apellidoMaterno || '',
            nombre: response.payload.nombres || '',
            fechaNacimiento: response.payload.fechaNacimiento ? moment(response.payload.fechaNacimiento).format('DD/MM/YYYY') : undefined,
            token: response.payload.token,
            meta: {
              habilitarCamposExtranjero: !response.payload.apellidoPaterno || !response.payload.apellidoMaterno || !response.payload.fechaNacimiento
            },
            sexo: response.payload.sexo,
            estadoCivil: response.payload.estadoCivil
          });
          return Promise.all([dispatch(hideSecondaryProgressBar()), dispatch(hideNotifier())]);
        case EQUIFAX_FALLIDO:
          var error = payload;
          registerEvent(createFailedFormEvent({
            category: eventTypes.categories.usuarioNuevo,
            name: 'Paso 1 - Inscribirme: fallo',
            error: error
          }));
          var notNotifier = ['INSV2-004', 'INSV2-005', 'INSV2-007', 'INSV2-008', 'INSV2-013'];
          return Promise.all([dispatch(hideSecondaryProgressBar()), !notNotifier.includes(error.code) && dispatch(showNotifier(processErrorMessage(error, getCmsTexts())))]);
        default:
          return nextResponse;
      }
    };
  };
};
export var flujoRegistro = function flujoRegistro(_ref3) {
  var dispatch = _ref3.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== INTENTO_REGISTRO) {
        return nextResponse;
      }
      var _action$payload2 = action.payload,
        registro = _action$payload2.registro,
        showSuccess = _action$payload2.showSuccess,
        showErrors = _action$payload2.showErrors;
      return Promise.all([dispatch(showSecondaryProgress()), dispatch(apiRequest(loyaltyEndpoints.usuario.inscribir2Pasos(), function (response) {
        return registroExitoso(response, showSuccess, showErrors);
      }, registroFallido, {
        body: {
          password: registro.password,
          nombres: registro.nombres,
          apellidoMaterno: registro.apellidoMaterno,
          apellidoPaterno: registro.apellidoPaterno,
          fechaNacimiento: moment(registro.fechaNacimiento, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          rut: formatear(registro.rut),
          tyc: registro.aceptaCondiciones,
          sexo: registro.sexo,
          estadoCivil: registro.estadoCivil,
          referido: registro.referido
        },
        headers: {
          Authorization: "Bearer ".concat(registro.token)
        }
      }))]);
    };
  };
};
export var procesarRegistro = function procesarRegistro(_ref4) {
  var dispatch = _ref4.dispatch,
    getState = _ref4.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      var getCmsTexts = function getCmsTexts() {
        return getState().ui.parameterize;
      };
      switch (type) {
        case REGISTRO_FALLIDO:
          {
            var error = payload;
            registerEvent(createFailedFormEvent({
              category: eventTypes.categories.usuarioNuevo,
              name: 'Paso 2 - Inscribirme: fallo',
              error: error
            }));
            var notNotifier = ['INSV2-007'];
            return Promise.all([dispatch(hideSecondaryProgressBar()), !notNotifier.includes(error.code) && dispatch(showNotifier(processErrorMessage(error, getCmsTexts()))), error.isUiMessage && dispatch(showNotifier(error.message))]);
          }
        case REGISTRO_EXITOSO:
          {
            var showSuccess = payload.showSuccess,
              showErrors = payload.showErrors;
            registerEvent(createSuccessfulFormEvent({
              category: eventTypes.categories.usuarioNuevo,
              name: 'Paso 2 - Inscribirme: OK - Final'
            }));
            var response = payload.response;
            var isError = ['INSV2-007'];
            if (!isError.includes(response.payload.rspCode)) {
              showSuccess();
            } else {
              showErrors({
                code: response.payload.rspCode
              });
            }
            return Promise.all([dispatch(hideSecondaryProgressBar()), dispatch(hideNotifier())]);
          }
        default:
          return nextResponse;
      }
    };
  };
};
export default [flujoEquifax, procesarEquifax, flujoRegistro, procesarRegistro];