import isObject from 'lodash/isObject';
export default (function (carousel) {
  var _carousel$bannerImage, _carousel$bannerImage2, _carousel$webImage, _carousel$mobileImage;
  return isObject(carousel) ? {
    id: carousel.id,
    altText: null,
    landing: null,
    name: carousel.name,
    subtype: carousel.contentType,
    nombreClub: carousel.clubName,
    titulo: carousel.name,
    video: carousel.video,
    descripcion: null,
    puntosExtra1: carousel.extraPoints1,
    medioPago: carousel.paymentMethod,
    medioPago2: null,
    puntosExtra2: carousel.extraPoints2,
    tarjetaCencosud: carousel.cencosudCard ? 'SI' : 'NO',
    nombreBoton: carousel.buttonName,
    colorBoton: carousel.buttonColor,
    legal1: carousel.legal1,
    legal2: carousel.legal2,
    productos: null,
    linkBoton: carousel.buttonLink,
    imagenBanner: (_carousel$bannerImage = carousel.bannerImage) === null || _carousel$bannerImage === void 0 ? void 0 : _carousel$bannerImage.url,
    imagenBanner2: (_carousel$bannerImage2 = carousel.bannerImage2) === null || _carousel$bannerImage2 === void 0 ? void 0 : _carousel$bannerImage2.url,
    textoSuperior: carousel.topText,
    bajadaSuperior: carousel.topDescent,
    bajadaPrincipal: carousel.mainDescent,
    externalInternalLink: carousel.externalLink,
    targetLink: carousel.targetLink,
    urlImagen: (_carousel$webImage = carousel.webImage) === null || _carousel$webImage === void 0 ? void 0 : _carousel$webImage.url,
    urlImagenMobile: (_carousel$mobileImage = carousel.mobileImage) === null || _carousel$mobileImage === void 0 ? void 0 : _carousel$mobileImage.url,
    link: null
  } : {};
});