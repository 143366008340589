import { apiRequest } from '@cencosud/puntos-core/src/features/api/api.actions';
import history from '@cencosud/puntos-core/src/features/router/history';
import AuthenticationError from '@cencosud/puntos-core/src/features/auth/AuthenticationError';
import { tiposProducto } from '../tiposProducto';
import { contentV3Endpoints } from '../../api/endpoints';
import { GET_PRODUCTO_DETALLE, GET_PRODUCTO_DETALLE_FAIL, GET_PRODUCTO_DETALLE_SUCCESS, setProductoDetalle, getProductoDetalleFail, getProductoDetalleSuccess, CHECK_COPEC, checkCopecSuccess, checkCopecFail, CHECK_COPEC_SUCCESS, CHECK_COPEC_FAIL, setCopecIdentity } from './productoDetalle.actions';
import loyaltyEndpoints from '../../api/endpoints/loyaltyEndpoints';
import eventTypes from '@cencosud/puntos-web/src/analytics/eventTypes';
import registerEvent from '@cencosud/puntos-web/src/analytics/registerEvent';
import productOnlineBinder from "@cencosud/puntos-core/src/features/binder/ProductOnlineBinder";
export var getProductoFlow = function getProductoFlow(_ref) {
  var dispatch = _ref.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_PRODUCTO_DETALLE) {
        return nextResponse;
      }
      return dispatch(apiRequest(contentV3Endpoints.productos.getDetalles(action.payload.id), getProductoDetalleSuccess, getProductoDetalleFail));
    };
  };
};
export var processGetProducto = function processGetProducto(_ref2) {
  var dispatch = _ref2.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      switch (type) {
        case GET_PRODUCTO_DETALLE_SUCCESS:
          {
            var producto = payload.payload;
            return dispatch(setProductoDetalle(productOnlineBinder(producto)));
          }
        case GET_PRODUCTO_DETALLE_FAIL:
          {
            if (payload instanceof AuthenticationError) {
              return nextResponse;
            }
            var event = {
              event: eventTypes.event.error,
              category: eventTypes.categories.paginaError,
              name: 'Error al obtener detalle de producto (Content -> /catalogo/productos)'
            };
            registerEvent(event);
            return dispatch(history.push('/error'));
          }
        default:
          return nextResponse;
      }
    };
  };
};
export var checkCopecIdentity = function checkCopecIdentity(_ref3) {
  var dispatch = _ref3.dispatch,
    getState = _ref3.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var rut = getState().auth.rut;
      if (action.type !== CHECK_COPEC || !rut) {
        return nextResponse;
      }
      return dispatch(apiRequest(loyaltyEndpoints.canje.checkCopecAuth(rut), checkCopecSuccess, checkCopecFail));
    };
  };
};
export var processCopecIdentity = function processCopecIdentity(_ref4) {
  var dispatch = _ref4.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== CHECK_COPEC_SUCCESS && action.type !== CHECK_COPEC_FAIL) {
        return nextResponse;
      }
      switch (action.type) {
        case CHECK_COPEC_SUCCESS:
          {
            return dispatch(setCopecIdentity(true));
          }
        case CHECK_COPEC_FAIL:
          {
            return dispatch(setCopecIdentity(false));
          }
        default:
          return Promise.resolve();
      }
    };
  };
};
export default [getProductoFlow, processGetProducto, checkCopecIdentity, processCopecIdentity];