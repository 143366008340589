import _objectWithoutProperties from "/home/jenkins/agent/workspace/UCTION_FRONT_deploy_front_master/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["onSuccess", "onCancel"];
import modalAlert from './modalAlert.web';
import { SHOW_ALERT, SHOW_CONFIRM, HIDE_ALERT, hideAlert } from './alert.actions';
var alertFlow = function alertFlow(_ref) {
  var dispatch = _ref.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      if (type !== SHOW_ALERT && type !== SHOW_CONFIRM) {
        return nextResponse;
      }
      var onSuccess = payload.onSuccess,
        onCancel = payload.onCancel,
        options = _objectWithoutProperties(payload, _excluded);
      return modalAlert(options).then(function (response) {
        return response === true ? onSuccess() : onCancel();
      }).then(function () {
        return dispatch(hideAlert());
      }).catch(function (e) {
        dispatch(hideAlert());
        throw e;
      });
    };
  };
};
var hideAlertFlow = function hideAlertFlow() {
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type;
      if (type !== HIDE_ALERT) {
        return nextResponse;
      }
      try {
        modalAlert.close();
      } catch (e) {
        /*
        Ignorar errores al cerrar alerta, específicamente por errores al no existir
        una alerta activa
         */
      }
      return nextResponse;
    };
  };
};
export default [alertFlow, hideAlertFlow];