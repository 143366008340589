var namespace = '[basic-page]';
export var GET_BASIC_PAGE = "".concat(namespace, " get basic page");
export var GET_BASIC_PAGE_SUCCESS = "".concat(GET_BASIC_PAGE, ": success");
export var GET_BASIC_PAGE_FAIL = "".concat(GET_BASIC_PAGE, ": fail");
export var SET_BASIC_PAGE = "".concat(namespace, " set basic page");
export var getBasicPage = function getBasicPage(id) {
  return {
    type: GET_BASIC_PAGE,
    payload: {
      id: id
    }
  };
};
export var getBasicPageSuccess = function getBasicPageSuccess(success) {
  return {
    type: GET_BASIC_PAGE_SUCCESS,
    payload: success
  };
};
export var getBasicPageFail = function getBasicPageFail(cause) {
  return {
    type: GET_BASIC_PAGE_FAIL,
    payload: cause
  };
};
export var setBasicPage = function setBasicPage(BasicPage) {
  return {
    type: SET_BASIC_PAGE,
    payload: BasicPage
  };
};