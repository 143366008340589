/* istanbul ignore file */
var namespace = '[chat]';
export var SHOW_CHAT = "".concat(namespace, " show click to chat");
export var HIDE_CHAT = "".concat(namespace, " hide click to chat");
export var showClickToChat = function showClickToChat() {
  return {
    type: SHOW_CHAT
  };
};
export var hideClickToChat = function hideClickToChat() {
  return {
    type: HIDE_CHAT
  };
};