var namespace = '[e-commerce]';
export var GET_COMMERCES = "".concat(namespace, " get commerces list");
export var SET_COMMERCES = "".concat(namespace, " set commerces list");
export var DELETE_COMMERCE = "".concat(namespace, " delete commerce");
export var GET_COMMERCES_SUCCESS = "".concat(GET_COMMERCES, ": success");
export var GET_COMMERCES_LOADING = "".concat(GET_COMMERCES, ": success");
export var GET_COMMERCES_FAIL = "".concat(GET_COMMERCES, ": fail");
export var DELETE_COMMERCE_SUCCESS = "".concat(DELETE_COMMERCE, ": success");
export var DELETE_COMMERCES_FAIL = "".concat(DELETE_COMMERCE, ": fail");
export var getCommerces = function getCommerces() {
  return {
    type: GET_COMMERCES
  };
};
export var getCommercesLoading = function getCommercesLoading() {
  return {
    type: GET_COMMERCES_LOADING
  };
};
export var getCommercesSuccess = function getCommercesSuccess(payload) {
  return {
    type: GET_COMMERCES_SUCCESS,
    payload: payload
  };
};
export var getCommercesFail = function getCommercesFail(payload) {
  return {
    type: GET_COMMERCES_FAIL,
    payload: payload
  };
};
export var setCommerces = function setCommerces(payload) {
  return {
    type: SET_COMMERCES,
    payload: payload
  };
};
export var deleteCommerce = function deleteCommerce(payload) {
  return {
    type: DELETE_COMMERCE,
    payload: payload
  };
};
export var deleteCommerceSuccess = function deleteCommerceSuccess(payload) {
  return {
    type: DELETE_COMMERCE_SUCCESS,
    payload: payload
  };
};
export var deleteCommerceFail = function deleteCommerceFail(payload) {
  return {
    type: DELETE_COMMERCES_FAIL,
    payload: payload
  };
};