/**
 * Género
 * @param id
 * @param nombre
 * @return {{id: *, nombre: *}}
 * @constructor
 */
var Genero = function Genero(_ref) {
  var id = _ref.id,
    nombre = _ref.nombre;
  return {
    id: id,
    nombre: nombre
  };
};
export default [Genero({
  id: 'M',
  nombre: 'Masculino'
}), Genero({
  id: 'F',
  nombre: 'Femenino'
})];