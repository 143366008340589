import times from 'lodash/times';
import isNumber from 'lodash/isNumber';
var doesShortCircuit = function doesShortCircuit(_times, iteratee) {
  return times(_times).reduce(function (previousCondition, currentValue, index) {
    if (!previousCondition) {
      return false;
    }
    return iteratee(index);
  }, true);
};
var isConsecutiveTo = function isConsecutiveTo(number, other) {
  if (!isNumber(number) || !isNumber(other)) {
    return false;
  }
  return number - other === 1;
};
export default (function (digits) {
  var amountOfDigits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return digits.filter(function (number, index, numbers) {
    var isConsecutiveToPrevious = function isConsecutiveToPrevious(offset) {
      var iIndex = index - offset;
      return isConsecutiveTo(numbers[iIndex], numbers[iIndex - 1]);
    };
    return doesShortCircuit(amountOfDigits, isConsecutiveToPrevious);
  }).reduce(function () {
    return true;
  }, false);
});