import { apiRequest } from '../api/api.actions';
import loyaltyEndpoints from '../api/endpoints/loyaltyEndpoints';
import { GET_INSCRIPTION_TYPE, setInscriptionType } from './inscripcion.actions';
export var flujoInscriptionType = function flujoInscriptionType(_ref) {
  var dispatch = _ref.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_INSCRIPTION_TYPE) {
        return nextResponse;
      }
      return Promise.all([dispatch(apiRequest(loyaltyEndpoints.usuario.getInscriptionType(), function (response) {
        return setInscriptionType(response);
      }))]);
    };
  };
};
export default [flujoInscriptionType];