import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';
import { apiRequest } from '@cencosud/puntos-core/src/features/api/api.actions';
import { createCatalogoCategoriaEvent } from '@cencosud/puntos-web/src/analytics/eventCreators';
import registerEvent from '@cencosud/puntos-web/src/analytics/registerEvent';
import { pushRoute } from '@cencosud/puntos-core/src/features/router/history.actions';
import { showSecondaryProgress, hideSecondaryProgressBar, showPrimaryProgress, hidePrimaryProgressBar } from '@cencosud/puntos-core/src/features/ui/ui.actions';
import AuthenticationError from '@cencosud/puntos-core/src/features/auth/AuthenticationError';
import bindCatalog from '../binder/CatalogOnlineBinder';
import { GET_CATALOGO, GET_CATALOGO_FAIL, GET_CATALOGO_SUCCESS, setCatalogo, getCatalogoFail, getCatalogoSuccess, getBannersSuccess, getBannersFail, setBanner, setProductoPromocionado, appendCatalogo, GET_BANNERS_SUCCESS, GET_BANNERS_FAIL, GET_BANNERS, GET_APPEND_CATALOGO, APPEND_CATALOGO_FAIL, APPEND_CATALOGO_SUCCESS, appendCatalogoSuccess, appendCatalogoFail, setCampagna, SET_CATALOGO, APPEND_CATALOGO } from './catalogo.actions';
import { getFeaturedProduct } from '../productos/ProductoDestacado/productoDestacado.actions';
import { contentV3Endpoints } from '../api/endpoints';
import history from '../router/history';
import eventTypes from '@cencosud/puntos-web/src/analytics/eventTypes';
export var getCatalogoFlow = function getCatalogoFlow(_ref) {
  var dispatch = _ref.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_CATALOGO) {
        return nextResponse;
      }
      var _action$payload = action.payload,
        page = _action$payload.page,
        size = _action$payload.size,
        from = _action$payload.from,
        to = _action$payload.to,
        categoria = _action$payload.categoria,
        isDesc = _action$payload.isDesc,
        nuevos = _action$payload.nuevos,
        ofertas = _action$payload.ofertas,
        excluidos = _action$payload.excluidos;
      return Promise.all([dispatch(apiRequest(contentV3Endpoints.productos.catalogo.get({
        page: page,
        size: size,
        from: from,
        to: to,
        isDescendingOrder: isDesc,
        categoria: categoria,
        nuevos: nuevos,
        ofertas: ofertas,
        excluidos: excluidos
      }), getCatalogoSuccess, getCatalogoFail)), dispatch(showPrimaryProgress())]).then(function (actions) {
        var _flatten$find = flatten(actions).find(function (_ref2) {
            var type = _ref2.type;
            return type === SET_CATALOGO;
          }),
          productos = _flatten$find.payload.productos;
        var tieneSubcategoria = !!get(categoria, 'nombreCategoria');
        var path = history.location.pathname;
        if (path !== '/') {
          registerEvent(createCatalogoCategoriaEvent({
            categoria: tieneSubcategoria ? get(categoria, 'nombreCategoria') : get(categoria, 'nombre'),
            subcategoria: tieneSubcategoria ? get(categoria, 'nombre') : undefined,
            productos: productos
          }));
        }
        return actions;
      }).then(function () {
        return dispatch(hidePrimaryProgressBar());
      });
    };
  };
};
export var processGetCatalogo = function processGetCatalogo(_ref3) {
  var dispatch = _ref3.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      switch (type) {
        case GET_CATALOGO_SUCCESS:
          {
            var catalogo = payload.payload;
            var isBusquedaSinResultados = isEmpty(catalogo.items) || catalogo.total === 0 || catalogo.limit === 0;
            return Promise.all([isBusquedaSinResultados && dispatch(getFeaturedProduct()), dispatch(setCatalogo(bindCatalog(catalogo.items), catalogo.total, catalogo.skip))]);
          }
        case GET_CATALOGO_FAIL:
          {
            var event = {
              event: eventTypes.event.error,
              category: eventTypes.categories.paginaError,
              name: 'Error al obtener catalogo de productos (Content -> /catalogo/productos)'
            };
            registerEvent(event);
            return Promise.all([nextResponse, payload instanceof AuthenticationError ? undefined : dispatch(pushRoute('/error'))]);
          }
        default:
          return nextResponse;
      }
    };
  };
};
export var getAppendCatalogoFlow = function getAppendCatalogoFlow(_ref4) {
  var dispatch = _ref4.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_APPEND_CATALOGO) {
        return nextResponse;
      }
      var _action$payload2 = action.payload,
        page = _action$payload2.page,
        size = _action$payload2.size,
        from = _action$payload2.from,
        to = _action$payload2.to,
        categoria = _action$payload2.categoria,
        isDesc = _action$payload2.isDesc,
        nuevos = _action$payload2.nuevos,
        ofertas = _action$payload2.ofertas,
        excluidos = _action$payload2.excluidos;
      return Promise.all([dispatch(showSecondaryProgress()), dispatch(apiRequest(contentV3Endpoints.productos.catalogo.get({
        page: page,
        size: size,
        from: from,
        to: to,
        isDescendingOrder: isDesc,
        categoria: categoria,
        nuevos: nuevos,
        ofertas: ofertas,
        excluidos: excluidos
      }), appendCatalogoSuccess, appendCatalogoFail))]).then(function (actions) {
        var _actions$flat$find = actions.flat().find(function (_ref5) {
            var type = _ref5.type;
            return type === APPEND_CATALOGO;
          }),
          productos = _actions$flat$find.payload;
        var path = history.location.pathname;
        if (path !== '/') {
          registerEvent(createCatalogoCategoriaEvent({
            categoria: get(categoria, 'nombre'),
            productos: productos.productos
          }));
        }
        return actions;
      });
    };
  };
};
export var processAppendCatalogo = function processAppendCatalogo(_ref6) {
  var dispatch = _ref6.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      if (type !== APPEND_CATALOGO_SUCCESS && type !== APPEND_CATALOGO_FAIL) {
        return nextResponse;
      }
      var progressResponse = dispatch(hideSecondaryProgressBar());
      // eslint-disable-next-line default-case
      switch (type) {
        case APPEND_CATALOGO_SUCCESS:
          {
            var catalogo = payload.payload;
            return Promise.all([progressResponse, dispatch(appendCatalogo(bindCatalog(catalogo.items), catalogo.skip, catalogo.total))]);
          }
        case APPEND_CATALOGO_FAIL:
          {
            var event = {
              event: eventTypes.event.error,
              category: eventTypes.categories.paginaError,
              name: 'Error al obtener catalogo de productos (Content -> /catalogo/productos)'
            };
            registerEvent(event);
            return Promise.all([progressResponse, !(payload instanceof AuthenticationError) && dispatch(pushRoute('/error'))]);
          }
        default:
          return [];
      }
    };
  };
};
export var getBannersFlow = function getBannersFlow(_ref7) {
  var dispatch = _ref7.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_BANNERS) {
        return nextResponse;
      }
      return dispatch(apiRequest(contentV3Endpoints.productos.catalogo.getBanners(), getBannersSuccess, getBannersFail));
    };
  };
};
export var processGetBanners = function processGetBanners(_ref8) {
  var dispatch = _ref8.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      switch (type) {
        case GET_BANNERS_SUCCESS:
          {
            var _payload$payload = payload.payload,
              banner = _payload$payload.banner,
              producto = _payload$payload.producto,
              popup = _payload$payload.popup;
            return Promise.all([dispatch(setBanner({
              image: get(banner, 'urlImagen'),
              label: get(banner, 'altText'),
              link: get(banner, 'link')
            })), dispatch(setProductoPromocionado({
              id: get(producto, 'id'),
              imagen: get(producto, 'urlImagen'),
              nombre: get(producto, 'nombre'),
              link: get(producto, 'link'),
              puntos: get(producto, 'puntos')
            })), dispatch(setCampagna({
              imagen: get(popup, 'imagen'),
              contenido: get(popup, 'contenido'),
              link: get(popup, 'link')
            }))]);
          }
        case GET_BANNERS_FAIL:
          if (payload instanceof AuthenticationError) {
            return nextResponse;
          }
          return nextResponse;
        default:
          return nextResponse;
      }
    };
  };
};
export default [getCatalogoFlow, processGetCatalogo, getAppendCatalogoFlow, processAppendCatalogo, getBannersFlow, processGetBanners];