import {
// GET_TOKEN,
SET_TOKEN } from './reCaptchav3.constants';
var initialState = {
  tokenv3: ''
};
export var reCaptchav3Reducers = function reCaptchav3Reducers() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    // case GET_TOKEN:
    //   return { tokenv3: {...action.payload} }
    // return { list: [...state.list, ...action.payload] };

    case SET_TOKEN:
      return {
        tokenv3: action.payload
      };
    default:
      return state;
  }
};
export default {
  reCaptcha: reCaptchav3Reducers
};