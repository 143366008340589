import { apiRequest } from '@cencosud/puntos-core/src/features/api/api.actions';
import { GET_CATEGORIAS_PRODUCTO, GET_CATEGORIAS_PRODUCTO_SUCCESS, getCategoriasProductoSuccess, getCategoriasProductoFail, setCategoriasProducto } from './categorias.actions';
import categoryBinder from '../../binder/CategoryBinder';
import { contentV3Endpoints } from '../../api/endpoints';
export var getCategoriasFlujo = function getCategoriasFlujo(_ref) {
  var dispatch = _ref.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type === GET_CATEGORIAS_PRODUCTO) {
        return dispatch(apiRequest(contentV3Endpoints.productos.getCategorias(), getCategoriasProductoSuccess, getCategoriasProductoFail));
      }
      return nextResponse;
    };
  };
};
export var getCategoriasProcesar = function getCategoriasProcesar(_ref2) {
  var dispatch = _ref2.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      if (type !== GET_CATEGORIAS_PRODUCTO_SUCCESS) {
        return nextResponse;
      }
      return dispatch(setCategoriasProducto(categoryBinder(payload.categorias)));
    };
  };
};
export default [getCategoriasFlujo, getCategoriasProcesar];