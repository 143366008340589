import { TipoProducto } from '@cencosud/puntos-core/src/features/productos/tiposProducto';
export var tiposLayout = {
  PRODUCTOS: TipoProducto(1, 'Producto'),
  ENTRETENCION: TipoProducto(2, 'Servicios y Entretenimiento'),
  GIFTCARD: TipoProducto(3, 'Gift Card'),
  ESTADIAS: TipoProducto(4, 'Estadías')
};
var PRODUCTOS = tiposLayout.PRODUCTOS,
  ENTRETENCION = tiposLayout.ENTRETENCION,
  GIFTCARD = tiposLayout.GIFTCARD;
export var isIntrucciones = function isIntrucciones(nombreLayout) {
  switch (nombreLayout) {
    case GIFTCARD.nombre:
      return false;
    case ENTRETENCION.nombre:
      return false;
    default:
      return true;
  }
};
export var isCAN14 = function isCAN14(nombreLayout) {
  switch (nombreLayout) {
    case GIFTCARD.nombre:
      return true;
    case ENTRETENCION.nombre:
      return true;
    default:
      return false;
  }
};
export var isCAN16 = function isCAN16(nombreLayout) {
  switch (nombreLayout) {
    case GIFTCARD.nombre:
      return true;
    case ENTRETENCION.nombre:
      return true;
    case PRODUCTOS.nombre:
      return true;
    default:
      return false;
  }
};