import { GET_VOUCHERS, GET_VOUCHERS_SUCCESS, getVouchersSuccess, getVouchersFail, setVouchers, RESEND_VOUCHER_SUCCESS, RESEND_VOUCHER, resendMailSuccess, resendMailFail, signalResend } from './AutogestionVouchers.actions';
import { apiRequest } from '@cencosud/puntos-core/src/features/api/api.actions';
import loyaltyEndpoints from '@cencosud/puntos-core/src/features/api/endpoints/loyaltyEndpoints';
import get from 'lodash/get';
import normalize from './normalizeVouchers';
export var getVouchersFlujo = function getVouchersFlujo(_ref) {
  var dispatch = _ref.dispatch,
    getState = _ref.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var rut = get(getState(), 'auth.rut');
      if (!rut) {
        return nextResponse;
      }
      if (action.type === GET_VOUCHERS) {
        var _action$payload = action.payload,
          pageSize = _action$payload.pageSize,
          startRowNum = _action$payload.startRowNum;
        return dispatch(apiRequest(loyaltyEndpoints.usuario.getVouchers(rut), getVouchersSuccess, getVouchersFail, {
          body: {
            pageSize: pageSize,
            startRowNum: startRowNum
          }
        }));
      }
      return nextResponse;
    };
  };
};
export var getProcesarVouchers = function getProcesarVouchers(_ref2) {
  var dispatch = _ref2.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      if (type !== GET_VOUCHERS_SUCCESS) {
        return nextResponse;
      }
      return dispatch(setVouchers(normalize(payload.vouchers)));
    };
  };
};
export var resendMail = function resendMail(_ref3) {
  var dispatch = _ref3.dispatch,
    getState = _ref3.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var rut = get(getState(), 'auth.rut');
      if (!rut) {
        return nextResponse;
      }
      var type = action.type,
        payload = action.payload;
      if (type === RESEND_VOUCHER) {
        var idVoucher = payload.idVoucher;
        return dispatch(apiRequest(loyaltyEndpoints.usuario.resendVoucher(rut), resendMailSuccess, resendMailFail, {
          body: {
            idVoucher: idVoucher
          }
        }));
      }
      return nextResponse;
    };
  };
};
export var sendResendSignal = function sendResendSignal(_ref4) {
  var dispatch = _ref4.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type;
      if (type !== RESEND_VOUCHER_SUCCESS) {
        return nextResponse;
      }
      return dispatch(signalResend({
        finishResend: true
      }));
    };
  };
};
export default [getVouchersFlujo, getProcesarVouchers, resendMail, sendResendSignal];