import _defineProperty from "/home/jenkins/agent/workspace/UCTION_FRONT_deploy_front_master/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { SET_CANJE_EXITOSO, SET_ERROR_CANJE, GENERAR_CODIGO, GENERAR_CODIGO_SUCCESS, GENERAR_CODIGO_FAIL, CANJEAR, CANJEAR_SUCCESS, CANJEAR_FAIL, GENERAR_CODIGO_ECOMMERCE, GENERAR_CODIGO_ECOMMERCE_SUCCESS, GENERAR_CODIGO_ECOMMERCE_FAIL, CHECK_REDEEM, CHECK_REDEEM_SUCCESS, CHECK_REDEEM_FAIL } from './canje.actions';

// prettier-ignore
export default (function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _ref = arguments.length > 1 ? arguments[1] : undefined,
    type = _ref.type,
    payload = _ref.payload;
  // NOSONAR
  switch (type) {
    case CANJEAR:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          confirmacionCanjeLoading: true
        });
      }
    case CANJEAR_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          confirmacionCanjeLoading: false
        });
      }
    case CANJEAR_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          confirmacionCanjeLoading: false
        });
      }
    case GENERAR_CODIGO:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          canjeLoading: true
        });
      }
    case GENERAR_CODIGO_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          canjeLoading: false
        });
      }
    case GENERAR_CODIGO_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          canjeLoading: false
        });
      }
    case GENERAR_CODIGO_ECOMMERCE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          canjeLoading: true
        });
      }
    case GENERAR_CODIGO_ECOMMERCE_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          canjeLoading: false
        });
      }
    case GENERAR_CODIGO_ECOMMERCE_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          canjeLoading: false
        });
      }
    case SET_CANJE_EXITOSO:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          exitoso: payload
        });
      }
    case SET_ERROR_CANJE:
      {
        var code = payload.code,
          message = payload.message,
          status = payload.status;
        return _objectSpread(_objectSpread({}, state), {}, {
          error: {
            code: code,
            message: message,
            status: status
          }
        });
      }
    case CHECK_REDEEM:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          exitoso: payload
        });
      }
    case CHECK_REDEEM_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          exitoso: payload
        });
      }
    case CHECK_REDEEM_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          exitoso: payload
        });
      }
    default:
      return _objectSpread(_objectSpread({}, state), payload);
  }
});