var namespace = '[registro]';
export var GET_TYC = "".concat(namespace, " get T&C");
export var GET_TYC_SUCCESS = "".concat(GET_TYC, ": success");
export var GET_TYC_FAIL = "".concat(GET_TYC, ": fail");
export var SET_TYC = "".concat(namespace, " set T&C");
export var TYC_APPROVE = "".concat(namespace, " aprove T&C");
export var TYC_APPROVE_SUCCESS = "".concat(TYC_APPROVE, ": success");
export var TYC_APPROVE_FAIL = "".concat(TYC_APPROVE, ": fail");
export var SHOW_TYC = "".concat(namespace, " show T&C");
export var HIDE_TYC = "".concat(namespace, " hide T&C");
export var GET_TYC_HASH = "".concat(namespace, " get tyc hash");
export var GET_TYC_HASH_SUCCESS = "".concat(GET_TYC_HASH, ": success");
export var GET_TYC_HASH_FAIL = "".concat(GET_TYC_HASH, ": fail");
export var SIGN_TYC_HASH = "".concat(namespace, " sign tyc hash");
export var SIGN_TYC_HASH_SUCCESS = "".concat(SIGN_TYC_HASH, ": success");
export var SIGN_TYC_HASH_FAIL = "".concat(SIGN_TYC_HASH, ": fail");
export var getTyC = function getTyC() {
  return {
    type: GET_TYC
  };
};
export var getTyCSuccess = function getTyCSuccess(success) {
  return {
    type: GET_TYC_SUCCESS,
    payload: success
  };
};
export var getTyCFail = function getTyCFail(cause) {
  return {
    type: GET_TYC_FAIL,
    payload: cause
  };
};
export var setTyC = function setTyC(tyc) {
  return {
    type: SET_TYC,
    payload: tyc
  };
};
export var approveTyC = function approveTyC() {
  return {
    type: TYC_APPROVE
  };
};
export var approveTyCSuccess = function approveTyCSuccess(success) {
  return {
    type: TYC_APPROVE_SUCCESS,
    payload: success
  };
};
export var approveTyCFail = function approveTyCFail(cause) {
  return {
    type: TYC_APPROVE_FAIL,
    payload: cause
  };
};
export var showTyC = function showTyC() {
  return {
    type: SHOW_TYC
  };
};
export var hideTyC = function hideTyC() {
  return {
    type: HIDE_TYC
  };
};
export var getTyCHash = function getTyCHash(hash) {
  return {
    type: GET_TYC_HASH,
    payload: hash
  };
};
export var getTyCHashSuccess = function getTyCHashSuccess(response) {
  return {
    type: GET_TYC_HASH_SUCCESS,
    payload: response
  };
};
export var getTyCHashFail = function getTyCHashFail(cause) {
  return {
    type: GET_TYC_HASH_FAIL,
    payload: cause
  };
};
export var signTyCHash = function signTyCHash(hash) {
  return {
    type: SIGN_TYC_HASH,
    payload: hash
  };
};
export var signTyCHashSuccess = function signTyCHashSuccess(response) {
  return {
    type: SIGN_TYC_HASH_SUCCESS,
    payload: response
  };
};
export var signTyCHashFail = function signTyCHashFail(cause) {
  return {
    type: SIGN_TYC_HASH_FAIL,
    payload: cause
  };
};