import methods from './httpMethods';
import { eliminarCaracteresEspeciales } from '../../common/rut';
import apiEndpointCreators from './apiEndpointCreators';
var createPrivateEndpoint = apiEndpointCreators.security.PrivateSecurityEndpoint;
var createPublicEndpoint = apiEndpointCreators.security.PublicSecurityEndpoint;
export default {
  terminosCondiciones: {
    aceptar: function aceptar(rut) {
      return createPrivateEndpoint({
        method: methods.put,
        uri: '/usuarios/TYC/{rut}',
        uriParams: {
          rut: eliminarCaracteresEspeciales(rut)
        }
      });
    },
    aceptarTyc: function aceptarTyc(rut) {
      return createPrivateEndpoint({
        method: methods.put,
        uri: '/usuarios/confirmacionTYC/{rut}',
        uriParams: {
          rut: eliminarCaracteresEspeciales(rut)
        }
      });
    }
  },
  usuario: {
    cambiarContrasena: function cambiarContrasena() {
      return createPrivateEndpoint({
        method: methods.put,
        uri: '/usuarios/clave'
      });
    },
    restablecerContrasena: function restablecerContrasena() {
      return createPublicEndpoint({
        method: methods.post,
        uri: '/usuarios/clave-tmp'
      });
    }
  },
  partner: {
    getAll: function getAll() {
      return createPublicEndpoint({
        method: methods.get,
        uri: '/socios/partners'
      });
    }
  }
};