import isObject from 'lodash/isObject';
export default (function (banner) {
  var _banner$bannerImage, _banner$bannerImage2, _banner$webImage, _banner$mobileImage;
  return isObject(banner) ? {
    id: banner.id,
    altText: banner.alternativeText,
    landing: null,
    name: banner.name,
    subtype: 'Banner',
    nombreClub: null,
    titulo: null,
    video: null,
    descripcion: null,
    puntosExtra1: banner.extraPoints1,
    medioPago: banner.paymentMethod,
    medioPago2: null,
    puntosExtra2: banner.extraPoints2,
    tarjetaCencosud: banner.cencosudCard,
    nombreBoton: banner.buttonName,
    colorBoton: banner.buttonColor,
    legal1: banner.legal1,
    legal2: banner.legal2,
    productos: banner.product,
    linkBoton: banner.buttonLink,
    imagenBanner: (_banner$bannerImage = banner.bannerImage) === null || _banner$bannerImage === void 0 ? void 0 : _banner$bannerImage.url,
    imagenBanner2: (_banner$bannerImage2 = banner.bannerImage2) === null || _banner$bannerImage2 === void 0 ? void 0 : _banner$bannerImage2.url,
    textoSuperior: banner.topText,
    bajadaSuperior: banner.topDescent,
    bajadaPrincipal: banner.mainDescent,
    externalInternalLink: banner.link,
    externalLink: banner.externalLink,
    urlImagen: (_banner$webImage = banner.webImage) === null || _banner$webImage === void 0 ? void 0 : _banner$webImage.url,
    urlImagenMobile: (_banner$mobileImage = banner.mobileImage) === null || _banner$mobileImage === void 0 ? void 0 : _banner$mobileImage.url,
    link: banner.externalLink,
    targetLink: banner.targetLink
  } : {};
});