import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { apiRequest } from '@cencosud/puntos-core/src/features/api/api.actions';
import { eliminarCaracteresEspeciales, formatear } from '@cencosud/puntos-core/src/features/common/rut';
import { OBTENER_ADICIONALES, OBTENER_ADICIONALES_SUCCESS, obtenerAdicionalesFail, obtenerAdicionalesSuccess, setAdicionales, OTORGAR_PERMISO, OTORGAR_PERMISO_SUCCESS, OTORGAR_PERMISO_FAIL, otorgarPermisoFail, otorgarPermisoSuccess, appendTransaccionAsycAdicional, ELIMINAR_ADICIONAL_SUCCESS, ELIMINAR_ADICIONAL_FAIL, ELIMINAR_ADICIONAL, eliminarAdicionalSuccess, eliminarAdicionalFail } from './usuarioAdicionales.actions';
import { hidePrimaryProgressBar, showPrimaryProgress, showNotifier } from '@cencosud/puntos-core/src/features/ui/ui.actions';
import processErrorMessage from '@cencosud/puntos-core/src/features/api/processErrorMessage';
import { loyaltyEndpoints } from '@cencosud/puntos-core/src/features/api/endpoints';
export var obtenerAdicionalesFlow = function obtenerAdicionalesFlow(_ref) {
  var dispatch = _ref.dispatch,
    getState = _ref.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== OBTENER_ADICIONALES) {
        return nextResponse;
      }
      return Promise.all([dispatch(showPrimaryProgress()), dispatch(apiRequest(loyaltyEndpoints.usuario.adicionales.get(getState().auth.rut), obtenerAdicionalesSuccess, obtenerAdicionalesFail, {
        auth: true
      }))]);
    };
  };
};
export var processObtenerAdicionales = function processObtenerAdicionales(_ref2) {
  var dispatch = _ref2.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== OBTENER_ADICIONALES_SUCCESS) {
        return nextResponse;
      }
      var progressResponse = dispatch(hidePrimaryProgressBar());
      var adicionales = get(action.payload, 'payload');
      if (!isArray(adicionales)) {
        return progressResponse;
      }
      return Promise.all([progressResponse,
      // TODO mejorar control de errores
      dispatch(setAdicionales(adicionales.map(function (adicional) {
        return {
          rut: formatear(adicional.Id),
          nombre: "".concat(adicional.nombre, " ").concat(adicional.ApellidoPaterno),
          tienePermisoCanje: adicional.PuedeCanjear
        };
      })))]);
    };
  };
};
export var otorgarPermisoSocioFlow = function otorgarPermisoSocioFlow(_ref3) {
  var dispatch = _ref3.dispatch,
    getState = _ref3.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== OTORGAR_PERMISO) {
        return nextResponse;
      }
      var _action$payload = action.payload,
        run = _action$payload.run,
        permisoCanje = _action$payload.permisoCanje;
      return dispatch(apiRequest(loyaltyEndpoints.usuario.adicionales.otorgarPermisoCanje(getState().auth.rut), function (x) {
        return otorgarPermisoSuccess(run, x);
      }, function (x) {
        return otorgarPermisoFail(run, x);
      }, {
        body: {
          run: eliminarCaracteresEspeciales(run),
          permisoCanje: permisoCanje
        },
        auth: true
      }));
    };
  };
};
export var processOtorgarPermisoSocio = function processOtorgarPermisoSocio(_ref4) {
  var dispatch = _ref4.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload,
        meta = action.meta;
      switch (type) {
        case OTORGAR_PERMISO_SUCCESS:
          {
            return appendTransaccionAsycAdicional(meta.run);
          }
        case OTORGAR_PERMISO_FAIL:
          {
            return Promise.all([appendTransaccionAsycAdicional(meta.run), dispatch(showNotifier(processErrorMessage(payload)))]);
          }
        default:
          return nextResponse;
      }
    };
  };
};
export var eliminarSocioFlow = function eliminarSocioFlow(_ref5) {
  var dispatch = _ref5.dispatch,
    getState = _ref5.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== ELIMINAR_ADICIONAL) {
        return nextResponse;
      }
      var rut = action.payload.rut;
      return dispatch(apiRequest(loyaltyEndpoints.usuario.adicionales.eliminarSocio(getState().auth.rut), eliminarAdicionalSuccess, eliminarAdicionalFail, {
        body: {
          rutAdicional: eliminarCaracteresEspeciales(rut)
        },
        auth: true
      }));
    };
  };
};
export var processEliminarSocio = function processEliminarSocio(_ref6) {
  var dispatch = _ref6.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      switch (type) {
        case ELIMINAR_ADICIONAL_SUCCESS:
          {
            return nextResponse;
          }
        case ELIMINAR_ADICIONAL_FAIL:
          {
            return dispatch(showNotifier(processErrorMessage(payload)));
          }
        default:
          return nextResponse;
      }
    };
  };
};
export default [obtenerAdicionalesFlow, processObtenerAdicionales, otorgarPermisoSocioFlow, processOtorgarPermisoSocio, eliminarSocioFlow, processEliminarSocio];