import { apiRequest } from '@cencosud/puntos-core/src/features/api/api.actions';
import { GET_FOOTER, GET_FOOTER_SUCCESS, GET_FOOTER_FAIL, getFooterLinksFail, getFooterLinksSuccess, setFooterLinks } from './footer.actions';
import FooterStatic from './Footer/FooterStatic';
import { contentEndpoints } from '../../api/endpoints';
export var getFooterFlow = function getFooterFlow(_ref) {
  var dispatch = _ref.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_FOOTER) {
        return nextResponse;
      }
      return dispatch(apiRequest(contentEndpoints.footer.get(), getFooterLinksSuccess, getFooterLinksFail));
    };
  };
};
export var processGetFooter = function processGetFooter(_ref2) {
  var dispatch = _ref2.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type;
      switch (type) {
        case GET_FOOTER_SUCCESS:
          {
            return dispatch(setFooterLinks(action.payload.payload));
          }
        case GET_FOOTER_FAIL:
          {
            return dispatch(setFooterLinks(FooterStatic.payload));
          }
        default:
          return nextResponse;
      }
    };
  };
};
export default [getFooterFlow, processGetFooter];