import { PUSH } from './history.actions';
import history from './history';
import { hideNotifier } from '../ui/ui.actions';
export var pushFlow = function pushFlow(_ref) {
  var dispatch = _ref.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== PUSH) {
        return nextResponse;
      }
      var _action$payload = action.payload,
        uri = _action$payload.uri,
        state = _action$payload.state;
      history.push(uri, state);
      return dispatch(hideNotifier());
    };
  };
};
export default [pushFlow];