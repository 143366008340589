import namespace from '@cencosud/puntos-core/src/features/productos/productoActionNamespace';
export var GET_FEATURED_PRODUCT = "".concat(namespace, " get destacados");
export var GET_FEATURED_PRODUCT_SUCCESS = "".concat(GET_FEATURED_PRODUCT, ": success");
export var GET_FEATURED_PRODUCT_FAIL = "".concat(GET_FEATURED_PRODUCT, ": fail");
export var SET_FEATURED_PRODUCT = "".concat(namespace, " set destacados");
export var getFeaturedProduct = function getFeaturedProduct() {
  return {
    type: GET_FEATURED_PRODUCT
  };
};
export var getFeaturedProductSuccess = function getFeaturedProductSuccess(productos) {
  return {
    type: GET_FEATURED_PRODUCT_SUCCESS,
    payload: productos
  };
};
export var getFeaturedProductFail = function getFeaturedProductFail(cause) {
  return {
    type: GET_FEATURED_PRODUCT_FAIL,
    payload: cause
  };
};
export var setFeaturedProduct = function setFeaturedProduct(productos) {
  return {
    type: SET_FEATURED_PRODUCT,
    payload: productos
  };
};