import _toConsumableArray from "/home/jenkins/agent/workspace/UCTION_FRONT_deploy_front_master/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import { apiRequest } from '../../api/api.actions';
import { CAMBIAR, CAMBIAR_FAIL, CAMBIAR_SUCCESS, cambiarFail, cambiarSuccess } from './cambiarContrasena.actions';
import { showNotifier } from '../../ui/ui.actions';
import { pushRoute } from '../../router/history.actions';
import processErrorMessage from '../../api/processErrorMessage';
import { logoutWithoutNavigation } from '../../auth/auth.actions';
import { securityEndpoints } from '../../api/endpoints';
export var cambiarFlow = function cambiarFlow(_ref) {
  var dispatch = _ref.dispatch,
    getState = _ref.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== CAMBIAR) {
        return nextResponse;
      }
      var _action$payload = action.payload,
        nueva = _action$payload.nueva,
        actual = _action$payload.actual;
      return dispatch(apiRequest(securityEndpoints.usuario.cambiarContrasena(), cambiarSuccess, cambiarFail, {
        body: {
          username: getState().auth.rut,
          contrasenia: nueva,
          contraseniaActual: actual
        },
        auth: true
      }));
    };
  };
};
export var processCambiar = function processCambiar(_ref2) {
  var dispatch = _ref2.dispatch,
    getState = _ref2.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      switch (action.type) {
        case CAMBIAR_SUCCESS:
          return nextResponse;
        case CAMBIAR_FAIL:
          {
            var payload = action.payload;
            return Promise.all([dispatch(showNotifier(processErrorMessage(action.payload, getState().ui.parameterize)))].concat(_toConsumableArray(payload.code === 'LOG-023' ? [dispatch(logoutWithoutNavigation()), dispatch(pushRoute('/bloqueo'))] : [])));
          }
        default:
          return nextResponse;
      }
    };
  };
};
export default [cambiarFlow, processCambiar];