var namespace = '[footer]';
export var GET_FOOTER = "".concat(namespace, " get footer");
export var GET_FOOTER_SUCCESS = "".concat(GET_FOOTER, ": success");
export var GET_FOOTER_FAIL = "".concat(GET_FOOTER, ": fail");
export var SET_FOOTER = "".concat(namespace, " set footer");
export var getFooterLinks = function getFooterLinks() {
  return {
    type: GET_FOOTER
  };
};
export var getFooterLinksSuccess = function getFooterLinksSuccess(FooterLinks) {
  return {
    type: GET_FOOTER_SUCCESS,
    payload: FooterLinks
  };
};
export var getFooterLinksFail = function getFooterLinksFail(cause) {
  return {
    type: GET_FOOTER_FAIL,
    payload: cause
  };
};
export var setFooterLinks = function setFooterLinks(columnas) {
  return {
    type: SET_FOOTER,
    payload: columnas
  };
};