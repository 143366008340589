var seccionOnOff = {
  result: {
    message: 'OK'
  },
  payload: [{
    modulo: 'login',
    mensaje: null,
    estado: 'Off'
  }, {
    modulo: 'consultaPuntos',
    mensaje: null,
    estado: 'Off'
  }, {
    modulo: 'adicional',
    mensaje: null,
    estado: 'Off'
  }, {
    modulo: 'inscripcion',
    mensaje: null,
    estado: 'Off'
  }, {
    modulo: 'actualizacionDatos',
    mensaje: null,
    estado: 'Off'
  }, {
    modulo: 'canje',
    mensaje: null,
    estado: 'Off'
  }, {
    modulo: 'carruselProductos',
    mensaje: null,
    estado: 'Off'
  }]
};
export default seccionOnOff;