var namespace = '[landing]';
export var GET_INFORMACION = "".concat(namespace, " get informaciones ");
export var GET_INFORMACION_SUCCESS = "".concat(GET_INFORMACION, ": success");
export var GET_INFORMACION_FAIL = "".concat(GET_INFORMACION, ": fail");
export var SET_INFORMACION = "".concat(namespace, " set informaciones");
export var getInformacion = function getInformacion() {
  return {
    type: GET_INFORMACION
  };
};
export var getInformacionSuccess = function getInformacionSuccess(success) {
  return {
    type: GET_INFORMACION_SUCCESS,
    payload: success
  };
};
export var getInformacionFail = function getInformacionFail(cause) {
  return {
    type: GET_INFORMACION_FAIL,
    payload: cause
  };
};
export var setInformacion = function setInformacion(informacion) {
  return {
    type: SET_INFORMACION,
    payload: informacion
  };
};