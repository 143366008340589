import persistantStore from '../../state/persistentStore';
import { GET_DASHBOARD_CUENTA, GET_DASHBOARD_CUENTA_SUCCESS, getDashboardCuentaSuccess, getDashboardCuentaFail, setDashboardCuenta } from './dashboard.actions';
import { loyaltyEndpoints } from '../../api/endpoints';
import { apiRequest } from '../../api/api.actions';
import normalizeDashboard from './normalizeDashboard';
export var getDashboardCuenta = function getDashboardCuenta(_ref) {
  var dispatch = _ref.dispatch,
    getState = _ref.getState;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_DASHBOARD_CUENTA) {
        return nextResponse;
      }
      var cacheTransacciones = null;
      try {
        cacheTransacciones = persistantStore.getTransacciones();
        if (cacheTransacciones || undefined) {
          persistantStore.clearTransacciones();
        }
      } catch (_unused) {
        persistantStore.clearTransacciones();
      }
      return dispatch(apiRequest(loyaltyEndpoints.usuario.getDashboardCuenta(getState().auth.rut), getDashboardCuentaSuccess, getDashboardCuentaFail, {
        auth: true
      }));
    };
  };
};
export var processDashBoardCuenta = function processDashBoardCuenta(_ref2) {
  var dispatch = _ref2.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      if (type !== GET_DASHBOARD_CUENTA_SUCCESS) {
        return nextResponse;
      }
      var transacciones = normalizeDashboard(payload.payload);
      return dispatch(setDashboardCuenta(transacciones));
    };
  };
};
export default [getDashboardCuenta, processDashBoardCuenta];