var namespace = '[regiones]';
export var GET_REGIONES = "".concat(namespace, " get regiones");
export var GET_REGIONES_SUCCESS = "".concat(GET_REGIONES, ": success");
export var GET_REGIONES_FAIL = "".concat(GET_REGIONES, ": fail");
export var SET_REGIONES = "".concat(namespace, " set regiones");
export var getRegiones = function getRegiones() {
  return {
    type: GET_REGIONES
  };
};
export var getRegionesSuccess = function getRegionesSuccess(regiones) {
  return {
    type: GET_REGIONES_SUCCESS,
    payload: regiones
  };
};
export var getRegionesFail = function getRegionesFail(cause) {
  return {
    type: GET_REGIONES_FAIL,
    payload: cause
  };
};
export var setRegiones = function setRegiones(regiones) {
  return {
    type: SET_REGIONES,
    payload: regiones
  };
};