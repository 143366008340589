import { apiRequest } from '@cencosud/puntos-core/src/features/api/api.actions';
import { contentEndpoints } from '../../api/endpoints';
import { GET_DUPLAS,
// eslint-disable-next-line import/named
GET_DUPLAS_SUCCESS, setDuplaList,
// eslint-disable-next-line import/named
getDuplasSuccess,
// eslint-disable-next-line import/named
getDuplasFail } from './dupla.action';
import normalizeDuplas from '@cencosud/puntos-core/src/features/registro/dupla/normalizeDuplas';
export var getDuplas = function getDuplas(_ref) {
  var dispatch = _ref.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type === GET_DUPLAS) {
        return dispatch(apiRequest(contentEndpoints.duplas.get(), getDuplasSuccess, getDuplasFail));
      }
      return nextResponse;
    };
  };
};
export var getDuplasProcesar = function getDuplasProcesar(_ref2) {
  var dispatch = _ref2.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      if (type !== GET_DUPLAS_SUCCESS) {
        return nextResponse;
      }
      return dispatch(setDuplaList(normalizeDuplas(payload.payload)));
    };
  };
};
export default [getDuplas, getDuplasProcesar];