import * as Yup from 'yup';
import { calle, comuna, email, numero, region, telefono } from '@cencosud/puntos-core/src/features/common/form/validaciones';
export default (function () {
  return Yup.object().shape({
    email: email,
    calle: calle,
    numero: numero,
    region: region,
    comuna: comuna,
    telefono: telefono
  });
});