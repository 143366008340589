export default (function (list, code) {
  var defaultValue = list.find(function (l) {
    return l[0].toLowerCase() === 'Default'.toLowerCase();
  });
  var defaultCampaign = defaultValue ? defaultValue[1] : 'P002';
  if (!code) return defaultCampaign;
  var campaign = list.find(function (l) {
    return l[0].toLowerCase() === code.toLowerCase();
  });
  var defaultErrorValue = list.find(function (l) {
    return l[0].toLowerCase() === 'Default_error'.toLowerCase();
  });
  return campaign ? campaign[1] : defaultErrorValue ? defaultErrorValue[1] : defaultCampaign;
});