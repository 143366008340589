/* istanbul ignore file */
import { apiRequest } from '@cencosud/puntos-core/src/features/api/api.actions';
import { pushRoute } from '@cencosud/puntos-core/src/features/router/history.actions';
import { eliminarCaracteresEspeciales } from '@cencosud/puntos-core/src/features/common/rut';
import { loyaltyEndpoints } from '@cencosud/puntos-core/src/features/api/endpoints';
import { GET_PUNTOS, GET_PUNTOS_SUCCESS, GET_PUNTOS_FAIL, getPuntosSuccess, getPuntosFail, setPuntos } from '@cencosud/puntos-core/src/features/ui/header/Header/TopLevelHeader/CuantosPuntosTengo/CuantosPuntosTengo.actions';
import registerEvent from '@cencosud/puntos-web/src/analytics/registerEvent';
import { createFailedFormEvent, createSuccessfulFormEvent } from '@cencosud/puntos-web/src/analytics/eventCreators';
import eventTypes from '@cencosud/puntos-web/src/analytics/eventTypes';
export var puntosPorRutFlow = function puntosPorRutFlow(_ref) {
  var dispatch = _ref.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      if (action.type !== GET_PUNTOS) {
        return nextResponse;
      }
      var _action$payload = action.payload,
        rut = _action$payload.rut,
        captcha = _action$payload.captcha;
      var normalizeRut = function normalizeRut(_rut) {
        return _rut !== undefined ? eliminarCaracteresEspeciales(_rut).toUpperCase() : null;
      };
      return dispatch(apiRequest(loyaltyEndpoints.usuario.getPuntos(), getPuntosSuccess, getPuntosFail, {
        body: {
          run: normalizeRut(rut),
          gRecaptchaResponse: captcha
        }
      }));
    };
  };
};
export var processPuntosPorRut = function processPuntosPorRut(_ref2) {
  var dispatch = _ref2.dispatch;
  return function (next) {
    return function (action) {
      var nextResponse = next(action);
      var type = action.type,
        payload = action.payload;
      switch (type) {
        case GET_PUNTOS_SUCCESS:
          {
            var usuario = payload.payload;
            registerEvent(createSuccessfulFormEvent({
              category: eventTypes.categories.puntos,
              name: '¿Cuántos puntos tengo?: éxito',
              puntos: usuario.puntos
            }));
            return Promise.all([dispatch(setPuntos(usuario)), dispatch(pushRoute('/puntos'))]);
          }
        case GET_PUNTOS_FAIL:
          {
            var error = payload;
            registerEvent(createFailedFormEvent({
              category: eventTypes.categories.puntos,
              name: '¿Cuántos puntos tengo?: fallo',
              error: error
            }));
            var limpiarPuntos = function limpiarPuntos() {
              return dispatch(setPuntos({
                puntos: null
              }));
            };
            if (error.code === 'LOG-024' || error.code === 'LOG-025') {
              return Promise.all([limpiarPuntos(), dispatch(pushRoute('/puntos', {
                error: error
              }))]);
            }
            return Promise.all([nextResponse, limpiarPuntos()]);
          }
        default:
          return nextResponse;
      }
    };
  };
};
export default [puntosPorRutFlow, processPuntosPorRut];